import axios from 'axios';
import abs from '@/assets/js/abs';

let API_URL = 'http://101.32.207.161:10010';
let WS_URL = 'ws://101.32.207.161:10901/ws';
let invoke = function (svc, cmd, _this, reqBody, success, failure) {
    axios.post(API_URL, {
        head: {
            svc: svc || '',
            cmd: cmd || '',
            lang: abs.Store.locale(),
            device: navigator.userAgent,
            platform: abs.account.EPlatform.WEB,
            client: abs.Store.client() || '',
            series: String(new Date().getTime()),
            uticket: abs.Store.uticket() || ''
        },
        body: reqBody || {}
    }).then(function (result) {
        if (result.status !== 200) {
            failure && failure.call(_this, result.status, result.resultText);
            return;
        }
        if (result.data.head.code !== 0) {
            if (result.data.head.code === 9 || result.data.head.code === 8) {
                _this.$router.push(_this.abs.routes.HOME_LOGIN);
            }
            else {
                failure && failure.call(_this, result.data.head.code, result.data.head.message);
            }
            return;
        }
        success && success.call(_this, result.data.body);
    });
};

export default {
    websocket: function () {
        return new WebSocket(WS_URL);
    },
    countries: function (_this, reqBody, success, failure) {
        invoke('base', 'countries', _this, reqBody, success, failure);
    },
    currencies: function (_this, reqBody, success, failure) {
        invoke('base', 'currencies', _this, reqBody, success, failure);
    },
    instruments: function (_this, reqBody, success, failure) {
        invoke('base', 'instruments', _this, reqBody, success, failure);
    },

    vcode: function (_this, reqBody, success, failure) {
        invoke('account', 'vcode', _this, reqBody, success, failure);
    },
    client: function (_this, reqBody, success, failure) {
        invoke('account', 'client', _this, reqBody, success, failure);
    },
    register: function (_this, reqBody, success, failure) {
        invoke('account', 'register', _this, reqBody, success, failure);
    },
    verify: function (_this, reqBody, success, failure) {
        invoke('account', 'verify', _this, reqBody, success, failure);
    },
    login: function (_this, reqBody, success, failure) {
        invoke('account', 'login', _this, reqBody, success, failure);
    },
    refresh: function (_this, reqBody, success, failure) {
        invoke('account', 'refresh', _this, reqBody, success, failure);
    },
    account: function (_this, reqBody, success, failure) {
        invoke('account', 'account', _this, reqBody, success, failure);
    },
    vercode: function (_this, reqBody, success, failure) {
        invoke('account', 'vercode', _this, reqBody, success, failure);
    },
    resetPassword: function (_this, reqBody, success, failure) {
        invoke('account', 'reset_password', _this, reqBody, success, failure);
    },
    editNickname: function (_this, reqBody, success, failure) {
        invoke('account', 'edit_nickname', _this, reqBody, success, failure);
    },
    bindPhone: function (_this, reqBody, success, failure) {
        invoke('account', 'bind_phone', _this, reqBody, success, failure);
    },
    bindEmail: function (_this, reqBody, success, failure) {
        invoke('account', 'bind_email', _this, reqBody, success, failure);
    },
    changePassword: function (_this, reqBody, success, failure) {
        invoke('account', 'change_password', _this, reqBody, success, failure);
    },
    logout: function (_this, reqBody, success, failure) {
        invoke('account', 'logout', _this, reqBody, success, failure);
    },
    unregister: function (_this, reqBody, success, failure) {
        invoke('account', 'unregister', _this, reqBody, success, failure);
    },
    userRecordList: function (_this, reqBody, success, failure) {
        invoke('account', 'user_record_list', _this, reqBody, success, failure);
    },
    tradeFee: function (_this, reqBody, success, failure) {
        invoke('account', 'trade_fee', _this, reqBody, success, failure);
    },

    valuation: function (_this, reqBody, success, failure) {
        invoke('wallet', 'valuation', _this, reqBody, success, failure);
    },
    accountBalance: function (_this, reqBody, success, failure) {
        invoke('wallet', 'account_balance', _this, reqBody, success, failure);
    },
    accountBillList: function (_this, reqBody, success, failure) {
        invoke('wallet', 'account_bill_list', _this, reqBody, success, failure);
    },
    assetBalances: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_balances', _this, reqBody, success, failure);
    },
    assetBillList: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_bill_list', _this, reqBody, success, failure);
    },
    walletAddress: function (_this, reqBody, success, failure) {
        invoke('wallet', 'wallet_address', _this, reqBody, success, failure);
    },
    assetDepositState: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_deposit_state', _this, reqBody, success, failure);
    },
    assetDepositList: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_deposit_list', _this, reqBody, success, failure);
    },
    assetWithdrawal: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_withdrawal', _this, reqBody, success, failure);
    },
    assetWithdrawalCancel: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_withdrawal_cancel', _this, reqBody, success, failure);
    },
    assetWithdrawalState: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_withdrawal_state', _this, reqBody, success, failure);
    },
    assetWithdrawalList: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_withdrawal_list', _this, reqBody, success, failure);
    },
    assetTransfer: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_transfer', _this, reqBody, success, failure);
    },
    assetTransferState: function (_this, reqBody, success, failure) {
        invoke('wallet', 'asset_transfer_state', _this, reqBody, success, failure);
    },

    tickers: function (_this, reqBody, success, failure) {
        invoke('market', 'tickers', _this, reqBody, success, failure);
    },
    ticker: function (_this, reqBody, success, failure) {
        invoke('market', 'ticker', _this, reqBody, success, failure);
    },
    indexTicker: function (_this, reqBody, success, failure) {
        invoke('market', 'index_ticker', _this, reqBody, success, failure);
    },
    orderBooks: function (_this, reqBody, success, failure) {
        invoke('market', 'order_books', _this, reqBody, success, failure);
    },
    candles: function (_this, reqBody, success, failure) {
        invoke('market', 'candles', _this, reqBody, success, failure);
    },
    indexCandles: function (_this, reqBody, success, failure) {
        invoke('market', 'index_candles', _this, reqBody, success, failure);
    },
    markPriceCandles: function (_this, reqBody, success, failure) {
        invoke('market', 'mark_price_candles', _this, reqBody, success, failure);
    },
    trades: function (_this, reqBody, success, failure) {
        invoke('market', 'trades', _this, reqBody, success, failure);
    },
    ranks: function (_this, reqBody, success, failure) {
        invoke('market', 'ranks', _this, reqBody, success, failure);
    },

    spotMakeOrder: function (_this, reqBody, success, failure) {
        invoke('spot_base', 'make_order', _this, reqBody, success, failure);
    },
    spotCancelOrder: function (_this, reqBody, success, failure) {
        invoke('spot_base', 'cancel_order', _this, reqBody, success, failure);
    },
    spotOrder: function (_this, reqBody, success, failure) {
        invoke('spot_base', 'order', _this, reqBody, success, failure);
    },
    spotOrderPendingList: function (_this, reqBody, success, failure) {
        invoke('spot_base', 'order_pending_list', _this, reqBody, success, failure);
    },
    spotOrderHistoryList: function (_this, reqBody, success, failure) {
        invoke('spot_base', 'order_history_list', _this, reqBody, success, failure);
    },
    spotOrderFillList: function (_this, reqBody, success, failure) {
        invoke('spot_base', 'order_fill_list', _this, reqBody, success, failure);
    }
}