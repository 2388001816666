<template>
<div class="container">
    <top-bar></top-bar>
    <div class="asset-tabs">
        <div class="tab-heads">
            <div class="tab-head" :class="{'active':module===MODULES.OVERVIEW}" @click="clickTab(MODULES.OVERVIEW)" v-text="$t('wallet.overview.overview')"></div>
            <div class="tab-head" :class="{'active':module===MODULES.CASH || module===MODULES.DEPOSIT || module===MODULES.WITHDRAW || module===MODULES.TRANSFER}" @click="clickTab(MODULES.CASH)" v-text="$t('wallet.funding.funding')"></div>
            <div class="tab-head" :class="{'active':module===MODULES.TRADE}" @click="clickTab(MODULES.TRADE)" v-text="$t('wallet.trading.trading')"></div>
            <div class="tab-head" :class="{'active':module===MODULES.ORDER}" @click="clickTab(MODULES.ORDER)" v-text="$t('wallet.order.order')"></div>
            <div class="tab-head" :class="{'active':module===MODULES.FEE}" @click="clickTab(MODULES.FEE)" v-text="$t('wallet.fee.fee')"></div>
        </div>
        <div class="tab-bodys">
            <div class="tab-body" :class="{'active':module===MODULES.OVERVIEW}">
                <user-asset-overview :base-lot-size="BASE_LOT_SIZE" :base-unit="BASE_UNIT" :base-unit-tickers="BASE_UNIT_TICKERS" :btc-unit-ticker="BTC_UNIT_TICKER" :usd-unit-ticker="USD_UNIT_TICKER" :unit="unit" :units="UNITS" @changeunit="changeAssetUnit"></user-asset-overview>
            </div>
            <div class="tab-body" :class="{'active':module===MODULES.CASH || module===MODULES.DEPOSIT || module===MODULES.WITHDRAW || module===MODULES.TRANSFER}">
                <user-asset-funding v-if="module===MODULES.CASH" :base-lot-size="BASE_LOT_SIZE" :base-unit="BASE_UNIT" :base-unit-tickers="BASE_UNIT_TICKERS" :btc-unit-ticker="BTC_UNIT_TICKER" :usd-unit-ticker="USD_UNIT_TICKER" :unit="unit" :units="UNITS" @changeunit="changeAssetUnit"></user-asset-funding>
                <user-asset-deposit v-if="module===MODULES.DEPOSIT" :wallet-types="WALLET_TYPES"></user-asset-deposit>
                <user-asset-withdraw v-if="module===MODULES.WITHDRAW" :base-lot-size="BASE_LOT_SIZE"></user-asset-withdraw>
                <user-asset-transfer v-if="module===MODULES.TRANSFER" :base-lot-size="BASE_LOT_SIZE"></user-asset-transfer>
            </div>
            <div class="tab-body" :class="{'active':module===MODULES.TRADE}">
                <user-asset-trading :base-lot-size="BASE_LOT_SIZE" :base-unit="BASE_UNIT" :base-unit-tickers="BASE_UNIT_TICKERS" :btc-unit-ticker="BTC_UNIT_TICKER" :usd-unit-ticker="USD_UNIT_TICKER" :unit="unit" :units="UNITS" @changeunit="changeAssetUnit"></user-asset-trading>
            </div>
            <div class="tab-body" :class="{'active':module===MODULES.ORDER}">
                <user-asset-order></user-asset-order>
            </div>
            <div class="tab-body" :class="{'active':module===MODULES.FEE}">
                <user-asset-fee></user-asset-fee>
            </div>
        </div>
    </div>
    <bottom-bar></bottom-bar>
    <msg-toast></msg-toast>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import UserAssetOverview from "@/UserAssetOverview";
import UserAssetFunding from "@/UserAssetFunding";
import UserAssetTrading from "@/UserAssetTrading";
import UserAssetDeposit from "@/UserAssetDeposit";
import UserAssetWithdraw from "@/UserAssetWithdraw";
import UserAssetTransfer from "@/UserAssetTransfer";
import UserAssetOrder from "@/UserAssetOrder";
import UserAssetFee from "@/UserAssetFee";
export default {
    name: "UserAsset",
    components: {UserAssetOverview, UserAssetFunding, UserAssetTrading, UserAssetDeposit, UserAssetWithdraw, UserAssetTransfer, UserAssetOrder, UserAssetFee},
    data () {
        return {
            MODULES: {
                OVERVIEW: 'overview',
                CASH: 'cash',
                TRADE: 'trade',
                ANALYSIS: 'analysis',
                ORDER: 'order',
                FEE: 'fee',
                DEPOSIT: 'deposit',
                WITHDRAW: 'withdraw',
                TRANSFER: 'transfer'
            },
            BASE_LOT_SIZE: 8,
            UNITS: [{
                id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2
            }, {
                id: 'USD', text: 'USD', symbol: '$', lotSize: 2
            }, {
                id: 'BTC', text: 'BTC', symbol: '฿', lotSize: 6
            }],
            BASE_UNIT: {
                id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2
            },
            BASE_UNIT_TICKERS: [],
            BTC_UNIT_TICKER: 0,
            USD_UNIT_TICKER: 0,
            WALLET_TYPES: [{
                id: this.abs.wallet.EWalletType.FUNDING, text: this.$t('wallet.funding.funding')
            }, {
                id: this.abs.wallet.EWalletType.TRADING, text: this.$t('wallet.trading.trading')
            }],
            module: '',
            unit: {
                id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2
            }
        };
    },
    created () {
        this.module = this.$route.params.module || this.MODULES.OVERVIEW;
        this.$watch(() => this.$route.params, (params) => {
            this.module = params.module || this.MODULES.OVERVIEW;
        });
    },
    mounted () {
        this.indexTicker();
    },
    methods: {
        clickTab (module) {
            if (this.module === module) {
                return;
            }
            this.module = module;
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.module);
        },
        changeAssetUnit (id, unit) {
            this.unit = unit;
        },
        indexTicker () {
            this.api.indexTicker(this, {
                quoteCcy: this.BASE_UNIT.id
            }, function (rspBody) {
                let baseUnitTickers = [];
                for (let i = 0; i < rspBody.tickers.length; i++) {
                    let ticker = rspBody.tickers[i];
                    baseUnitTickers[ticker.instId] = ticker;
                }
                this.BASE_UNIT_TICKERS = baseUnitTickers;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });

            this.api.indexTicker(this, {
                instrumentId: 'BTC-USDT'
            }, function (rspBody) {
                this.BTC_UNIT_TICKER = Number(rspBody.tickers[0].idxPx);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });

            this.api.indexTicker(this, {
                instrumentId: 'USDT-USD'
            }, function (rspBody) {
                this.USD_UNIT_TICKER = Number(rspBody.tickers[0].idxPx);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.tab-heads {
    padding: 0 100px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
}
.tab-heads .tab-head {
    margin-right: 30px;
    height: 48px;
    border-bottom: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tab-heads .tab-head.active {
    color: var(--text-strong);
    border-bottom: 2px solid var(--text-strong);
}
.tab-heads .tab-head:hover {
    color: var(--text-strong);
}
.tab-bodys .tab-body {
    display: none;
    min-height: 1000px;
    padding: 24px 100px 100px;
}
.tab-bodys .tab-body.active {
    display: block;
}
</style>