<template>
<div class="action-panel deposit-panel">
    <div class="title" v-text="$t('wallet.deposit.deposit')"></div>
    <div class="step" :class="{'doing':step===STEPS.ONE,'done':step!==STEPS.ONE}">
        <div class="num">1</div>
        <div class="label" v-text="$t('wallet.deposit.select_crypto')"></div>
        <div class="block block-doing">
            <currency-picker class="field" :action="abs.wallet.EWalletAction.DEPOSIT" :text-align="'left'" @changeValue="changeCurrency"></currency-picker>
            <div class="sub-label" v-text="$t('wallet.deposit.select_chain', {currencyText:currencyText})"></div>
            <drop-down class="field" :text-align="'left'" :value="chainId" :options="chainList" @change="changeChain"></drop-down>
            <div class="notice">
                <div class="text" v-text="$t('wallet.deposit.select_chain_ensure')"></div>
                <i class="icon exclamation-circle"></i>
            </div>
            <div class="next"><a class="button primary-button" :class="{'disabled':!currency || !chain}" @click="clickNext" v-text="$t('wallet.deposit.next')"></a></div>
        </div>
        <div class="num num-doing">2</div>
        <div class="label label-doing" v-text="$t('wallet.deposit.deposit_details')"></div>
        <div class="block block-done">
            <div class="crypto-chain" v-text="'Deposit ' + chainText"></div>
            <div class="back"><a class="button link-button" @click="clickEdit" v-text="$t('wallet.deposit.edit')"></a></div>
        </div>
    </div>
    <div class="step" :class="{'ending':step===STEPS.TWO}">
        <div class="num">2</div>
        <div class="label" v-text="$t('wallet.deposit.deposit_details')"></div>
        <div class="block">
            <div class="sub-label" style="margin-top:0" v-text="$t('wallet.deposit.deposit_to')"></div>
            <drop-down :text-align="'left'" :value="walletTypes[0].id" :options="walletTypes" @change="changeWalletType"></drop-down>
            <div class="sub-label" v-text="currencyText + ' ' + $t('wallet.deposit.deposit_address')"></div>
            <div class="address"><label class="nil nil-address"><input v-model="address" /></label><span class="text" v-text="address"></span><i class="icon copy" @click="clickCopyAddress"></i></div>
            <div class="notice">
                <div class="text" v-text="$t('wallet.deposit.deposit_crypto_ensure', {currencyText:currencyText})"></div>
                <i class="icon exclamation-circle"></i>
            </div>
            <template v-if="extraLabel && extraValue">
                <div class="sub-label" v-text="currencyText + ' ' + extraLabel"></div>
                <div class="address"><label class="nil nil-tag"><input v-model="extraValue" /></label><span class="text" v-text="extraValue"></span><i class="icon copy" @click="clickCopyTag"></i></div>
                <div class="notice">
                    <div class="text" v-text="$t('wallet.deposit.deposit_tag_ensure', {currencyText:currencyText})"></div>
                    <i class="icon exclamation-circle"></i>
                </div>
            </template>
            <div class="memos">
                <div class="memo"><div class="memo-desc" v-text="$t('wallet.deposit.minimum_deposit')"></div><div class="memo-value" v-text="(currency ? abs.Util.formatNumber(currency.minDeposit) : '0.0') + ' ' + currencyText"></div></div>
                <div class="memo"><div class="memo-desc" v-text="$t('wallet.deposit.deposit_arrival')"></div><div class="memo-value" v-text="(chain ? abs.Util.formatNumber(chain.minDepositConfirm) : '0') + ' ' + $t('wallet.deposit.confirmations')"></div></div>
                <div class="memo"><div class="memo-desc" v-text="$t('wallet.deposit.withdrawal_unlock')"></div><div class="memo-value" v-text="(chain ? abs.Util.formatNumber(chain.minWithdrawConfirm) : '0') + ' ' + $t('wallet.deposit.confirmations')"></div></div>
            </div>
        </div>
    </div>
    <div class="history">
        <div class="label" v-text="$t('wallet.deposit.deposits')"></div>
        <div class="only" v-if="currency"><checkbox-field :label="$t('wallet.deposit.crypto_only', {currencyText:currencyText})" :value="currencyOnly" @changeValue="changeCurrencyOnly"></checkbox-field></div>
        <div class="list">
            <div class="head">
                <div class="row">
                    <div class="col time" v-text="$t('wallet.deposit.time')"></div>
                    <div class="col address" v-text="$t('wallet.deposit.address')"></div>
                    <div class="col crypto" v-text="$t('wallet.deposit.crypto')"></div>
                    <div class="col amount" v-text="$t('wallet.deposit.amount')"></div>
                    <div class="col progress" v-text="$t('wallet.deposit.progress')"></div>
                    <div class="col status" v-text="$t('wallet.deposit.status')"></div>
                </div>
            </div>
            <div class="body">
                <div class="row" v-for="order in orderList" :key="order.depId">
                    <div class="col time" v-text="$d(order.time, 'long')"></div>
                    <div class="col address" v-text="order.address + ' (' + order.chain + ')'"></div>
                    <div class="col crypto" v-text="order.currency"></div>
                    <div class="col amount" v-text="abs.Util.formatNumber(order.amount)"></div>
                    <div class="col progress" v-text="order.confirms"></div>
                    <div class="col status" v-text="abs.wallet.EDepositStatus[order.status]"></div>
                </div>
            </div>
            <div class="empty" v-if="!orderList.length" v-text="$t('wallet.deposit.no_deposit')"></div>
        </div>
        <div class="show-history"><a class="button link-button" @click="clickHistory" v-text="$t('wallet.deposit.open_history')"></a></div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import CurrencyPicker from "@/components/CurrencyPicker";
import DropDown from "@/components/DropDown";
import CheckboxField from "@/components/CheckboxField";
export default {
    name: "UserAssetDeposit",
    components: {CheckboxField, DropDown, CurrencyPicker},
    props: {
        walletTypes: {
            type: Array,
            default: () => {return [];}
        }
    },
    data () {
        return {
            STEPS: {
                ONE: '1',
                TWO: '2'
            },
            step: '1',
            currency: null,
            chain: null,
            chainList: [],
            walletType: this.abs.wallet.EWalletType.FUNDING,
            address: '',
            extraLabel: '',
            extraValue: '',
            currencyOnly: false,
            orderList: []
        }
    },
    computed: {
        currencyId () {
            return this.currency ? this.currency.id : '';
        },
        currencyText () {
            return this.currency ? this.currency.text : '';
        },
        chainId () {
            return this.chain ? this.chain.id : '';
        },
        chainText () {
            return this.chain ? this.chain.text : '';
        }
    },
    mounted () {
        this.assetDepositList();
        this.websocket.subscribe({
            channel: 'deposit-info'
        }, 'user-asset-deposit', function (content) {
            if (!content.data || !content.data.length) {
                return;
            }
            for (let i = 0; i < content.data.length; i++) {
                let item = content.data[i];

                let exist = false;
                for (let j = 0; j < this.orderList.length; j++) {
                    let order = this.orderList[j];
                    if (order.depId === item.depId) {
                        order.confirms = Number(item.actualDepBlkConfirm);
                        order.status = item.state;
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    this.orderList.unshift({
                        depId: item.depId,
                        time: new Date(Number(item.ts)),
                        address: item.to,
                        chain: item.chain,
                        currency: item.ccy,
                        amount: Number(item.amt),
                        confirms: Number(item.actualDepBlkConfirm),
                        status: item.state
                    });
                }
            }
        }, true);
    },
    unmounted () {
        this.websocket.unsubscribe({
            channel: 'deposit-info'
        }, 'user-asset-deposit', true);
    },
    methods: {
        changeCurrency (currency, chainList) {
            this.currency = currency;
            this.chain = null;
            this.chainList = chainList;
            this.assetDepositList();
        },
        changeChain (chainId, chain) {
            this.chain = chain;
        },
        clickNext () {
            if (!this.currency || !this.chain) {
                return;
            }
            this.step = this.STEPS.TWO;
            this.walletAddress();
        },
        clickEdit () {
            this.address = '';
            this.extraLabel = '';
            this.extraValue = '';
            this.step = this.STEPS.ONE;
        },
        changeWalletType (walletType) {
            this.walletType = walletType;
            this.walletAddress();
        },
        clickCopyAddress () {
            document.querySelector('.deposit-panel .step .block .address .nil-address input').select();
            document.execCommand('copy');
        },
        clickCopyTag () {
            document.querySelector('.deposit-panel .step .block .address .nil-tag input').select();
            document.execCommand('copy');
        },
        changeCurrencyOnly (currencyOnly) {
            this.currencyOnly = currencyOnly;
            this.assetDepositList();
        },
        clickHistory () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_ORDER + '/' + this.abs.routes.USER_ASSET_MODULE_ORDER_FUNDING);
        },
        walletAddress () {
            this.api.walletAddress(this, {
                currency: this.currency.id,
                chain: (this.chain ? this.chain.id : ''),
                walletType: this.walletType
            }, function (rspBody) {
                let split = rspBody.address.addr.indexOf(":");
                if (split > 0) {
                    rspBody.address.addr = rspBody.address.addr.substring(0, split);
                }
                this.address = rspBody.address.addr;
                if (rspBody.address.tag) {
                    this.extraLabel = 'tag';
                    this.extraValue = rspBody.address.tag;
                }
                else if (rspBody.address.memo) {
                    this.extraLabel = 'memo';
                    this.extraValue = rspBody.address.memo;
                }
                else if (rspBody.address.pmtId) {
                    this.extraLabel = 'payment_id';
                    this.extraValue = rspBody.address.pmtId;
                }
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetDepositList () {
            this.api.assetDepositList(this, {
                currency: (this.currencyOnly ? this.currency.id : '')
            }, function (rspBody) {
                let orderList = [];
                for (let i = 0; i < rspBody.depositList.length; i++) {
                    let deposit = rspBody.depositList[i];
                    orderList.push({
                        depId: deposit.depId,
                        time: new Date(Number(deposit.ts)),
                        address: deposit.to,
                        chain: deposit.chain,
                        currency: deposit.ccy,
                        amount: Number(deposit.amt),
                        confirms: Number(deposit.actualDepBlkConfirm),
                        status: deposit.state
                    });
                }
                this.orderList = orderList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.demo{}
.action-panel .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 24px;
}
.action-panel .step {
    padding-left: 18px;
    display: none;
}
.action-panel .step.doing, .action-panel .step.done, .action-panel .step.ending {
    display: block;
}
.action-panel .step .num {
    position: absolute;
    left: 6px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-panel .step.doing .num, .action-panel .step.ending .num {
    border: 1px solid var(--text-default);
}
.action-panel .step .num.num-doing {
    color: var(--text-muted);
    border: 1px solid var(--text-muted);
}
.action-panel .step.done .num.num-doing {
    display: none;
}
.action-panel .step.done .num {
    color: var(--background-primary);
    background-color: var(--text-default);
}
.action-panel .step .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    padding: 0 18px;
}
.action-panel .step .label.label-doing {
    color: var(--text-muted);
}
.action-panel .step.done .label.label-doing {
    display: none;
}
.action-panel .step .block {
    width: 460px;
    padding: 24px 18px;
}
.action-panel .step.doing .block {
    border-left: 1px dashed var(--text-tertiary);
}
.action-panel .step.done .block {
    border-left: 1px solid var(--text-default);
}
.action-panel .step.doing .block.block-done {
    display: none;
}
.action-panel .step.done .block.block-doing {
    display: none;
}
.action-panel .step .block .sub-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    margin-top: 36px;
    margin-bottom: 8px;
}
.action-panel .step .block .notice {
    margin: 8px 0;
    padding: 8px 8px 8px 32px;
    border-radius: 4px;
    background-color: var(--background-secondary);
}
.action-panel .step .block .notice .icon {
    position: absolute;
    top: 10px;
    left: 8px;
}
.action-panel .step .block .notice .text {
    color: var(--text-strong);
    line-height: 20px;
}
.action-panel .step .block .next {
    margin-top: 36px;
}
.action-panel .step .block .next .button {
    height: 40px;
    border-radius: 20px;
}
.action-panel .step .block .crypto-chain {
    color: var(--text-strong);
    font-weight: 500;
    line-height: 20px;
}
.action-panel .step .block .back {
    position: absolute;
    top: 24px;
    right: 18px;
}
.action-panel .step .block .back .button {
    height: 20px;
    color: var(--text-active);
}
.action-panel .step .block .address {
    padding: 8px;
    border-radius: 4px;
    background-color: var(--background-secondary-alt);
    display: flex;
    align-items: center;
}
.action-panel .step .block .address .nil {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.action-panel .step .block .address .text {
    flex: 1;
    line-height: 20px;
    background-color: var(--background-secondary-alt);
    word-wrap: break-word;
    overflow: hidden;
}
.action-panel .step .block .address .icon {
    flex-shrink: 0;
    margin-left: 8px;
    cursor: pointer;
}
.action-panel .step .block .field .holder {
    border-radius: 3px;
    background-color: var(--background-tertiary);
    display: flex;
    align-items: center;
}
.action-panel .step .block .field .holder .unit {
    padding: 0 8px;
}
.action-panel .step .block .amount-available {
    padding: 0 8px;
    border-radius: 3px;
    background-color: var(--background-secondary-alt);
    display: flex;
    align-items: center;
}
.action-panel .step .block .amount-available .text {
    font-size: 12px;
    line-height: 30px;
}
.action-panel .step .block .amount-available .button {
    height: 30px;
}
.action-panel .step .block .amount-limit {
    font-size: 12px;
    line-height: 30px;
}
.action-panel .step .block .est {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
}
.action-panel .step .block .est .est-label {
    color: var(--text-secondary);
}
.action-panel .step .block .est .est-value {
    color: var(--text-strong);
}
.action-panel .step .block .account-exchange {
    display: flex;
    align-items: center;
}
.action-panel .step .block .account-exchange .account {
    flex: 1;
}
.action-panel .step .block .account-exchange .account .acc-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    margin-top: 36px;
    margin-bottom: 8px;
}
.action-panel .step .block .account-exchange .account .acc-field {
    height: 40px;
    padding: 0 8px;
    background-color: var(--background-secondary);
    border: 1px solid var(--background-tertiary);
    border-radius: 3px;
    display: flex;
    align-items: center;
}
.action-panel .step .block .account-exchange .ex {
    margin: 64px 8px 0 8px;
    cursor: pointer;
}
.action-panel .step .block .memos {
    margin-top: 64px;
    padding: 16px;
    background-color: var(--background-secondary);
    border-radius: 4px;
}
.action-panel .step .block .memos .memo {
    padding: 8px 0;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}
.action-panel .step .block .memos .memo .memo-desc {
    color: var(--text-secondary);
}
.action-panel .step .block .memos .memo .memo-value {
    color: var(--text-strong);
}

.action-panel .history {
    margin-top: 36px;
}
.action-panel .history .label-tabs {
    display: flex;
    align-items: center;
}
.action-panel .history .label {
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
}
.action-panel .history .label.label-tab {
    margin-right: 40px;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.action-panel .history .label.label-tab.active {
    border-bottom-color: var(--text-default);
}
.action-panel .history .only {
    position: absolute;
    top: 0;
    left: 260px;
    line-height: 20px;
}
.action-panel .history .list {
    margin-top: 16px;
}
.action-panel .history .row {
    height: 52px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.action-panel .history .body.body-tab {
    display: none;
}
.action-panel .history .body.body-tab.active {
    display: block;
}
.action-panel .history .body .row:hover {
    background-color: var(--background-hover);
}
.action-panel .history .head .col {
    color: var(--text-tertiary);
}
.action-panel .history .empty {
    height: 180px;
    color: var(--text-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-panel .history .show-history {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 20px;
}
.action-panel .history .show-history .button {
    min-width: 0;
    height: 20px;
    color: var(--text-active);
}

.deposit-panel .history .col.time {
    width: 18%;
}
.deposit-panel .history .col.address {
    width: 40%;
}
.deposit-panel .history .col.crypto {
    width: 10%;
}
.deposit-panel .history .col.amount {
    width: 10%;
}
.deposit-panel .history .col.progress {
    width: 10%;
}
.deposit-panel .history .col.status {
    width: 12%;
    text-align: right;
}
.demo{}
</style>