<template>
<div class="msg-toast">
    <div class="content">
        <div class="text"></div><div class="action"><a class="button link-button" @click="clickOk" v-text="$t('common.ok')"></a></div>
    </div>
</div>
</template>

<script>
export default {
    name: "MsgToast",
    methods: {
        clickOk () {
            let toast = document.querySelector('.msg-toast');
            let classList = toast.className.split(' ');
            for (let i = 0; i < classList.length; i++) {
                if (classList[i].trim() === 'show') {
                    classList.splice(i, 1);
                    break;
                }
            }
            toast.className = classList.join(' ');
        }
    }
}
</script>

<style scoped>
.msg-toast {
    position: fixed;
    width: 100%;
    height: 0;
    top: -48px;
    transition: top 0.2s;
    display: flex;
    justify-content: center;
    z-index: 100;
}
.msg-toast.show {
    top: 60px;
}
.msg-toast .content {
    height: 48px;
    border-radius: 6px;
    background-color: var(--background-tertiary);
    display: flex;
    align-items: center;
}
.msg-toast .content .text {
    padding: 0 20px;
    line-height: 20px;
    color: var(--text-strong);
}
.msg-toast .content .action .button {
    color: var(--text-active);
}
</style>