<template>
<div class="side-tab">
    <div class="side-tab-item buy" :class="{'active':side===abs.common.EOrderSide.BUY}" @click="clickOrderSide(abs.common.EOrderSide.BUY)"><span class="text" v-text="$t('component.side_tab.buy')"></span></div>
    <div class="side-tab-item sell" :class="{'active':side===abs.common.EOrderSide.SELL}" @click="clickOrderSide(abs.common.EOrderSide.SELL)"><span class="text" v-text="$t('component.side_tab.sell')"></span></div>
</div>
</template>

<script>
export default {
    name: "SideTab",
    data () {
        return {
            events: {
                CHANGE: 'change'
            },
            side: this.abs.common.EOrderSide.BUY
        };
    },
    methods: {
        clickOrderSide (side) {
            this.side = side;
            this.$emit(this.events.CHANGE, this.side);
        }
    }
}
</script>

<style scoped>
.side-tab {
    background-color: var(--background-secondary);
    border-radius: 3px;
    display: flex;
}
.side-tab .side-tab-item {
    flex: 1;
    height: 32px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.side-tab .side-tab-item.buy.active {
    background-color: var(--index-up);
}
.side-tab .side-tab-item.sell.active {
    background-color: var(--index-down);
}
.side-tab .side-tab-item.active .text {
    color: var(--text-strong);
}
</style>