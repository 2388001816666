<template>
<div class="price-field">
    <div class="label-bar">
        <div class="left">
            <span class="text" v-text="label"></span>
        </div>
        <div class="right" v-if="marketCheck">
            <checkbox-field :label="marketText" :value="market" @changeValue="changeMarket"></checkbox-field>
        </div>
    </div>
    <div class="field-bar">
        <div class="field">
            <label class="input-label"><input class="text" type="text" :disabled="inMarket" :placeholder="placeholder" v-model="valueText" @keydown="keydownValue" @keyup="keyupValue" /></label>
            <span class="input-append" v-text="valueAppend"></span>
        </div>
    </div>
</div>
</template>

<script>
import CheckboxField from "@/components/CheckboxField";
export default {
    name: "PriceField",
    components: {CheckboxField},
    props: {
        label: {
            type: String,
            default: () => {
                return '';
            }
        },
        marketCheck: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        market: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        value: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        text: {
            type: String,
            default: () => {
                return '';
            }
        },
        indexPrice: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        tickSize: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        placeholder: {
            type: String,
            default: () => {
                return '';
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE_VALUE: 'changeValue',
                CHANGE_TEXT: 'changeText'
            },
            marketText: this.$t('component.price_field.market'),
            inMarket: false,
            valueNumber: 0,
            valueText: ''
        }
    },
    computed: {
        valueAppend () {
            let append = this.valueNumber * this.indexPrice;
            return append ? '≈$' + this.abs.Util.formatNumber(append, 2) : '';
        }
    },
    watch: {
        value (value) {
            this.setValue(value);
        },
        text (text) {
            this.setText(text);
        },
        market (market) {
            this.setMarket(market);
        }
    },
    mounted () {
        if (this.value) {
            this.setValue(this.value);
        }
        else if (this.text) {
            this.setText(this.text);
        }
        this.setMarket(this.market);
    },
    methods: {
        setValue (value) {
            if (value !== this.valueNumber) {
                this.valueNumber = this.abs.Util.floor(Math.max(Number(value) || 0, 0), this.tickSize);
                this.valueText = this.valueNumber ? this.abs.Util.formatNumber(this.valueNumber) : '';
                this.$emit(this.EVENTS.CHANGE_TEXT, this.valueText);
            }
        },
        setText (text) {
            if (text !== this.valueText) {
                text = text.replace(/,/g, '');
                this.valueNumber = this.abs.Util.floor(Math.max(Number(text) || 0, 0), this.tickSize);
                this.valueText = text;
            }
        },
        setMarket (market) {
            this.inMarket = market;
            this.valueText = this.inMarket ? this.marketText : (this.valueNumber ? this.abs.Util.formatNumber(this.valueNumber) : '');
        },
        keydownValue (e) {
            if (!this.abs.Util.inputNumberBefore(e, this.valueNumber, this.valueText, this.tickSize, this.tickSize)) {
                e.preventDefault();
            }
        },
        keyupValue () {
            let result = this.abs.Util.inputNumberAfter(this.valueText);
            this.valueNumber = result.valueNumber;
            this.valueText = result.valueText;
            this.$emit(this.EVENTS.CHANGE_VALUE, this.valueNumber, this.valueText);
        },
        changeMarket (market) {
            this.setMarket(market);
        }
    }
}
</script>

<style scoped>
.label-bar {
    margin-bottom: 4px;
    height: 24px;
    display: flex;
}
.label-bar .left {
    flex: 1;
    display: flex;
    align-items: center;
}
.label-bar .right {
    display: flex;
    align-items: center;
}
.label-bar .right .input-label {
    padding-right: 2px;
    font-size: 12px;
}

.field-bar {
    height: 40px;
    display: flex;
}
.field-bar .field {
    flex: 1;
    display: flex;
}
.field-bar .field .input-label {
    flex: 1;
}
.field-bar .field .input-label .text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.field-bar .field .input-append {
    padding: 8px;
    color: var(--text-default);
    background-color: var(--background-tertiary);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    align-items: center;
}
</style>