<template>
<div class="available-field">
    <div class="amount">
        <span class="text label" v-text="$t('component.available_field.available')"></span>
        <span class="text value" v-text="abs.Util.formatNumber(orderSide===abs.common.EOrderSide.BUY ? quoteCcyCount : baseCcyCount)"></span>
        <span class="text unit" v-text="orderSide===abs.common.EOrderSide.BUY ? quoteCcy : baseCcy"></span>
    </div>
    <div class="transfer"><i class="icon exchange-alt" @click="clickTransfer"></i></div>
</div>
</template>

<script>
export default {
    name: "AvailableField",
    props: {
        orderSide: {
            type: String,
            default: () => {
                return this.abs.common.EOrderSide.BUY;
            }
        },
        baseCcy: {
            type: String,
            default: () => {
                return '';
            }
        },
        quoteCcy: {
            type: String,
            default: () => {
                return '';
            }
        },
        baseCcyCount: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        quoteCcyCount: {
            type: Number,
            default: () => {
                return 0;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                TRANSFER: 'transfer'
            }
        }
    },
    methods: {
        clickTransfer () {
            this.$emit(this.EVENTS.TRANSFER);
        }
    }
}
</script>

<style scoped>
.available-field {
    display: flex;
}
.available-field .amount {
    flex: 1;
    height: 20px;
    display: flex;
    align-items: center;
}
.available-field .transfer {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.amount .text {
    margin-right: 4px;
    font-size: 12px;
}
.amount .label {
    color: var(--text-tertiary);
}

.transfer .icon {
    width: 14px;
    height: 14px;
    color: var(--text-active);
    background-color: var(--text-active);
    cursor: pointer;
}
</style>