<template>
<div class="preference">
    <div class="section">
        <div class="title" v-text="$t('account.preference.language')"></div>
        <div class="field"><drop-down :width="140" :height="32" :value="locale" :options="LOCALES" :text-align="'center'" @change="changeLocale"></drop-down></div>
    </div>
    <div class="section">
        <div class="title" v-text="$t('account.preference.chart_colors')"></div>
        <div class="options">
            <div class="option">
                <div class="selector"><radiobox-field :name="'indexMode'" :label="$t('account.preference.color_regular')" :value="indexMode===abs.common.EIndexMode.REGULAR" @changeValue="changeRegular"></radiobox-field></div>
                <div class="memo green"><div class="block"></div><i class="icon long-arrow-alt-up"></i></div>
            </div>
            <div class="option">
                <div class="selector"><radiobox-field :name="'indexMode'" :label="$t('account.preference.color_reverse')" :value="indexMode===abs.common.EIndexMode.REVERSE" @changeValue="changeReverse"></radiobox-field></div>
                <div class="memo red"><div class="block"></div><i class="icon long-arrow-alt-up"></i></div>
            </div>
        </div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import DropDown from "@/components/DropDown";
import RadioboxField from "@/components/RadioboxField";
export default {
    name: "UserAccountPreference",
    components: {RadioboxField, DropDown},
    data () {
        return {
            LOCALES: [{id: 'en-US', text: 'English'}, {id: 'zh-CN', text: '简体中文'}],
            uticket: '',
            locale: '',
            indexMode: this.abs.Store.get('updown') || this.abs.common.EIndexMode.REGULAR
        };
    },
    created () {
        this.uticket = this.abs.Store.uticket();
        this.locale = this.abs.Store.locale();
    },
    methods: {
        changeLocale (locale) {
            this.$i18n.locale = locale;
            this.abs.Store.locale(locale);
        },
        changeRegular (e) {
            if (!e) {
                return;
            }
            this.indexMode = this.abs.common.EIndexMode.REGULAR;
            this.abs.Store.set('updown', this.indexMode);
            this.changeTheme();
        },
        changeReverse (e) {
            if (!e) {
                return;
            }
            this.indexMode = this.abs.common.EIndexMode.REVERSE;
            this.abs.Store.set('updown', this.indexMode);
            this.changeTheme();
        },
        changeTheme () {
            let removeClass = '';
            let addClass = '';
            if (this.indexMode === this.abs.common.EIndexMode.REGULAR) {
                removeClass = 'theme-reverse';
                addClass = 'theme-regular';
            }
            else if (this.indexMode === this.abs.common.EIndexMode.REVERSE) {
                removeClass = 'theme-regular';
                addClass = 'theme-reverse';
            }
            this.abs.Dom.replaceClass(document.querySelector('#app'), removeClass, addClass);
        }
    }
}
</script>

<style scoped>
.section {
    width: 680px;
    margin-bottom: 24px;
    padding: 20px 24px;
    border: 1px solid var(--sep-color);
}
.section .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
}
.section .field {
    position: absolute;
    top: 20px;
    right: 24px;
}
.section .options {
    margin-top: 8px;
}
.section .options .option {
    margin-bottom: 4px;
    padding: 0 16px;
    height: 52px;
    background-color: var(--background-secondary);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.section .options .option .selector {
    line-height: 20px;
}
.section .options .option .memo {
    display: flex;
    align-items: center;
}
.section .options .option .memo .block {
    width: 36px;
    height: 20px;
    border-radius: 3px;
}
.section .options .option .memo.green .block {
    background-color: var(--index-green);
}
.section .options .option .memo.red .block {
    background-color: var(--index-red);
}
.section .options .option .memo.green .icon {
    color: var(--index-green);
    background-color: var(--index-green);
}
.section .options .option .memo.red .icon {
    color: var(--index-red);
    background-color: var(--index-red);
}
</style>