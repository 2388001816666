<template>
<div class="builder">
    <price-field class="seg" :label="$t('component.order_builder.price')+' '+quoteCcyLabel" :value="inPrice" :text="inPriceText" :index-price="inst.quoteIndex" :tick-size="inst.tickSize" :placeholder="$t('component.order_builder.tick_size')+' '+inst.tickSizeText+inst.quoteCcy" @changeValue="changePrice" @changeText="changePriceText"></price-field>
    <count-field class="seg" :label="$t('component.order_builder.amount')+' '+baseCcyLabel" :value="inCount" :text="inCountText" :lot-size="inst.lotSize" :min-size="inst.minSize" :placeholder="$t('component.order_builder.min_size')+' '+inst.minSizeText+inst.baseCcy" @changeValue="changeCount" @changeText="changeCountText"></count-field>
    <slider-bar :value="inRate" @changeValue="changeRate"></slider-bar>
    <amount-field class="seg" :label="$t('component.order_builder.total')+' '+quoteCcyLabel" :value="inAmount" :text="inAmountText" :tick-size="inst.tickSize" :placeholder="$t('component.order_builder.tick_size')+' '+inst.tickSizeText+inst.quoteCcy" @changeValue="changeAmount" @changeText="changeAmountText"></amount-field>
    <available-field class="seg" :order-side="side" :base-ccy="inst.baseCcy" :quote-ccy="inst.quoteCcy" :base-ccy-count="baseCcyCount" :quote-ccy-count="quoteCcyCount" @transfer="clickTransferAvailable"></available-field>
    <div class="seg seg-submit">
        <a class="button" v-if="uticket" :class="{'background-index-up':side===abs.common.EOrderSide.BUY,'background-index-down':side===abs.common.EOrderSide.SELL,'disabled':disabled}" v-text="(side===abs.common.EOrderSide.BUY ? $t('component.order_builder.buy') : $t('component.order_builder.sell'))+' '+inst.baseCcy" @click="clickBuildOrder"></a>
        <a class="button" v-if="!uticket" :class="{'background-index-up':side===abs.common.EOrderSide.BUY,'background-index-down':side===abs.common.EOrderSide.SELL}" v-text="$t('home.login.sign_in')" @click="clickLogin"></a>
    </div>
    <dialog-panel class="dlg-confirm" v-if="confirm" :title="$t('component.order_builder.order_confirmation')" :height="'160px'" @confirm="confirmOrder" @cancel="cancelOrder" @close="closeOrder">
        <div class="content">
            <div class="inst">
                <img class="logo" v-if="inst.logo" :src="inst.logo" alt="" />
                <div class="text" v-text="inst.instId"></div>
                <div class="side" v-text="side===abs.common.EOrderSide.BUY ? $t('component.order_builder.buy') : $t('component.order_builder.sell')"></div>
            </div>
            <div class="order">
                <div class="item">
                    <div class="label" v-text="$t('component.order_builder.price')"></div>
                    <div class="value" v-text="abs.Util.formatNumber(price) + ' ' + inst.quoteCcy"></div>
                </div>
                <div class="item">
                    <div class="label" v-text="$t('component.order_builder.amount')"></div>
                    <div class="value" v-text="abs.Util.formatNumber(count) + ' ' + inst.baseCcy"></div>
                </div>
                <div class="item">
                    <div class="label" v-text="$t('component.order_builder.type')"></div>
                    <div class="value" v-text="$t('component.order_builder.type_limit')"></div>
                </div>
            </div>
        </div>
    </dialog-panel>
</div>
</template>

<script>
import PriceField from "@/components/PriceField";
import CountField from "@/components/CountField";
import AmountField from "@/components/AmountField";
import AvailableField from "@/components/AvailableField";
import SliderBar from "@/components/SliderBar";
import DialogPanel from "@/components/DialogPanel";
export default {
    name: "SpotLimitOrderBuilder",
    components: {PriceField, CountField, AmountField, AvailableField, SliderBar, DialogPanel},
    props: {
        inst: {
            type: Object,
            default: () => {
                return {
                    instId: '',
                    logo: '',
                    last: 0,
                    baseCcy: '',
                    quoteCcy: '',
                    quoteIndex: 0,
                    tickSize: 0,
                    tickSizeText: '',
                    lotSize: 0,
                    lotSizeText: '',
                    minSize: 0,
                    minSizeText: ''
                };
            }
        },
        side: {
            type: String,
            default: () => {
                return this.abs.common.EOrderSide.BUY
            }
        },
        baseCcyCount: {
            type: Number,
            default: () => {
                return 0
            }
        },
        quoteCcyCount: {
            type: Number,
            default: () => {
                return 0
            }
        }
    },
    data () {
        return {
            EVENTS: {
                BUILD_ORDER: 'buildOrder'
            },
            uticket: this.abs.Store.uticket(),
            price: 0,
            count: 0,
            amount: 0,
            inPrice: 0,
            inCount: 0,
            inAmount: 0,
            priceText: '',
            countText: '',
            amountText: '',
            inPriceText: '',
            inCountText: '',
            inAmountText: '',
            inRate: 0,
            confirm: false
        }
    },
    computed: {
        baseCcyLabel () {
            return this.inst.baseCcy ? ('(' + this.inst.baseCcy + ')') : '';
        },
        quoteCcyLabel () {
            return this.inst.quoteCcy ? ('(' + this.inst.quoteCcy + ')') : '';
        },
        disabled () {
            return !this.price || !this.count || !this.amount;
        }
    },
    watch: {
        side () {
            this.count = 0;
            this.amount = 0;
            this.inCount = 0;
            this.inAmount = 0;
            this.countText = '';
            this.amountText = '';
            this.inCountText = '';
            this.inAmountText = '';
            this.inRate = 0;
        },
        'inst.last' (last) {
            this.price = last;
            this.inPrice = last;
        }
    },
    methods: {
        changePrice (price, priceText) {
            this.price = price;
            this.priceText = priceText;
            this.inPriceText = priceText;
            if (this.side === this.abs.common.EOrderSide.BUY) {
                this.amount = this.abs.Util.ceil(this.count * this.price, this.inst.tickSize);
            }
            else {
                this.amount = this.abs.Util.floor(this.count * this.price, this.inst.tickSize);
            }
            this.inAmount = this.amount;

            let rate = 0;
            if (this.side === this.abs.common.EOrderSide.BUY) {
                rate = this.quoteCcyCount ? (this.amount / this.quoteCcyCount) : 0;
            }
            else {
                rate = this.baseCcyCount ? (this.count / this.baseCcyCount) : 0;
            }
            this.inRate = Math.min(rate * 100, 100);
        },
        changeCount (count, countText) {
            this.count = count;
            this.countText = countText;
            this.inCountText = countText;
            if (this.side === this.abs.common.EOrderSide.BUY) {
                this.amount = this.abs.Util.ceil(this.count * this.price, this.inst.tickSize);
            }
            else {
                this.amount = this.abs.Util.floor(this.count * this.price, this.inst.tickSize);
            }
            this.inAmount = this.amount;

            let rate = 0;
            if (this.side === this.abs.common.EOrderSide.BUY) {
                rate = this.quoteCcyCount ? (this.amount / this.quoteCcyCount) : 0;
            }
            else {
                rate = this.baseCcyCount ? (this.count / this.baseCcyCount) : 0;
            }
            this.inRate = Math.min(rate * 100, 100);
        },
        changeAmount (amount, amountText) {
            this.amount = amount;
            this.amountText = amountText;
            this.inAmountText = amountText;
            if (this.side === this.abs.common.EOrderSide.BUY) {
                this.count = this.abs.Util.floor(this.price > 0 ? (this.amount / this.price) : 0, this.inst.lotSize);
            }
            else {
                this.count = this.abs.Util.ceil(this.price > 0 ? (this.amount / this.price) : 0, this.inst.lotSize);
            }
            if (!this.abs.Util.floor(this.count, this.inst.minSize)) {
                this.count = 0;
                this.amount = 0;
            }
            this.inCount = this.count;

            let rate = 0;
            if (this.side === this.abs.common.EOrderSide.BUY) {
                rate = this.quoteCcyCount ? (this.amount / this.quoteCcyCount) : 0;
            }
            else {
                rate = this.baseCcyCount ? (this.count / this.baseCcyCount) : 0;
            }
            this.inRate = Math.min(rate * 100, 100);
        },
        changePriceText (priceText) {
            this.inPriceText = priceText;
        },
        changeCountText (countText) {
            this.inCountText = countText;
        },
        changeAmountText (amountText) {
            this.inAmountText = amountText;
        },
        changeRate (rate) {
            let count = 0;
            let amount = 0;
            if (this.side === this.abs.common.EOrderSide.BUY) {
                amount = this.quoteCcyCount ? (this.quoteCcyCount * rate / 100) : 0;
                amount = this.abs.Util.floor(amount, this.inst.tickSize);
                count = this.price ? (amount / this.price) : 0;
                count = this.abs.Util.floor(count, this.inst.lotSize);
                if (!this.abs.Util.floor(count, this.inst.minSize)) {
                    count = 0;
                    amount = 0;
                }
            }
            else {
                count = this.baseCcyCount ? (this.baseCcyCount * rate / 100) : 0;
                count = this.abs.Util.floor(count, this.inst.lotSize);
                if (!this.abs.Util.floor(count, this.inst.minSize)) {
                    count = 0;
                }
                amount = count * this.price;
                amount = this.abs.Util.floor(amount, this.inst.tickSize);
            }
            this.count = count;
            this.amount = amount;
            this.inCount = count;
            this.inAmount = amount;
        },
        clickTransferAvailable () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_TRANSFER);
        },
        clickBuildOrder () {
            if (this.disabled) {
                return;
            }
            this.confirm = true;
        },
        confirmOrder () {
            this.api.spotMakeOrder(this, {
                instrumentId: this.inst.instId,
                orderSide: this.side,
                orderType: this.abs.common.EOrderType.LIMIT,
                price: this.price,
                count: this.count,
                countCurrency: 'base_ccy'
            }, function () {
                this.confirm = false;
                this.count = 0;
                this.amount = 0;
                this.inCount = 0;
                this.inAmount = 0;
                this.countText = '';
                this.amountText = '';
                this.inCountText = '';
                this.inAmountText = '';
                this.inRate = 0;
            }, function (code, message) {
                this.abs.Toast.show(message);
            });
        },
        cancelOrder () {
            this.confirm = false;
        },
        closeOrder () {
            this.confirm = false;
        },
        clickLogin () {
            this.$router.push(this.abs.routes.HOME_LOGIN);
        }
    }
}
</script>

<style scoped>
.seg {
    margin-top: 12px;
}
.seg-submit {
    margin-top: 32px;
}
.seg-submit .button {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: var(--text-strong);
}

.dlg-confirm .content {
    width: 100%;
    padding: 24px;
}
.dlg-confirm .content .inst {
    display: flex;
    align-items: center;
}
.dlg-confirm .content .inst .logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.dlg-confirm .content .inst .text {
    margin-right: 8px;
    line-height: 20px;
}
.dlg-confirm .content .inst .side {
    color: var(--index-up);
    line-height: 20px;
}
.dlg-confirm .content .order {
    margin-top: 16px;
    display: flex;
}
.dlg-confirm .content .order .item {
    flex: 1;
}
.dlg-confirm .content .order .item .label {
    font-size: 12px;
    line-height: 20px;
    color: var(--text-tertiary);
}
.dlg-confirm .content .order .item .value {
    line-height: 20px;
    color: var(--text-strong);
}
</style>