<template>
<div class="security">
    <div class="section">
        <div class="title" v-text="$t('account.security.security')"></div>
        <div class="features">
            <div class="feature">
                <div class="label">
                    <i class="icon unlock-alt"></i>
                    <div class="name">
                        <div class="main" v-text="$t('account.security.password_title')"></div>
                        <div class="attach" v-text="$t('account.security.password_desc')"></div>
                    </div>
                </div>
                <div class="value">
                    <div class="action"><a class="button link-button" @click="clickChangePassword" v-text="$t('account.security.change_password')"></a></div>
                </div>
            </div>
            <div class="feature">
                <div class="label">
                    <i class="icon mobile-alt"></i>
                    <div class="name">
                        <div class="main" v-text="$t('account.security.phone_title')"></div>
                        <div class="attach" v-text="$t('account.security.phone_desc')"></div>
                    </div>
                </div>
                <div class="value">
                    <div class="text" v-text="phone || '-'"></div>
                    <div class="action"><a class="button link-button" @click="clickChangePhone" v-text="$t('account.security.change_phone')"></a></div>
                </div>
            </div>
            <div class="feature">
                <div class="label">
                    <i class="icon envelope"></i>
                    <div class="name">
                        <div class="main" v-text="$t('account.security.email_title')"></div>
                        <div class="attach" v-text="$t('account.security.email_desc')"></div>
                    </div>
                </div>
                <div class="value">
                    <div class="text" v-text="email || '-'"></div>
                    <div class="action"><a class="button link-button" @click="clickChangeEmail" v-text="$t('account.security.change_email')"></a></div>
                </div>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="title" v-text="$t('account.security.login_history')"></div>
        <div class="records">
            <div class="head">
                <div class="row">
                    <div class="col time" v-text="$t('account.security.time')"></div>
                    <div class="col device" v-text="$t('account.security.device')"></div>
                    <div class="col ip" v-text="$t('account.security.ip')"></div>
                </div>
            </div>
            <div class="body">
                <div class="row" v-for="userRecord in userRecordList" :key="userRecord.recordId">
                    <div class="col time" v-text="$d(userRecord.time, 'long')"></div>
                    <div class="col device" v-text="userRecord.device || '-'"></div>
                    <div class="col ip" v-text="userRecord.ip || '-'"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
export default {
    name: "UserAccountSecurity",
    data () {
        return {
            uticket: '',
            phone: '',
            email: '',
            userRecordList: []
        };
    },
    created () {
        this.uticket = this.abs.Store.uticket();
    },
    mounted () {
        if (this.uticket) {
            this.api.account(this, null, function (rspBody) {
                this.phone = rspBody.user.phone;
                this.email = rspBody.user.email;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
            this.api.userRecordList(this, null, function (rspBody) {
                let userRecordList = [];
                for (let i = 0; i < rspBody.userRecordList.length; i++) {
                    let userRecord = rspBody.userRecordList[i];
                    userRecordList.push({
                        time: new Date(userRecord.recordTime),
                        device: userRecord.device,
                        ip: userRecord.recordIp
                    });
                }
                this.userRecordList = userRecordList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    },
    methods: {
        clickChangePassword () {
            this.$router.push(this.abs.routes.USER_ACCOUNT + '/' + this.abs.routes.USER_ACCOUNT_MODULE_PASSWORD);
        },
        clickChangePhone () {
            this.$router.push(this.abs.routes.USER_ACCOUNT + '/' + this.abs.routes.USER_ACCOUNT_MODULE_PHONE);
        },
        clickChangeEmail () {
            this.$router.push(this.abs.routes.USER_ACCOUNT + '/' + this.abs.routes.USER_ACCOUNT_MODULE_EMAIL);
        }
    }
}
</script>

<style scoped>
.section {
    margin-bottom: 40px;
}
.title {
    font-size: 22px;
}
.features .feature {
    padding: 16px 16px 16px 0;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.features .feature .label {
    padding: 0 36px;
    display: flex;
}
.features .feature .label .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 20px;
    height: 20px;
    z-index: 1;
}
.features .feature .label .name {
    line-height: 20px;
}
.features .feature .label .name .attach {
    font-size: 12px;
    color: var(--text-secondary);
}
.features .feature .value {
    display: flex;
    align-items: center;
}
.features .feature .value .text {
    margin-right: 32px;
    color: var(--text-strong);
}
.features .feature .value .action .button {
    color: var(--text-active);
}
.records .row {
    height: 60px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.records .col {
    line-height: 20px;
}
.records .col.time {
    width: 20%;
}
.records .col.device {
    width: 65%;
}
.records .col.ip {
    width: 15%;
    text-align: center;
}
.records .head .col {
    color: var(--text-tertiary);
}
</style>