<template>
<div class="instrument-picker">
    <div class="drop-field" @click="clickControl">
        <div class="img" v-if="!inst || !inst.logo"></div>
        <img class="img" v-if="inst && inst.logo" :src="inst.logo" alt="" />
        <div class="text" v-text="inst ? inst.baseCcy + '/' + inst.quoteCcy : ''"></div>
        <i class="icon drop-down"></i>
    </div>
    <div class="mask" :class="{'show':showPanel}" @click="clickMask"></div>
    <div class="drop-panel" :class="{'show':showPanel}" :style="'height:'+panelHeight">
        <label class="search"><input class="text" type="text" v-model="filter" /></label>
        <div class="tabs">
            <div class="tab" :class="{'active':blockTab===BLOCK_TABS.FAVOR}" @click="clickBlockTab(BLOCK_TABS.FAVOR)" v-text="$t('component.inst_picker.favorites')"></div>
            <div class="tab" :class="{'active':blockTab===BLOCK_TABS.ALL}" @click="clickBlockTab(BLOCK_TABS.ALL)" v-text="$t('component.inst_picker.all')"></div>
            <div class="tab" :class="{'active':blockTab===block.code}" v-for="block in blockList" :key="block.code" v-text="block.name" @click="clickBlockTab(block.code)"></div>
        </div>
        <div class="cols">
            <div class="row">
                <div class="col col-instrument" v-text="$t('component.inst_picker.pair')"></div>
                <div class="col col-price" v-text="$t('component.inst_picker.last')"></div>
                <div class="col col-change" v-text="$t('component.inst_picker.change')"></div>
            </div>
        </div>
        <div class="rows small-scroll" @scroll="scrollPanel">
            <div class="row" v-for="item in filterList" :key="item.instId" @click="clickInstrument(item)">
                <div class="col col-instrument"><i class="icon star"></i><div class="text" v-text="item.baseCcy + '/' + item.quoteCcy"></div><i class="hot"></i></div>
                <div class="col col-price"><div class="text" v-text="item.last"></div></div>
                <div class="col col-change"><div :class="{'index-up':item.updown>0,'index-down':item.updown<0}" v-text="(item.updown>0 ? '+' : '') + abs.Util.formatNumber(item.updown, 2) + '%'"></div></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "InstrumentPicker",
    props: {
        instrumentType: {
            type: String,
            default: () => {
                return 'SPOT';
            }
        },
        instrument: {
            type: Object,
            default: () => {
                return null;
            }
        },
        panelHeight: {
            type: String,
            default: () => {
                return '600px';
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE: 'change'
            },
            BLOCK_TABS: {
                FAVOR: 'favor',
                ALL: 'all'
            },
            inst: null,
            filter: '',
            blockTab: 'all',
            blockList: [],
            blockInstrumentLists: [],
            favorList: [],
            instrumentList: [],
            instruments: [],
            currentList: [],
            tickers: [],
            showPanel: false
        };
    },
    computed: {
        filterList () {
            if (!this.filter) {
                return this.currentList;
            }
            let filter = this.filter.toLocaleUpperCase();
            let filterList = [];
            for (let i = 0; i < this.currentList.length; i++) {
                let item = this.currentList[i];
                if (item.baseCcy.indexOf(filter) >= 0 || item.quoteCcy.indexOf(filter) >= 0) {
                    filterList.push(item);
                }
            }
            return filterList;
        }
    },
    watch: {
        instrument (instrument) {
            if (instrument) {
                this.inst = instrument;
            }
        },
        showPanel (showPanel) {
            if (showPanel) {
                this.$nextTick(() => {
                    let target = document.querySelector('.instrument-picker .drop-panel .rows');
                    this.buildTickers(target.scrollTop, target.clientHeight);
                });
            }
            else {
                this.cleanTickers(false);
            }
        }
    },
    mounted () {
        if (this.instrument) {
            this.inst = this.instrument;
        }
        this.api.instruments(this, {
            instrumentType: this.instrumentType
        }, function (rspBody) {
            this.blockList = rspBody.blockList;
            rspBody.blockList.forEach((block) => {
                this.blockInstrumentLists[block.code] = [];
            }, this);
            rspBody.instruments.forEach((instrument) => {
                instrument.last = '-';
                instrument.change = '-';
                instrument.h24low = '-';
                instrument.h24high = '-';
                instrument.h24volume = '-';
                instrument.h24turnover = '-';
                instrument.updown = 0;
                let tickSize = instrument.tickSz.indexOf('.');
                instrument.tickSize = tickSize > 0 ? instrument.tickSz.length - tickSize - 1 : 0;
                let lotSize = instrument.lotSz.indexOf('.');
                instrument.lotSize = lotSize > 0 ? instrument.lotSz.length - lotSize - 1 : 0;
                this.instruments[instrument.instId] = instrument;
                if (instrument.blocks) {
                    instrument.blocks.forEach((block) => {
                        let instrumentList = this.blockInstrumentLists[block];
                        if (instrumentList) {
                            instrumentList.push(instrument);
                        }
                    }, this);
                }
            }, this);
            this.instrumentList = rspBody.instruments;
            this.clickBlockTab(this.blockTab);
            if (!this.inst && this.currentList.length > 0) {
                this.inst = this.currentList[0];
                this.$emit(this.EVENTS.CHANGE, this.inst);
            }
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });

        let _this = this;
        this.websocket.observe('tickers', 'instrument-picker', function (content) {
            let data = content.data[0];
            let inst = _this.instruments[data.instId];
            if (!inst) {
                return;
            }
            inst.updown = (Number(data.last) - Number(data.open24h)) / Number(data.open24h);
            inst.last = _this.abs.Util.formatNumber(Number(data.last), inst.tickSize);
            inst.change = (inst.updown * 100).toFixed(2);
            inst.h24low = _this.abs.Util.formatNumber(Number(data.low24h), inst.tickSize);
            inst.h24high = _this.abs.Util.formatNumber(Number(data.high24h), inst.tickSize);
            inst.h24volume = _this.abs.Util.formatNumber(Number(data.vol24h), 0);
            inst.h24turnover = _this.abs.Util.formatNumber(Number(data.volCcy24h), 0);
        });
    },
    unmounted () {
        this.cleanTickers(true);
    },
    methods: {
        clickControl () {
            this.showPanel = !this.showPanel;
        },
        clickMask () {
            this.showPanel = false;
        },
        clickBlockTab (blockTab) {
            this.blockTab = blockTab;
            if (blockTab === this.BLOCK_TABS.FAVOR) {
                this.currentList = this.favorList;
            }
            else if (blockTab === this.BLOCK_TABS.ALL) {
                this.currentList = this.instrumentList;
            }
            else {
                this.currentList = this.blockInstrumentLists[blockTab];
            }
        },
        clickInstrument (inst) {
            this.inst = inst;
            this.showPanel = false;
            this.$emit(this.EVENTS.CHANGE, this.inst);
        },
        clickFavor (inst) {
            inst.favor = !inst.favor;
        },
        scrollPanel (e) {
            this.buildTickers(e.target.scrollTop, e.target.clientHeight);
        },
        buildTickers (scrollTop, clientHeight) {
            if (this.scrollTimer) {
                clearTimeout(this.scrollTimer);
            }
            let _this = this;
            this.scrollTimer = setTimeout(() => {
                let base = 0;
                let step = 50;
                let offset = Math.max(scrollTop - base, 0);
                let height = clientHeight - base;
                let start = Math.floor(offset / step);
                let limit = Math.ceil(height / step);
                let tickers = [];
                let exist = false;
                for (let i = start; i < start + limit && i < _this.filterList.length; i++) {
                    let inst = _this.filterList[i];
                    tickers[inst.instId] = inst;
                    if (inst.instId === _this.inst.instId) {
                        exist = true;
                    }
                }
                if (!exist) {
                    tickers[_this.inst.instId] = _this.inst;
                }
                for (let instId in tickers) {
                    if (instId in _this.tickers) {
                        delete _this.tickers[instId];
                        continue;
                    }
                    _this.websocket.subscribe({
                        channel: 'tickers',
                        instId: instId
                    });
                }
                for (let instId in _this.tickers) {
                    _this.websocket.unsubscribe({
                        channel: 'tickers',
                        instId: instId
                    });
                }
                _this.tickers = tickers;
            }, 500);
        },
        cleanTickers (unmounted) {
            for (let instId in this.tickers) {
                if (instId === this.inst.instId && !unmounted) {
                    continue;
                }
                this.websocket.unsubscribe({
                    channel: 'tickers',
                    instId: instId
                }, (unmounted ? 'instrument-picker' : ''));
            }
            for (let instId in this.tickers) {
                if (instId === this.inst.instId && !unmounted) {
                    continue;
                }
                delete this.tickers[instId];
            }
        }
    }
}
</script>

<style scoped>
.instrument-picker {
    width: 100%;
    padding-left: 20px;
}
.instrument-picker .drop-field {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.instrument-picker .drop-field .img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.instrument-picker .drop-field .text {
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
    color: var(--text-strong);
}
.instrument-picker .drop-field .icon {
    width: 14px;
    height: 14px;
    background: url(../assets/img/drop-down.png) center no-repeat;
    background-size: contain;
}

.instrument-picker .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 10;
    display: none;
}
.instrument-picker .mask.show {
    display: block;
}

.instrument-picker .drop-panel {
    position: absolute;
    top: 50px;
    left: 0;
    width: 440px;
    border-radius: 5px;
    border: 1px solid var(--background-secondary-alt);
    background-color: var(--background-secondary);
    z-index: 20;
    display: none;
}
.instrument-picker .drop-panel.show {
    display: block;
}
.instrument-picker .drop-panel .search {
    display: block;
    padding: 10px;
}
.instrument-picker .drop-panel .tabs {
    height: 40px;
    padding: 0 10px;
    display: flex;
    border-bottom: 1px solid var(--sep-color);
}
.instrument-picker .drop-panel .tabs .tab {
    height: 40px;
    line-height: 30px;
    margin: 0 10px;
    color: var(--text-default);
    cursor: pointer;
}
.instrument-picker .drop-panel .tabs .tab.active {
    color: var(--text-strong);
    border-bottom: 3px solid var(--text-strong);
}
.instrument-picker .drop-panel .cols {
    padding: 0 20px;
}
.instrument-picker .drop-panel .cols .col {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: var(--text-default);
}
.instrument-picker .drop-panel .cols .col.col-instrument {
    padding-left: 20px;
}
.instrument-picker .drop-panel .rows {
    position: absolute;
    top: 130px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
}
.instrument-picker .drop-panel .rows .row {
    padding: 0 20px;
    cursor: pointer;
}
.instrument-picker .drop-panel .rows .row:hover {
    background-color: var(--background-hover);
}
.instrument-picker .drop-panel .rows .col {
    height: 50px;
    line-height: 50px;
    color: var(--text-strong);
}
.instrument-picker .drop-panel .row {
    display: flex;
}
.instrument-picker .drop-panel .row .col.col-instrument {
    width: 40%;
    display: flex;
    align-items: center;
}
.instrument-picker .drop-panel .row .col.col-instrument .icon {
    margin: 0 4px 2px 0;
}
.instrument-picker .drop-panel .row .col.col-price {
    width: 30%;
    text-align: right;
}
.instrument-picker .drop-panel .row .col.col-change {
    width: 30%;
    text-align: right;
}
</style>