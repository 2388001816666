<template>
<div class="trade-chart">
    <div class="option-bar">
        <div class="left-block" :class="{'hidden':view===VIEWS.DEPTH}">
            <div class="scales">
                <div class="scale" :class="{'active':scale===SCALES.MINUTE1}" @click="clickScale(SCALES.MINUTE1, SUBSCRIBE_SCALES.MINUTE1)" v-text="$t('component.trade_chart.min1')"></div>
                <div class="scale" :class="{'active':scale===SCALES.MINUTE5}" @click="clickScale(SCALES.MINUTE5, SUBSCRIBE_SCALES.MINUTE5)" v-text="$t('component.trade_chart.min5')"></div>
                <div class="scale" :class="{'active':scale===SCALES.MINUTE15}" @click="clickScale(SCALES.MINUTE15, SUBSCRIBE_SCALES.MINUTE15)" v-text="$t('component.trade_chart.min15')"></div>
                <div class="scale" :class="{'active':scale===SCALES.MINUTE30}" @click="clickScale(SCALES.MINUTE30, SUBSCRIBE_SCALES.MINUTE30)" v-text="$t('component.trade_chart.min30')"></div>
                <div class="scale" :class="{'active':scale===SCALES.HOUR1}" @click="clickScale(SCALES.HOUR1, SUBSCRIBE_SCALES.HOUR1)" v-text="$t('component.trade_chart.hour1')"></div>
                <div class="scale" :class="{'active':scale===SCALES.HOUR2}" @click="clickScale(SCALES.HOUR2, SUBSCRIBE_SCALES.HOUR2)" v-text="$t('component.trade_chart.hour2')"></div>
                <div class="scale" :class="{'active':scale===SCALES.HOUR4}" @click="clickScale(SCALES.HOUR4, SUBSCRIBE_SCALES.HOUR4)" v-text="$t('component.trade_chart.hour4')"></div>
                <div class="scale" :class="{'active':scale===SCALES.HOUR6}" @click="clickScale(SCALES.HOUR6, SUBSCRIBE_SCALES.HOUR6)" v-text="$t('component.trade_chart.hour6')"></div>
                <div class="scale" :class="{'active':scale===SCALES.HOUR12}" @click="clickScale(SCALES.HOUR12, SUBSCRIBE_SCALES.HOUR12)" v-text="$t('component.trade_chart.hour12')"></div>
                <div class="scale" :class="{'active':scale===SCALES.DAY1}" @click="clickScale(SCALES.DAY1, SUBSCRIBE_SCALES.DAY1)" v-text="$t('component.trade_chart.day1')"></div>
                <div class="scale" :class="{'active':scale===SCALES.WEEK1}" @click="clickScale(SCALES.WEEK1, SUBSCRIBE_SCALES.WEEK1)" v-text="$t('component.trade_chart.week1')"></div>
                <div class="scale" :class="{'active':scale===SCALES.MONTH1}" @click="clickScale(SCALES.MONTH1, SUBSCRIBE_SCALES.MONTH1)" v-text="$t('component.trade_chart.month1')"></div>
            </div>
        </div>
        <div class="right-block" v-if="false">
            <div class="views">
                <div class="view" :class="{'active':view===VIEWS.CANDLE}" @click="clickView(VIEWS.CANDLE)" v-text="$t('component.trade_chart.view_original')"></div>
                <div class="view" :class="{'active':view===VIEWS.DEPTH}" @click="clickView(VIEWS.DEPTH)" v-text="$t('component.trade_chart.view_depth')"></div>
            </div>
        </div>
    </div>
    <div class="indicator ticker" :class="{'show':view===VIEWS.CANDLE && indicator.show, 'fold':!indicator.showCandleValue}">
        <i class="icon" :class="{'chevron-down':!indicator.showCandleValue,'chevron-right':indicator.showCandleValue}" @click="clickCandleValueIndicator"></i>
        <span class="name" v-text="indicator.candleValue.time"></span>
        <span class="item"><span class="label" v-text="$t('component.trade_chart.open')"></span><span class="value" :style="indicator.candleValue.color ? 'color:' + indicator.candleValue.color : ''" v-text="indicator.candleValue.open"></span></span>
        <span class="item"><span class="label" v-text="$t('component.trade_chart.high')"></span><span class="value" :style="indicator.candleValue.color ? 'color:' + indicator.candleValue.color : ''" v-text="indicator.candleValue.high"></span></span>
        <span class="item"><span class="label" v-text="$t('component.trade_chart.low')"></span><span class="value" :style="indicator.candleValue.color ? 'color:' + indicator.candleValue.color : ''" v-text="indicator.candleValue.low"></span></span>
        <span class="item"><span class="label" v-text="$t('component.trade_chart.close')"></span><span class="value" :style="indicator.candleValue.color ? 'color:' + indicator.candleValue.color : ''" v-text="indicator.candleValue.close"></span></span>
        <span class="item"><span class="label" v-text="$t('component.trade_chart.change')"></span><span class="value" :style="indicator.candleValue.color ? 'color:' + indicator.candleValue.color : ''" v-text="indicator.candleValue.change"></span></span>
        <span class="item"><span class="label" v-text="$t('component.trade_chart.range')"></span><span class="value" :style="indicator.candleValue.color ? 'color:' + indicator.candleValue.color : ''" v-text="indicator.candleValue.range"></span></span>
    </div>
    <div class="indicator ma" :class="{'show':view===VIEWS.CANDLE && indicator.show, 'fold':!indicator.showCandleMa}">
        <i class="icon" :class="{'chevron-down':!indicator.showCandleMa,'chevron-right':indicator.showCandleMa}" @click="clickCandleMaIndicator"></i>
        <span class="name">MA</span>
        <span class="item"><span class="label">MA5</span><span class="value" v-text="indicator.candleMa.ma5"></span></span>
        <span class="item"><span class="label">MA10</span><span class="value" v-text="indicator.candleMa.ma10"></span></span>
        <span class="item"><span class="label">MA20</span><span class="value" v-text="indicator.candleMa.ma20"></span></span>
    </div>
    <div class="indicator volume" :class="{'show':view===VIEWS.CANDLE && indicator.show, 'fold':!indicator.showVolume}" :style="'top:' + (volumeIndicatorTop + 4) + 'px;'">
        <i class="icon" :class="{'chevron-down':!indicator.showVolume,'chevron-right':indicator.showVolume}" @click="clickVolumeIndicator"></i>
        <span class="name">VOLUME(5, 10)</span>
        <span class="item"><span class="label" v-text="'VOL(' + inst.baseCcy + ')'"></span><span class="value" v-text="indicator.volume.volBaseCcy"></span></span>
        <span class="item"><span class="label" v-text="'VOL(' + inst.quoteCcy + ')'"></span><span class="value" v-text="indicator.volume.volQuoteCcy"></span></span>
        <span class="item"><span class="label">MA5</span><span class="value" v-text="indicator.volume.ma5"></span></span>
        <span class="item"><span class="label">MA10</span><span class="value" v-text="indicator.volume.ma10"></span></span>
    </div>
    <div class="candle-view" :class="{'active':view===VIEWS.CANDLE}"></div>
    <div class="depth-view" :class="{'active':view===VIEWS.DEPTH}"></div>
</div>
</template>

<script>
import { createChart } from "lightweight-charts";
export default {
    name: "TradeChart",
    props: {
        inst: {
            type: Object,
            default: () => {
                return {
                    instId: '',
                    instType: '',
                    baseCcy: '',
                    quoteCcy: '',
                    baseIndex: 0,
                    quoteIndex: 0,
                    tickSize: 0,
                    lotSize: 0
                };
            }
        },
        indexMode: {
            type: String,
            default: () => {
                return 'regular';
            }
        }
    },
    data () {
        return {
            VIEWS: {
                CANDLE: 'candle',
                DEPTH: 'depth'
            },
            SCALES: {
                MINUTE1: 60, MINUTE5: 300, MINUTE15: 900, MINUTE30: 1800,
                HOUR1: 3600, HOUR2: 7200, HOUR4: 14400, HOUR6: 21600, HOUR12: 43200,
                DAY1: 86400, WEEK1: 604800, MONTH1: 2592000
            },
            SUBSCRIBE_SCALES: {
                MINUTE1: 'candle1m', MINUTE5: 'candle5m', MINUTE15: 'candle15m', MINUTE30: 'candle30m',
                HOUR1: 'candle1H', HOUR2: 'candle2H', HOUR4: 'candle4H', HOUR6: 'candle6Hutc', HOUR12: 'candle12Hutc',
                DAY1: 'candle1Dutc', WEEK1: 'candle1Wutc', MONTH1: 'candle1Mutc'
            },
            COLORS: {//TO:这是默认值，要通过计算属性支持自定义
                TEXT_COLOR: '#ffffff', BACKGROUND_COLOR: '#36393f', BACKGROUND_ACTIVE_COLOR: '#43464c',
                BORDER_COLOR: 'rgba(79, 84, 92, 0.48)', GRID_LINE_COLOR: 'rgba(79, 84, 92, 0.4)',
                INDEX_UP_COLOR: '#0ecb81', INDEX_DOWN_COLOR: '#f6465d', INDEX_UP_HOVER_COLOR: 'rgba(14, 203, 129, 0.2)', INDEX_DOWN_HOVER_COLOR: 'rgba(246, 70, 93, 0.2)',
                CANDLE_MA5_COLOR: '#ffffff', CANDLE_MA10_COLOR: '#f7b715', CANDLE_MA20_COLOR: '#d051da',
                VOLUME_MA5_COLOR: '#ffffff', VOLUME_MA10_COLOR: '#f7b715'
            },
            view: 'candle',
            scale: 900,
            subscribeScale: 'candle15m',
            indicator: {
                show: false,
                showCandleValue: true,
                showCandleMa: true,
                showVolume: true,
                candleValue: {
                    time: ' - ',
                    open: ' - ',
                    high: ' - ',
                    low: ' - ',
                    close: ' - ',
                    change: ' - ',
                    range: ' - ',
                    color: ''
                },
                candleMa: {
                    ma5: ' - ',
                    ma10: ' - ',
                    ma20: ' - '
                },
                volume: {
                    volBaseCcy: ' - ',
                    volQuoteCcy: ' - ',
                    ma5: ' - ',
                    ma10: ' - '
                }
            },
            crosshair: false,
            candleHeight: 376,
            appendBuffer: 50,
            appending: false,
            completed: false,
            depth: {
                BOOK_SIZE: 50,
                subscribeChannel: 'books'
                //chart: null,
                //bidSeries: null,
                //askSeries: null,
                //bids: null,
                //asks: null,
                //bs: null,
                //as: null
            }
            //chart: null,
            //candleSeries: null,
            //candleMa5Series: null,
            //candleMa10Series: null,
            //candleMa20Series: null,
            //volumeSeries: null,
            //volumeMa5Series: null,
            //volumeMa10Series: null,
            //candles: [],
            //candlesMa5: [],
            //candlesMa10: [],
            //candlesMa20: [],
            //volumes: [],
            //volumesMa5: [],
            //volumesMa10: [],
            //volumeMap: []
        };
    },
    computed: {
        upColor () {
            return this.indexMode === this.abs.common.EIndexMode.REGULAR ? this.COLORS.INDEX_UP_COLOR : this.COLORS.INDEX_DOWN_COLOR;
        },
        downColor () {
            return this.indexMode === this.abs.common.EIndexMode.REGULAR ? this.COLORS.INDEX_DOWN_COLOR : this.COLORS.INDEX_UP_COLOR;
        },
        upHoverColor () {
            return this.indexMode === this.abs.common.EIndexMode.REGULAR ? this.COLORS.INDEX_UP_HOVER_COLOR : this.COLORS.INDEX_DOWN_HOVER_COLOR;
        },
        downHoverColor () {
            return this.indexMode === this.abs.common.EIndexMode.REGULAR ? this.COLORS.INDEX_DOWN_HOVER_COLOR : this.COLORS.INDEX_UP_HOVER_COLOR;
        },
        volumeIndicatorTop () {
            return this.candleHeight ? this.candleHeight + 40 : 0;
        }
    },
    watch: {
        //*/
        'inst.instId' (newInstId, oldInstId) {
            this.loadCandles(newInstId, this.scale, this.subscribeScale, oldInstId, this.subscribeScale);
            this.subscribeBooks(newInstId, oldInstId);
        }
        //*/
    },
    mounted () {
        let _this = this;
        this.chart = window.LightweightCharts.createChart(document.querySelector('.candle-view'), {
            layout: {
                textColor: this.COLORS.TEXT_COLOR,
                backgroundColor: this.COLORS.BACKGROUND_COLOR,
                fontSize: 12,
                fontFamily: '-apple-system, BlinkMacSystemFont, \'Trebuchet MS\', Roboto, Ubuntu, sans-serif'
            },
            timeScale: {
                rightOffset: 10,
                barSpacing: 10,
                minBarSpacing: 0.5,
                borderColor: this.COLORS.BORDER_COLOR,
                tickMarkFormatter: (time, type) => {
                    let format = 'short_time';
                    if (type === 0) {
                        format = 'year';
                    }
                    else if (type === 1) {
                        format = 'year_month';
                    }
                    else if (type === 2) {
                        format = 'month_date';
                    }
                    else if (type === 4) {
                        format = 'long_time';
                    }
                    return _this.$d(new Date(time), format);
                }
            },
            priceScale: {
                drawTicks: false,
                scaleMargins: {
                    top: 0.15,
                    bottom: 0.05
                },
                borderColor: this.COLORS.BORDER_COLOR
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: this.COLORS.TEXT_COLOR,
                    labelBackgroundColor: this.COLORS.BACKGROUND_ACTIVE_COLOR
                },
                horzLine: {
                    color: this.COLORS.TEXT_COLOR,
                    labelBackgroundColor: this.COLORS.BACKGROUND_ACTIVE_COLOR
                }
            },
            grid: {
                vertLines: {
                    color: this.COLORS.GRID_LINE_COLOR
                },
                horzLines: {
                    color: this.COLORS.GRID_LINE_COLOR
                }
            },
            localization: {
                locale: this.abs.Store.locale(),
                priceFormatter: (priceValue) => {
                    return _this.abs.Util.formatNumber(priceValue, _this.inst.tickSize);
                }
            },
            handleScale: {
                mouseWheel: false
            }
        });
        this.chart.subscribeCrosshairMove(parameter => {
            let time = parameter.time;
            let candleTicker = parameter.seriesPrices.get(this.candleSeries);
            if (!time || !candleTicker) {
                this.crosshair = false;
                this.indicator.candleValue = this.candleValueIndicatorFormat(this.lastCandle());
                this.indicator.candleMa = this.candleMaIndicatorFormat(this.lastCandleMa5() ? this.lastCandleMa5().value : 0, this.lastCandleMa10() ? this.lastCandleMa10().value : 0, this.lastCandleMa20() ? this.lastCandleMa20().value : 0);
                this.indicator.volume = this.volumeIndicatorFormat(this.lastVolume(), this.lastVolumeMa5() ? this.lastVolumeMa5().value : 0, this.lastVolumeMa10() ? this.lastVolumeMa10().value : 0);
                return;
            }

            this.crosshair = true;

            this.indicator.candleValue = this.candleValueIndicatorFormat({
                time: time,
                open: candleTicker.open,
                high: candleTicker.high,
                low: candleTicker.low,
                close: candleTicker.close
            });
            let candleMa5Ticker = parameter.seriesPrices.get(this.candleMa5Series);
            let candleMa10Ticker = parameter.seriesPrices.get(this.candleMa10Series);
            let candleMa20Ticker = parameter.seriesPrices.get(this.candleMa20Series);
            this.indicator.candleMa = this.candleMaIndicatorFormat(candleMa5Ticker, candleMa10Ticker, candleMa20Ticker);

            let volumeMa5Ticker = parameter.seriesPrices.get(this.volumeMa5Series);
            let volumeMa10Ticker = parameter.seriesPrices.get(this.volumeMa10Series);
            let volumeTicker = this.volumeMap[String(time)];
            this.indicator.volume = this.volumeIndicatorFormat(volumeTicker, volumeMa5Ticker, volumeMa10Ticker);
        });
        this.chart.subscribePaneResize(parameter => {
            if (!parameter || !parameter.top) {
                return;
            }
            this.candleHeight = parameter.top.height;
        });
        this.chart.timeScale().subscribeVisibleLogicalRangeChange((parameter) => {
            if (!this.candleSeries) {
                return;
            }
            let timeRange = this.candleSeries.barsInLogicalRange(parameter);
            if (!timeRange) {
                return;
            }
            if (timeRange.barsBefore < this.appendBuffer) {
                this.appendCandles();
            }
        });

        this.depth.chart = createChart(document.querySelector('.depth-view'), {
            layout: {
                textColor: this.COLORS.TEXT_COLOR,
                background: {
                    color: this.COLORS.BACKGROUND_COLOR
                },
                fontSize: 12,
                fontFamily: '-apple-system, BlinkMacSystemFont, \'Trebuchet MS\', Roboto, Ubuntu, sans-serif'
            },
            timeScale: {
                fixLeftEdge: true,
                fixRightEdge: true,
                borderColor: this.COLORS.BORDER_COLOR,
                tickMarkFormatter: (value) => {
                    return value;
                }
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.1,
                    bottom: 0
                },
                borderColor: this.COLORS.BORDER_COLOR
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: this.COLORS.TEXT_COLOR,
                    labelBackgroundColor: this.COLORS.BACKGROUND_ACTIVE_COLOR
                },
                horzLine: {
                    color: this.COLORS.TEXT_COLOR,
                    labelBackgroundColor: this.COLORS.BACKGROUND_ACTIVE_COLOR
                }
            },
            grid: {
                vertLines: {
                    color: this.COLORS.GRID_LINE_COLOR
                },
                horzLines: {
                    color: this.COLORS.GRID_LINE_COLOR
                }
            },
            localization: {
                locale: this.abs.Store.locale(),
                priceFormatter: (priceValue) => {
                    return _this.abs.Util.formatNumber(priceValue, _this.inst.tickSize);
                }
            },
            handleScale: false,
            kineticScroll: false
        });

        //*/
        this.depth.chart.subscribeCrosshairMove(parameter => {
            console.log(parameter);//TODO:深度图移动鼠标的反应
        });
        //*/
    },
    unmounted () {
        if (this.inst.instId) {
            this.websocket.unsubscribe({
                'channel': this.subscribeScale,
                'instId': this.inst.instId
            }, 'candle-chart');
            this.websocket.unsubscribe({
                'channel': this.depth.subscribeChannel,
                'instId': this.inst.instId
            }, 'depth-chart');
        }
        if (this.chart) {
            this.chart.remove();
            this.chart = null;
        }
        if (this.depth.chart) {
            this.depth.chart.remove();
            this.depth.chart = null;
        }
        this.candleSeries = null;
        this.candleMa5Series = null;
        this.candleMa10Series = null;
        this.candleMa20Series = null;
        this.volumeSeries = null;
        this.volumeMa5Series = null;
        this.volumeMa10Series = null;
        this.candles = [];
        this.candlesMa5 = [];
        this.candlesMa10 = [];
        this.candlesMa20 = [];
        this.volumes = [];
        this.volumesMa5 = [];
        this.volumesMa10 = [];
        this.volumeMap = [];
        this.depth.bidSeries = null;
        this.depth.askSeries = null;
        this.depth.bids = [];
        this.depth.asks = [];
        this.depth.bs = [];
        this.depth.as = [];
    },
    methods: {
        clickView (view) {
            this.view = view;
        },
        clickScale (scale, subscribeScale) {
            if (this.scale === scale) {
                return;
            }
            let oldSubscribeScale = this.subscribeScale;
            this.scale = scale;
            this.subscribeScale = subscribeScale;
            this.loadCandles(this.inst.instId, this.scale, this.subscribeScale, this.inst.instId, oldSubscribeScale);
        },
        clickCandleValueIndicator () {
            this.indicator.showCandleValue = !this.indicator.showCandleValue;
        },
        clickCandleMaIndicator () {
            this.indicator.showCandleMa = !this.indicator.showCandleMa;
        },
        clickVolumeIndicator () {
            this.indicator.showVolume = !this.indicator.showVolume;
        },
        loadCandles (newInstId, newScale, newSubscribeScale, oldInstId, oldSubscribeScale) {
            if (oldInstId) {
                this.websocket.unsubscribe({
                    'channel': oldSubscribeScale,
                    'instId': oldInstId
                }, 'candle-chart');
            }

            if (this.candleSeries) {
                this.chart.removeSeries(this.candleSeries);
                this.candleSeries = null;
            }
            if (this.candleMa5Series) {
                this.chart.removeSeries(this.candleMa5Series);
                this.candleMa5Series = null;
            }
            if (this.candleMa10Series) {
                this.chart.removeSeries(this.candleMa10Series);
                this.candleMa10Series = null;
            }
            if (this.candleMa20Series) {
                this.chart.removeSeries(this.candleMa20Series);
                this.candleMa20Series = null;
            }
            if (this.volumeSeries) {
                this.chart.removeSeries(this.volumeSeries);
                this.volumeSeries = null;
            }
            if (this.volumeMa5Series) {
                this.chart.removeSeries(this.volumeMa5Series);
                this.volumeMa5Series = null;
            }
            if (this.volumeMa10Series) {
                this.chart.removeSeries(this.volumeMa10Series);
                this.volumeMa10Series = null;
            }
            this.chart.timeScale().resetTimeScale();

            this.candles = [];
            this.candlesMa5 = [];
            this.candlesMa10 = [];
            this.candlesMa20 = [];
            this.volumes = [];
            this.volumesMa5 = [];
            this.volumesMa10 = [];
            this.volumeMap = [];
            this.crosshair = false;

            this.api.candles(this, {
                instrumentId: newInstId,
                seconds: newScale,
                limit: 300
            }, function (rspBody) {
                this.candleSeries = this.chart.addCandlestickSeries({
                    priceScaleId: 'right',
                    upColor: this.upColor,
                    downColor: this.downColor,
                    borderVisible: false,
                    borderUpColor: this.upColor,
                    borderDownColor: this.downColor,
                    wickUpColor: this.upColor,
                    wickDownColor: this.downColor,
                    pane: 0
                });
                this.candleMa5Series = this.chart.addLineSeries({
                    priceLineVisible: false,
                    lastValueVisible: false,
                    lineWidth: 1,
                    color: this.COLORS.CANDLE_MA5_COLOR,
                    pane: 0
                });
                this.candleMa10Series = this.chart.addLineSeries({
                    priceLineVisible: false,
                    lastValueVisible: false,
                    lineWidth: 1,
                    color: this.COLORS.CANDLE_MA10_COLOR,
                    pane: 0
                });
                this.candleMa20Series = this.chart.addLineSeries({
                    priceLineVisible: false,
                    lastValueVisible: false,
                    lineWidth: 1,
                    color: this.COLORS.CANDLE_MA20_COLOR,
                    pane: 0
                });
                this.volumeSeries = this.chart.addHistogramSeries({
                    priceLineVisible: false,
                    lastValueVisible: false,
                    pane: 1
                });
                this.volumeMa5Series = this.chart.addLineSeries({
                    priceLineVisible: false,
                    lastValueVisible: false,
                    lineWidth: 1,
                    color: this.COLORS.VOLUME_MA5_COLOR,
                    pane: 1
                });
                this.volumeMa10Series = this.chart.addLineSeries({
                    priceLineVisible: false,
                    lastValueVisible: false,
                    lineWidth: 1,
                    color: this.COLORS.VOLUME_MA10_COLOR,
                    pane: 1
                });

                this.indicator.show = true;

                this.composeHistoryCandles(rspBody.candles);

                if (!this.crosshair) {
                    this.indicator.candleValue = this.candleValueIndicatorFormat(this.lastCandle());
                    this.indicator.candleMa = this.candleMaIndicatorFormat(this.lastCandleMa5() ? this.lastCandleMa5().value : 0, this.lastCandleMa10() ? this.lastCandleMa10().value : 0, this.lastCandleMa20() ? this.lastCandleMa20().value : 0);
                    this.indicator.volume = this.volumeIndicatorFormat(this.lastVolume(), this.lastVolumeMa5() ? this.lastVolumeMa5().value : 0, this.lastVolumeMa10() ? this.lastVolumeMa10().value : 0);
                }

                let _this = this;
                this.websocket.subscribe({
                    'channel': newSubscribeScale,
                    'instId': newInstId
                }, 'candle-chart', function (result) {
                    _this.composeRealtimeCandle(result.data[0]);

                    if (!_this.crosshair) {
                        _this.indicator.candleValue = _this.candleValueIndicatorFormat(_this.lastCandle());
                        _this.indicator.candleMa = _this.candleMaIndicatorFormat(_this.lastCandleMa5() ? _this.lastCandleMa5().value : 0, _this.lastCandleMa10() ? _this.lastCandleMa10().value : 0, _this.lastCandleMa20() ? _this.lastCandleMa20().value : 0);
                        _this.indicator.volume = _this.volumeIndicatorFormat(_this.lastVolume(), _this.lastVolumeMa5() ? _this.lastVolumeMa5().value : 0, _this.lastVolumeMa10() ? _this.lastVolumeMa10().value : 0);
                    }
                });
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        appendCandles () {
            if (this.appending || this.completed) {
                return;
            }
            this.appending = true;
            this.api.candles(this, {
                instrumentId: this.inst.instId,
                seconds: this.scale,
                endTime: this.candles.length ? this.candles[0].time : 0,
                limit: 300
            }, function (rspBody) {
                if (!rspBody.candles.length) {
                    this.appending = false;
                    this.completed = true;
                    return;
                }
                this.composeHistoryCandles(rspBody.candles);
                this.appending = false;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        subscribeBooks (newInstId, oldInstId) {
            if (oldInstId) {
                this.websocket.unsubscribe({
                    'channel': this.depth.subscribeChannel,
                    'instId': oldInstId
                }, 'depth-chart');
            }

            if (this.depth.bidSeries) {
                this.depth.chart.removeSeries(this.depth.bidSeries);
                this.depth.bidSeries = null;
            }
            if (this.depth.askSeries) {
                this.depth.chart.removeSeries(this.depth.askSeries);
                this.depth.askSeries = null;
            }
            this.depth.bids = [];
            this.depth.asks = [];
            this.depth.bs = [];
            this.depth.as = [];

            let _this = this;
            this.websocket.subscribe({
                'channel': _this.depth.subscribeChannel,
                'instId': newInstId
            }, 'depth-chart', function (result) {
                if (!_this.depth.bidSeries) {
                    _this.depth.bidSeries = _this.depth.chart.addAreaSeries({
                        priceLineVisible: false,
                        topColor: _this.upHoverColor,
                        bottomColor: _this.upHoverColor,
                        lineColor: _this.upColor,
                        lineWidth: 2
                    });
                }
                if (!_this.depth.askSeries) {
                    _this.depth.askSeries = _this.depth.chart.addAreaSeries({
                        priceLineVisible: false,
                        topColor: _this.downHoverColor,
                        bottomColor: _this.downHoverColor,
                        lineColor: _this.downColor,
                        lineWidth: 2
                    });
                }

                let askList = result.data[0].asks;//低-高
                let bidList = result.data[0].bids;//高-低
                if (result.action === 'snapshot') {
                    let totalAskAmount = 0;
                    let asks = [];
                    let as = [];
                    for (let i = 0; i < askList.length && i < _this.depth.BOOK_SIZE; i++) {
                        let item = askList[i];
                        let amount = Number(item[1]);
                        totalAskAmount += amount;
                        let ask = {
                            price: item[0],
                            amount: amount,
                            total: totalAskAmount
                        };
                        asks.push(ask);
                        as[ask.price] = ask;
                    }

                    let totalBidAmount = 0;
                    let bids = [];
                    let bs = [];
                    for (let i = 0; i < bidList.length && i < _this.depth.BOOK_SIZE; i++) {
                        let item = bidList[i];
                        let amount = Number(item[1]);
                        totalBidAmount += amount;
                        let bid = {
                            price: item[0],
                            amount: amount,
                            total: totalBidAmount
                        };
                        bids.push(bid);
                        bs[bid.price] = bid;
                    }

                    _this.depth.asks = asks;//低-高
                    _this.depth.bids = bids.reverse();//低-高
                    _this.depth.as = as;
                    _this.depth.bs = bs;

                    let askDatas = [];
                    let bidDatas = [];
                    for (let i = 0; i < _this.depth.asks.length; i++) {
                        let ask = _this.depth.asks[i];
                        askDatas.push({
                            time: Number(ask.price),
                            value: ask.total
                        });
                    }
                    for (let i = 0; i < _this.depth.bids.length; i++) {
                        let bid = _this.depth.bids[i];
                        bidDatas.push({
                            time: Number(bid.price),
                            value: bid.total
                        });
                    }
                    _this.depth.bidSeries.setData(bidDatas);
                    _this.depth.askSeries.setData(askDatas);

                    _this.depth.chart.timeScale().fitContent();
                }
                else if (result.action === 'update') {
                    //更新asks
                    let newAsk = false;
                    for (let i = 0; i < askList.length; i++) {
                        let item = askList[i];
                        let price = item[0];
                        let amount = Number(item[1]);
                        let a = _this.depth.as[price];
                        if (a) {
                            if (amount) {
                                a.amount = amount;
                            }
                            else {
                                _this.depth.asks.splice(_this.depth.asks.indexOf(a), 1);
                                delete _this.depth.as[price];
                            }
                        }
                        else if (amount) {
                            let ask = {
                                price: price,
                                amount: amount,
                                total: 0
                            };
                            _this.depth.asks.push(ask);
                            _this.depth.as[ask.price] = ask;
                            newAsk = true;
                        }
                    }
                    //排序+截取
                    if (newAsk) {
                        _this.depth.asks.sort((ask1, ask2) => {
                            if (ask1.price < ask2.price) {
                                return -1;
                            }
                            else if (ask1.price > ask2.price) {
                                return 1;
                            }
                            return 0;
                        });
                        if (_this.depth.asks.length > _this.depth.BOOK_SIZE) {
                            let removes = _this.depth.asks.splice(_this.depth.BOOK_SIZE);
                            for (let i = 0; i < removes.length; i++) {
                                delete _this.depth.as[removes[i].price];
                            }
                        }
                    }
                    //计算合计
                    let totalAskAmount = 0;
                    for (let i = 0; i < _this.depth.asks.length; i++) {
                        let ask = _this.depth.asks[i];
                        totalAskAmount += ask.amount;
                        ask.total = totalAskAmount;
                    }

                    //更新bids
                    let newBid = false;
                    for (let i = 0; i < bidList.length; i++) {
                        let item = bidList[i];
                        let price = item[0];
                        let amount = Number(item[1]);
                        let b = _this.depth.bs[price];
                        if (b) {
                            if (amount) {
                                b.amount = amount;
                            }
                            else {
                                _this.depth.bids.splice(_this.depth.bids.indexOf(b), 1);
                                delete _this.depth.bs[price];
                            }
                        }
                        else if (amount) {
                            let bid = {
                                price: price,
                                amount: amount,
                                total: 0
                            };
                            _this.depth.bids.push(bid);
                            _this.depth.bs[bid.price] = bid;
                            newBid = true;
                        }
                    }
                    //排序+截取
                    if (newBid) {
                        _this.depth.bids.sort((bid1, bid2) => {
                            if (bid1.price < bid2.price) {
                                return -1;
                            }
                            else if (bid1.price > bid2.price) {
                                return 1;
                            }
                            return 0;
                        });
                        if (_this.depth.bids.length > _this.depth.BOOK_SIZE) {
                            let removes = _this.depth.bids.splice(0, _this.depth.bids.length - _this.depth.BOOK_SIZE);
                            for (let i = 0; i < removes.length; i++) {
                                delete _this.depth.bs[removes[i].price];
                            }
                        }
                    }
                    //计算合计
                    let totalBidAmount = 0;
                    for (let i = _this.depth.bids.length - 1; i >= 0; i--) {
                        let bid = _this.depth.bids[i];
                        totalBidAmount += bid.amount;
                        bid.total = totalBidAmount;
                    }

                    let askDatas = [];
                    let bidDatas = [];
                    for (let i = 0; i < _this.depth.asks.length; i++) {
                        let ask = _this.depth.asks[i];
                        askDatas.push({
                            time: Number(ask.price),
                            value: ask.total
                        });
                    }
                    for (let i = 0; i < _this.depth.bids.length; i++) {
                        let bid = _this.depth.bids[i];
                        bidDatas.push({
                            time: Number(bid.price),
                            value: bid.total
                        });
                    }
                    _this.depth.bidSeries.setData(bidDatas);
                    _this.depth.askSeries.setData(askDatas);

                    _this.depth.chart.timeScale().fitContent();
                }
            });
        },
        composeHistoryCandles (candles) {
            let candles5 = {array: [], sum: 0, step: 5};
            let candles10 = {array: [], sum: 0, step: 10};
            let candles20 = {array: [], sum: 0, step: 20};
            for (let i = 0; i < this.candles.length; i++) {
                let item = this.candles[i];
                if (i < 5) {
                    candles5.array.push(item);
                    candles5.sum += item.close;
                }
                if (i < 10) {
                    candles10.array.push(item);
                    candles10.sum += item.close;
                }
                if (i < 20) {
                    candles20.array.push(item);
                    candles20.sum += item.close;
                }
                if (i >= 20) {
                    break;
                }
            }
            let volumes5 = {array: [], sum: 0, step: 5};
            let volumes10 = {array: [], sum: 0, step: 10};
            for (let i = 0; i < this.volumes.length; i++) {
                let item = this.volumes[i];
                if (i < 5) {
                    volumes5.array.push(item);
                    volumes5.sum += item.value;
                }
                if (i < 10) {
                    volumes10.array.push(item);
                    volumes10.sum += item.value;
                }
                if (i >= 10) {
                    break;
                }
            }

            candles.forEach((item) => {
                let time = Number(item[0]);
                let open = Number(item[1]);
                let high = Number(item[2]);
                let low = Number(item[3]);
                let close = Number(item[4]);
                let vol = Number(item[5]);
                let volCcy = Number(item[6]);
                let volCcyQuote = Number(item[7]);
                let candle = {
                    time: time,
                    open: open,
                    high: high,
                    low: low,
                    close: close
                };
                let volume = {
                    time: time,
                    value: (this.inst.instType === this.abs.common.EInstrumentType.SPOT || this.inst.instType === this.abs.common.EInstrumentType.MARGIN ? vol : volCcy),
                    color: close >= open ? this.upColor : this.downColor,
                    volBaseCcy: (this.inst.instType === this.abs.common.EInstrumentType.SPOT || this.inst.instType === this.abs.common.EInstrumentType.MARGIN ? vol : volCcy),
                    volQuoteCcy: (this.inst.instType === this.abs.common.EInstrumentType.SPOT || this.inst.instType === this.abs.common.EInstrumentType.MARGIN ? volCcy : volCcyQuote)
                };
                this.candles.unshift(candle);
                this.volumes.unshift(volume);
                this.volumeMap[String(volume.time)] = volume;

                let candleMa5 = this.handleHistoryCandleMa(candles5, candle);
                let candleMa10 = this.handleHistoryCandleMa(candles10, candle);
                let candleMa20 = this.handleHistoryCandleMa(candles20, candle);
                if (candleMa5) {
                    this.candlesMa5.unshift(candleMa5);
                }
                if (candleMa10) {
                    this.candlesMa10.unshift(candleMa10);
                }
                if (candleMa20) {
                    this.candlesMa20.unshift(candleMa20);
                }
                let volumeMa5 = this.handleHistoryVolumeMa(volumes5, volume);
                let volumeMa10 = this.handleHistoryVolumeMa(volumes10, volume);
                if (volumeMa5) {
                    this.volumesMa5.unshift(volumeMa5);
                }
                if (volumeMa10) {
                    this.volumesMa10.unshift(volumeMa10);
                }
            }, this);

            this.candleSeries.setData(this.candles);
            this.candleMa5Series.setData(this.candlesMa5);
            this.candleMa10Series.setData(this.candlesMa10);
            this.candleMa20Series.setData(this.candlesMa20);
            this.volumeSeries.setData(this.volumes);
            this.volumeMa5Series.setData(this.volumesMa5);
            this.volumeMa10Series.setData(this.volumesMa10);
        },
        handleHistoryCandleMa (maCandles, candle) {
            let ma = null;
            maCandles.array.unshift(candle);
            maCandles.sum += candle.close;
            if (maCandles.array.length >= maCandles.step) {
                if (maCandles.array.length > maCandles.step) {
                    maCandles.sum -= (maCandles.array.pop()).close;
                }
                let maCandle = maCandles.array[maCandles.array.length - 1];
                ma = {
                    time: maCandle.time,
                    value: maCandles.sum / maCandles.step
                };
            }
            return ma;
        },
        handleHistoryVolumeMa (maVolumes, volume) {
            let ma = null;
            maVolumes.array.unshift(volume);
            maVolumes.sum += volume.value;
            if (maVolumes.array.length >= maVolumes.step) {
                if (maVolumes.array.length > maVolumes.step) {
                    maVolumes.sum -= (maVolumes.array.pop()).value;
                }
                let maVolume = maVolumes.array[maVolumes.array.length - 1];
                ma = {
                    time: maVolume.time,
                    value: maVolumes.sum / maVolumes.step
                };
            }
            return ma;
        },
        composeRealtimeCandle (candleItem) {
            let time = Number(candleItem[0]);
            let open = Number(candleItem[1]);
            let high = Number(candleItem[2]);
            let low = Number(candleItem[3]);
            let close = Number(candleItem[4]);
            let vol = Number(candleItem[5]);
            let volCcy = Number(candleItem[6]);
            let volCcyQuote = Number(candleItem[7]);
            let candle = {
                time: time,
                open: open,
                high: high,
                low: low,
                close: close
            };
            let volume = {
                time: time,
                value: (this.inst.instType === this.abs.common.EInstrumentType.SPOT || this.inst.instType === this.abs.common.EInstrumentType.MARGIN ? vol : volCcy),
                color: close >= open ? this.upColor : this.downColor,
                volBaseCcy: (this.inst.instType === this.abs.common.EInstrumentType.SPOT || this.inst.instType === this.abs.common.EInstrumentType.MARGIN ? vol : volCcy),
                volQuoteCcy: (this.inst.instType === this.abs.common.EInstrumentType.SPOT || this.inst.instType === this.abs.common.EInstrumentType.MARGIN ? volCcy : volCcyQuote)
            };
            let candleMa5 = null;
            let candleMa10 = null;
            let candleMa20 = null;
            let volumeMa5 = null;
            let volumeMa10 = null;

            let lastCandle = this.lastCandle();
            if (!lastCandle || lastCandle.time !== time) {
                this.candles.push(candle);
                candleMa5 = this.handleRealtimeCandleMa(candle, 5);
                candleMa10 = this.handleRealtimeCandleMa(candle, 10);
                candleMa20 = this.handleRealtimeCandleMa(candle, 20);
                if (candleMa5) {
                    this.candlesMa5.push(candleMa5);
                }
                if (candleMa10) {
                    this.candlesMa10.push(candleMa10);
                }
                if (candleMa20) {
                    this.candlesMa20.push(candleMa20);
                }
            }
            else {
                lastCandle.open = candle.open;
                lastCandle.high = candle.high;
                lastCandle.low = candle.low;
                lastCandle.close = candle.close;
                candleMa5 = this.handleRealtimeCandleMa(candle, 5);
                candleMa10 = this.handleRealtimeCandleMa(candle, 10);
                candleMa20 = this.handleRealtimeCandleMa(candle, 20);
                if (candleMa5) {
                    let lastCandleMa5 = this.lastCandleMa5();
                    if (!lastCandleMa5 || lastCandleMa5.time !== candleMa5.time) {
                        this.candlesMa5.push(candleMa5);
                    }
                    else {
                        lastCandleMa5.value = candleMa5.value;
                    }
                }
                if (candleMa10) {
                    let lastCandleMa10 = this.lastCandleMa10();
                    if (!lastCandleMa10 || lastCandleMa10.time !== candleMa10.time) {
                        this.candlesMa10.push(candleMa10);
                    }
                    else {
                        lastCandleMa10.value = candleMa10.value;
                    }
                }
                if (candleMa20) {
                    let lastCandleMa20 = this.lastCandleMa20();
                    if (!lastCandleMa20 || lastCandleMa20.time !== candleMa20.time) {
                        this.candlesMa20.push(candleMa20);
                    }
                    else {
                        lastCandleMa20.value = candleMa20.value;
                    }
                }
            }

            let lastVolume = this.lastVolume();
            if (!lastVolume || lastVolume.time !== time) {
                this.volumes.push(volume);
                this.volumeMap[String(volume.time)] = volume;
                volumeMa5 = this.handleRealtimeVolumeMa(volume, 5);
                volumeMa10 = this.handleRealtimeVolumeMa(volume, 10);
                if (volumeMa5) {
                    this.volumesMa5.push(volumeMa5);
                }
                if (volumeMa10) {
                    this.volumesMa10.push(volumeMa10);
                }
            }
            else {
                lastVolume.value = volume.value;
                volumeMa5 = this.handleRealtimeVolumeMa(volume, 5);
                volumeMa10 = this.handleRealtimeVolumeMa(volume, 10);
                if (volumeMa5) {
                    let lastVolumeMa5 = this.lastVolumeMa5();
                    if (!lastVolumeMa5 || lastVolumeMa5.time !== volumeMa5.time) {
                        this.volumesMa5.push(volumeMa5);
                    }
                    else {
                        lastVolumeMa5.value = volumeMa5.value;
                    }
                }
                if (volumeMa10) {
                    let lastVolumeMa10 = this.lastVolumeMa10();
                    if (!lastVolumeMa10 || lastVolumeMa10.time !== volumeMa10.time) {
                        this.volumesMa10.push(volumeMa10);
                    }
                    else {
                        lastVolumeMa10.value = volumeMa10.value;
                    }
                }
            }

            this.candleSeries.update(candle);
            this.volumeSeries.update(volume);
            if (candleMa5) {
                this.candleMa5Series.update(candleMa5);
            }
            if (candleMa10) {
                this.candleMa10Series.update(candleMa10);
            }
            if (candleMa20) {
                this.candleMa20Series.update(candleMa20);
            }
            if (volumeMa5) {
                this.volumeMa5Series.update(volumeMa5);
            }
            if (volumeMa10) {
                this.volumeMa10Series.update(volumeMa10);
            }
        },
        handleRealtimeCandleMa (candle, step) {
            let ma = null;
            if (this.candles.length >= step) {
                let sum = 0;
                for (let i = this.candles.length - 1; i >= this.candles.length - step; i--) {
                    sum += this.candles[i].close;
                }
                ma = {
                    time: candle.time,
                    value: sum / step
                };
            }
            return ma;
        },
        handleRealtimeVolumeMa (volume, step) {
            let ma = null;
            if (this.volumes.length >= step) {
                let sum = 0;
                for (let i = this.volumes.length - 1; i >= this.volumes.length - step; i--) {
                    sum += this.volumes[i].value;
                }
                ma = {
                    time: volume.time,
                    value: sum / step
                };
            }
            return ma;
        },
        lastCandle () {
            return this.candles && this.candles.length ? this.candles[this.candles.length - 1] : null;
        },
        lastCandleMa5 () {
            return this.candlesMa5 && this.candlesMa5.length ? this.candlesMa5[this.candlesMa5.length - 1] : null;
        },
        lastCandleMa10 () {
            return this.candlesMa10 && this.candlesMa10.length ? this.candlesMa10[this.candlesMa10.length - 1] : null;
        },
        lastCandleMa20 () {
            return this.candlesMa20 && this.candlesMa20.length ? this.candlesMa20[this.candlesMa20.length - 1] : null;
        },
        lastVolume () {
            return this.volumes && this.volumes.length ? this.volumes[this.volumes.length - 1] : null;
        },
        lastVolumeMa5 () {
            return this.volumesMa5 && this.volumesMa5.length ? this.volumesMa5[this.volumesMa5.length - 1] : null;
        },
        lastVolumeMa10 () {
            return this.volumesMa10 && this.volumesMa10.length ? this.volumesMa10[this.volumesMa10.length - 1] : null;
        },
        candleValueIndicatorFormat (candle) {
            if (!candle) {
                return {
                    time: ' - ',
                    open: ' - ',
                    high: ' - ',
                    low: ' - ',
                    close: ' - ',
                    change: ' - ',
                    range: ' - ',
                    color: ''
                };
            }
            let change = (candle.close - candle.open) * 100 / candle.open;
            let range = (candle.high - candle.low) * 100 / candle.open;
            return {
                time: this.$d(new Date(candle.time), 'long'),
                open: this.abs.Util.formatNumber(candle.open, this.inst.tickSize),
                high: this.abs.Util.formatNumber(candle.high, this.inst.tickSize),
                low: this.abs.Util.formatNumber(candle.low, this.inst.tickSize),
                close: this.abs.Util.formatNumber(candle.close, this.inst.tickSize),
                change: (change >= 0 ? '+' : '') + this.abs.Util.formatNumber(change, 2) + '%',
                range: this.abs.Util.formatNumber(range, 2) + '%',
                color: change >= 0 ? this.upColor : this.downColor
            };
        },
        candleMaIndicatorFormat (ma5Value, ma10Value, ma20Value) {
            return {
                ma5: ma5Value ? this.abs.Util.formatNumber(ma5Value, 2) : ' - ',
                ma10: ma10Value ? this.abs.Util.formatNumber(ma10Value, 2) : ' - ',
                ma20: ma20Value ? this.abs.Util.formatNumber(ma20Value, 2) : ' - '
            };
        },
        volumeIndicatorFormat (volume, ma5Value, ma10Value) {
            return {
                volBaseCcy: volume ? this.abs.Util.formatNumber(volume.volBaseCcy, 2) : ' - ',
                volQuoteCcy: volume ? this.abs.Util.formatNumber(volume.volQuoteCcy, 2) : ' - ',
                ma5: ma5Value ? this.abs.Util.formatNumber(ma5Value, 2) : ' - ',
                ma10: ma10Value ? this.abs.Util.formatNumber(ma10Value, 2): ' - '
            };
        }
    }
}
</script>

<style scoped>
.trade-chart {
    width: 100%;
    height: 100%;
}
.option-bar {
    height: 40px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    justify-content: space-between;
}
.option-bar .left-block {
    display: flex;
    align-items: center;
}
.option-bar .left-block.hidden {
    visibility: hidden;
}
.option-bar .right-block {
    display: flex;
    align-items: center;
}

.scales {
    margin: 0 8px;
    display: flex;
    align-items: center;
}
.scales .scale {
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
}
.scales .scale:hover {
    color: var(--text-strong);
}
.scales .scale.active {
    color: var(--text-strong);
    background-color: var(--background-active);
}
.views {
    height: 24px;
    margin: 0 8px;
    padding: 2px;
    background-color: var(--background-secondary);
    border-radius: 3px;
    display: flex;
    align-items: center;
}
.views .view {
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
}
.views .view.active {
    color: var(--text-strong);
    background-color: var(--background-active);
}

.indicator {
    position: absolute;
    height: 24px;
    opacity: 0.8;
    display: none;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 5;
}
.indicator.show {
    display: flex;
}
.indicator .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    cursor: pointer;
}
.indicator .name {
    margin-right: 8px;
    font-size: 12px;
    color: var(--text-strong);
}
.indicator .item {
    margin-right: 8px;
}
.indicator.fold .item {
    display: none;
}
.indicator .item .label {
    margin-right: 4px;
    font-size: 12px;
    color: var(--text-strong);
}
.indicator .item .value {
    font-size: 12px;
    color: var(--text-strong);
}
.indicator.ticker {
    top: 44px;
    left: 4px;
}
.indicator.ma {
    top: 68px;
    left: 4px;
}
.indicator.volume {
    left: 4px;
}
.indicator.ma .name {
    display: none;
}
.indicator.ma.fold .name {
    display: inline;
}

.candle-view, .depth-view {
    position: absolute;
    top: 40px;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
.candle-view.active, .depth-view.active {
    z-index: 2;
}
.demo{}
</style>