<template>
<div class="dialog-panel">
    <div class="dialog-content" :style="'width:'+width">
        <div class="dialog-head">
            <div class="title" v-text="title"></div>
            <div class="close" v-if="!hideClose" @click="clickClose"><i class="icon times"></i></div>
        </div>
        <div class="dialog-body" :style="'min-height:'+height">
            <slot></slot>
        </div>
        <div class="dialog-foot">
            <a class="button primary-button" :class="{'disabled':disableConfirm}" v-text="confirmButtonText" @click="clickConfirm"></a>
            <a class="button link-button" v-if="!hideCancel" v-text="cancelButtonText" @click="clickCancel"></a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "DialogPanel",
    props: {
        title: {
            type: String,
            default: () => {
                return '';
            }
        },
        width: {
            type: String,
            default: () => {
                return '600px';
            }
        },
        height: {
            type: String,
            default: () => {
                return '300px';
            }
        },
        confirmText: {
            type: String,
            default: () => {
                return '';
            }
        },
        cancelText: {
            type: String,
            default: () => {
                return '';
            }
        },
        disableConfirm: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        hideCancel: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        hideClose: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CONFIRM: 'confirm',
                CANCEL: 'cancel',
                CLOSE: 'close'
            },
            innerConfirmText: '',
            innerCancelText: ''
        };
    },
    computed: {
        confirmButtonText () {
            return this.innerConfirmText || this.$t('common.confirm');
        },
        cancelButtonText () {
            return this.innerCancelText || this.$t('common.cancel');
        }
    },
    watch: {
        confirmText (confirmText) {
            this.innerConfirmText = confirmText;
        },
        cancelText (cancelText) {
            this.innerCancelText = cancelText;
        }
    },
    mounted () {
        this.innerConfirmText = this.confirmText;
        this.innerCancelText = this.cancelText;
    },
    methods: {
        clickConfirm () {
            this.$emit(this.EVENTS.CONFIRM);
        },
        clickCancel () {
            this.$emit(this.EVENTS.CANCEL);
        },
        clickClose () {
            this.$emit(this.EVENTS.CLOSE);
        }
    }
}
</script>

<style scoped>
.dialog-panel {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-dialog);
    z-index: 50;
}
.dialog-panel .dialog-content {
    min-width: 400px;
    background-color: var(--background-primary);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}
.dialog-panel .dialog-content .dialog-head {
    height: 48px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.dialog-panel .dialog-content .dialog-head .title {
    padding: 0 48px 0 16px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    color: var(--text-strong);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dialog-panel .dialog-content .dialog-head .close {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 8px;
    right: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dialog-panel .dialog-content .dialog-head .close .icon {
    width: 20px;
    height: 20px;
}
.dialog-panel .dialog-content .dialog-head .close:hover .icon {
    color: var(--text-default);
}
.dialog-panel .dialog-content .dialog-body {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dialog-panel .dialog-content .dialog-foot {
    width: 100%;
    height: 72px;
    padding: 0 16px;
    background-color: var(--background-secondary);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.dialog-panel .dialog-content .dialog-foot .button {
    min-width: 96px;
    height: 40px;
    border-radius: 20px;
}
</style>