<template>
<div class="count-field">
    <div class="label-bar">
        <div class="left">
            <span class="text" v-text="label"></span>
        </div>
        <div class="right" v-if="volumeCheck">
            <a class="button link-button" @click="clickVolumeCheck" v-text="$t('component.count_field.total')"></a>
        </div>
    </div>
    <div class="field-bar">
        <div class="field">
            <label class="input-label"><input class="text" type="text" :placeholder="placeholder" v-model="valueText" @keydown="keydownValue" @keyup="keyupValue" /></label>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "CountField",
    props: {
        label: {
            type: String,
            default: () => {
                return '';
            }
        },
        volumeCheck: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        value: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        text: {
            type: String,
            default: () => {
                return '';
            }
        },
        lotSize: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        minSize: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        placeholder: {
            type: String,
            default: () => {
                return '';
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE_VALUE: 'changeValue',
                CHANGE_TEXT: 'changeText',
                CLICK_VOLUME_CHECK: 'clickVolumeCheck'
            },
            valueNumber: 0,
            valueText: ''
        }
    },
    watch: {
        value (value) {
            this.setValue(value);
        },
        text (text) {
            this.setText(text);
        }
    },
    mounted () {
        if (this.value) {
            this.setValue(this.value);
        }
        else if (this.text) {
            this.setText(this.text);
        }
    },
    methods: {
        setValue (value) {
            if (value !== this.valueNumber) {
                this.valueNumber = this.abs.Util.floor(Math.max(Number(value) || 0, 0), this.lotSize);
                if (this.abs.Util.floor(this.valueNumber, this.minSize) <= 0) {
                    this.valueNumber = 0;
                }
                this.valueText = this.valueNumber ? this.abs.Util.formatNumber(this.valueNumber) : '';
                this.$emit(this.EVENTS.CHANGE_TEXT, this.valueText);
            }
        },
        setText (text) {
            if (text !== this.valueText) {
                text = text.replace(/,/g, '');
                this.valueNumber = this.abs.Util.floor(Math.max(Number(text) || 0, 0), this.tickSize);
                this.valueText = text;
            }
        },
        keydownValue (e) {
            if (!this.abs.Util.inputNumberBefore(e, this.valueNumber, this.valueText, this.lotSize, this.minSize)) {
                e.preventDefault();
            }
        },
        keyupValue () {
            let result = this.abs.Util.inputNumberAfter(this.valueText);
            this.valueNumber = result.valueNumber;
            this.valueText = result.valueText;
            this.$emit(this.EVENTS.CHANGE_VALUE, this.valueNumber, this.valueText);
        },
        clickVolumeCheck () {
            this.$emit(this.EVENTS.CLICK_VOLUME_CHECK);
        }
    }
}
</script>

<style scoped>
.label-bar {
    margin-bottom: 4px;
    height: 24px;
    display: flex;
}
.label-bar .left {
    flex: 1;
    display: flex;
    align-items: center;
}
.label-bar .right {
    display: flex;
    align-items: center;
}
.label-bar .right .button {
    height: 24px;
    font-size: 12px;
}

.field-bar {
    height: 40px;
    display: flex;
}
.field-bar .field {
    flex: 1;
    display: flex;
}
.field-bar .field .input-label {
    flex: 1;
}
</style>