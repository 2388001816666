<template>
<div class="msg-box alert">
    <div class="content">
        <div class="msg">
            <div class="head" v-text="$t('common.msgbox.alert')"></div>
            <div class="body"></div>
        </div>
        <div class="buttons">
            <button class="button primary-button" @click="abs.MsgBox.ok()" v-text="$t('common.ok')"></button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "MsgAlert"
}
</script>

<style scoped>

</style>