<template>
<div class="container" @scroll="scrollMarket">
    <top-bar></top-bar>
    <div class="market-content">
        <div class="block-tabs">
            <div class="block-tab" :class="{'active':blockTab===BLOCK_TABS.FAVOR}" @click="clickBlockTab(BLOCK_TABS.FAVOR)" v-text="$t('market.favorites')"></div>
            <div class="block-tab" :class="{'active':blockTab===BLOCK_TABS.ALL}" @click="clickBlockTab(BLOCK_TABS.ALL)" v-text="$t('market.all')"></div>
            <div class="block-tab" :class="{'active':blockTab===block.code}" v-for="block in blockList" :key="block.code" v-text="block.name" @click="clickBlockTab(block.code)"></div>
            <label class="filter"><i class="icon search"></i><input class="text" type="text" v-model="filter" @keyup="inputSearch" /></label>
        </div>
        <div class="list-head">
            <div class="list-row">
                <div class="list-col inst" v-text="$t('market.pair')"></div>
                <div class="list-col last" v-text="$t('market.price')"></div>
                <div class="list-col change" v-text="$t('market.change')"></div>
                <div class="list-col low" v-text="$t('market.h24low')"></div>
                <div class="list-col high" v-text="$t('market.h24high')"></div>
                <div class="list-col volume" v-text="$t('market.h24volume')"></div>
                <div class="list-col turnover" v-text="$t('market.h24turnover')"></div>
                <div class="list-col action" v-text="$t('market.action')"></div>
            </div>
        </div>
        <div class="list-body">
            <div class="list-row" v-for="inst in filterList" :key="inst.instId" @click="clickInstrument(inst)">
                <div class="list-col inst" :class="{'favor':inst.favor}"><i class="icon star" @click="clickFavor(inst)"></i><span class="text" v-text="inst.baseCcy + '/' + inst.quoteCcy"></span></div>
                <div class="list-col last" v-text="inst.last || '-'"></div>
                <div class="list-col change" :class="{'index-up':inst.updown>0,'index-down':inst.updown<0}" v-text="inst.change ? ((inst.updown>=0 ? '+' : '') + inst.change + '%') : '-'"></div>
                <div class="list-col low" v-text="inst.h24low || '-'"></div>
                <div class="list-col high" v-text="inst.h24high || '-'"></div>
                <div class="list-col volume" v-text="inst.h24volume || '-'"></div>
                <div class="list-col turnover" v-text="inst.h24turnover || '-'"></div>
                <div class="list-col action" v-text="$t('market.trade')"></div>
            </div>
        </div>
    </div>
    <bottom-bar></bottom-bar>
    <msg-toast></msg-toast>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
export default {
    name: "MarketBase",
    data () {
        return {
            BLOCK_TABS: {
                FAVOR: 'favor',
                ALL: 'all'
            },
            instrumentType: this.abs.common.EInstrumentType.SPOT,
            filter: '',
            blockTab: 'all',
            blockList: [],
            blockInstrumentLists: [],
            favorList: [],
            instrumentList: [],
            instruments: [],
            currentList: [],
            tickers: []
        };
    },
    computed: {
        filterList () {
            if (!this.filter) {
                return this.currentList;
            }
            let filter = this.filter.toLocaleUpperCase();
            let filterList = [];
            for (let i = 0; i < this.currentList.length; i++) {
                let item = this.currentList[i];
                if (item.baseCcy.indexOf(filter) >= 0 || item.quoteCcy.indexOf(filter) >= 0) {
                    filterList.push(item);
                }
            }
            return filterList;
        }
    },
    mounted () {
        this.api.instruments(this, {
            instrumentType: this.instrumentType
        }, function (rspBody) {
            this.blockList = rspBody.blockList;
            rspBody.blockList.forEach((block) => {
                this.blockInstrumentLists[block.code] = [];
            }, this);
            rspBody.instruments.forEach((instrument) => {
                instrument.last = '';
                instrument.change = '';
                instrument.h24low = '';
                instrument.h24high = '';
                instrument.h24volume = '';
                instrument.h24turnover = '';
                instrument.updown = 0;
                let tickSize = instrument.tickSz.indexOf('.');
                instrument.tickSize = tickSize > 0 ? instrument.tickSz.length - tickSize - 1 : 0;
                let lotSize = instrument.lotSz.indexOf('.');
                instrument.lotSize = lotSize > 0 ? instrument.lotSz.length - lotSize - 1 : 0;
                this.instruments[instrument.instId] = instrument;
                if (instrument.blocks) {
                    instrument.blocks.forEach((block) => {
                        let instrumentList = this.blockInstrumentLists[block];
                        if (instrumentList) {
                            instrumentList.push(instrument);
                        }
                    }, this);
                }
            }, this);
            this.instrumentList = rspBody.instruments;
            this.clickBlockTab(this.blockTab);
            this.scrollMarket({
                target: document.querySelector('.container')
            });
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });

        let _this = this;
        this.websocket.observe('tickers', 'market-base', function (content) {
            let data = content.data[0];
            let inst = _this.instruments[data.instId];
            if (!inst) {
                return;
            }
            inst.updown = (Number(data.last) - Number(data.open24h)) / Number(data.open24h);
            inst.last = _this.abs.Util.formatNumber(Number(data.last), inst.tickSize);
            inst.change = (inst.updown * 100).toFixed(2);
            inst.h24low = _this.abs.Util.formatNumber(Number(data.low24h), inst.tickSize);
            inst.h24high = _this.abs.Util.formatNumber(Number(data.high24h), inst.tickSize);
            inst.h24volume = _this.abs.Util.formatNumber(Number(data.vol24h), 0);
            inst.h24turnover = _this.abs.Util.formatNumber(Number(data.volCcy24h), 0);
        });
    },
    unmounted () {
        for (let instId in this.tickers) {
            this.websocket.unsubscribe({
                channel: 'tickers',
                instId: instId
            }, 'market-base');
        }
        for (let instId in this.tickers) {
            delete this.tickers[instId];
        }
    },
    methods: {
        clickBlockTab (blockTab) {
            this.blockTab = blockTab;
            if (blockTab === this.BLOCK_TABS.FAVOR) {
                this.currentList = this.favorList;
            }
            else if (blockTab === this.BLOCK_TABS.ALL) {
                this.currentList = this.instrumentList;
            }
            else {
                this.currentList = this.blockInstrumentLists[blockTab] || [];
            }
            this.scrollMarket({
                target: document.querySelector('.container')
            });
        },
        inputSearch () {
            this.scrollMarket({
                target: document.querySelector('.container')
            });
        },
        clickInstrument (inst) {
            this.$router.push(this.abs.routes.SPOT_BASE + '/' + inst.instId);
        },
        clickFavor (inst) {
            inst.favor = !inst.favor;
        },
        scrollMarket (e) {
            if (this.scrollTimer) {
                clearTimeout(this.scrollTimer);
            }
            let _this = this;
            this.scrollTimer = setTimeout(() => {
                let base = 60;
                let step = 56;
                let offset = Math.max(e.target.scrollTop - base, 0);
                let height = window.innerHeight - base;
                let start = Math.floor(offset / step);
                let limit = Math.ceil(height / step);
                let tickers = [];
                for (let i = start; i < start + limit && i < _this.filterList.length; i++) {
                    tickers[_this.filterList[i].instId] = _this.filterList[i];
                }
                for (let instId in tickers) {
                    if (instId in _this.tickers) {
                        delete _this.tickers[instId];
                        continue;
                    }
                    _this.websocket.subscribe({
                        channel: 'tickers',
                        instId: instId
                    });
                }
                for (let instId in _this.tickers) {
                    _this.websocket.unsubscribe({
                        channel: 'tickers',
                        instId: instId
                    });
                }
                _this.tickers = tickers;
            }, 500);
        }
    }
}
</script>

<style scoped>
.market-content {
    padding: 0 80px;
    min-height: 800px;
}
.block-tabs {
    position: sticky;
    top: 0;
    height: 56px;
    background-color: var(--background-primary);
    display: flex;
    align-items: center;
    z-index: 1;
}
.block-tabs .block-tab {
    margin: 0 8px;
    padding: 0 16px;
    height: 28px;
    line-height: 28px;
    border-radius: 3px;
    cursor: pointer;
}
.block-tabs .block-tab.active {
    color: var(--text-strong);
    background-color: var(--background-active);
}
.block-tabs .filter {
    position: absolute;
    right: 16px;
    width: 200px;
}
.block-tabs .filter .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 12px;
    height: 12px;
    z-index: 1;
}
.block-tabs .filter .text {
    height: 32px;
    padding-left: 32px;
}
.list-head {
    position: sticky;
    top: 56px;
    background-color: var(--background-primary);
    z-index: 1;
}
.list-row {
    padding: 0 8px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.list-head .list-row {
    height: 32px;
}
.list-body .list-row {
    height: 56px;
    cursor: pointer;
}
.list-body .list-row:hover {
    background-color: var(--background-hover);
}
.list-col.inst {
    width: 18%;
    display: flex;
    align-items: center;
}
.list-col.inst .icon {
    margin-right: 4px;
    margin-bottom: 2px;
}
.list-col.inst.favor .icon {
    color: var(--text-favor);
    background-color: var(--background-favor);
}
.list-col.last {
    width: 12%;
    text-align: right;
}
.list-col.change {
    width: 12%;
    text-align: right;
}
.list-col.low {
    width: 12%;
    text-align: right;
}
.list-col.high {
    width: 12%;
    text-align: right;
}
.list-col.volume {
    width: 12%;
    text-align: right;
}
.list-col.turnover {
    width: 12%;
    text-align: right;
}
.list-col.action {
    width: 10%;
    padding-right: 8px;
    text-align: right;
}
.demo{}
</style>