<template>
<div class="order-center">
    <div class="order-tabs">
        <div class="order-tab" :class="{'active':tab===TABS.PENDING}" @click="clickOrderTab(TABS.PENDING)" v-text="$t('wallet.order.open_orders') + (pending.orderList.length ? '(' + pending.orderList.length + ')' : '')"></div>
        <div class="order-tab" :class="{'active':tab===TABS.HISTORY}" @click="clickOrderTab(TABS.HISTORY)" v-text="$t('wallet.order.order_history')"></div>
        <div class="order-tab" :class="{'active':tab===TABS.FUNDING}" @click="clickOrderTab(TABS.FUNDING)" v-text="$t('wallet.order.funding_history')"></div>
    </div>
    <div class="order-panel" :class="{'active':tab===TABS.PENDING}">
        <div class="filter">
            <div class="filter-item">
                <div class="label" v-text="$t('wallet.order.order_type')"></div>
                <div class="field" style="width:140px"><drop-down :height="32" :text-align="'left'" :value="pending.orderType" :options="ORDER_TYPES" @change="changePendingOrderType"></drop-down></div>
            </div>
        </div>
        <div class="order-list">
            <div class="head">
                <div class="row">
                    <div class="col col-time" v-text="$t('wallet.order.order_time')"></div>
                    <div class="col col-inst-type" v-text="$t('wallet.order.order_instrument')"></div>
                    <div class="col col-inst-id" v-text="$t('wallet.order.order_symbol')"></div>
                    <div class="col col-side enum" v-text="$t('wallet.order.order_side')"></div>
                    <div class="col col-price number" v-text="$t('wallet.order.order_price')"></div>
                    <div class="col col-count number" v-text="$t('wallet.order.order_volume')"></div>
                    <div class="col col-amount number" v-text="$t('wallet.order.order_turnover')"></div>
                    <div class="col col-state enum" v-text="$t('wallet.order.order_status')"></div>
                    <div class="col col-action enum" v-text="$t('wallet.order.order_action')"></div>
                </div>
            </div>
            <div class="body">
                <div class="row" v-for="order in pending.orderList" :key="order.ordId">
                    <div class="col col-time" v-text="$d(order.cTime, 'long')"></div>
                    <div class="col col-inst-type" v-text="order.instType"></div>
                    <div class="col col-inst-id" v-text="order.instId"></div>
                    <div class="col col-side enum" v-text="order.side"></div>
                    <div class="col col-price number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx) : '--') + ' | ' + abs.Util.formatNumber(order.px)"></div>
                    <div class="col col-count number" v-text="abs.Util.formatNumber(order.accFillSz) + ' | ' + abs.Util.formatNumber(order.sz)"></div>
                    <div class="col col-amount number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx * order.accFillSz) : '0') + ' | ' + abs.Util.formatNumber(order.px * order.sz)"></div>
                    <div class="col col-state enum" v-text="order.state"></div>
                    <div class="col col-action enum"><a class="button link-button" v-text="$t('common.cancel')"></a></div>
                </div>
                <div class="empty" v-if="!pending.orderList.length" v-text="$t('wallet.order.no_data')"></div>
            </div>
        </div>
    </div>
    <div class="order-panel" :class="{'active':tab===TABS.HISTORY}">
        <div class="filter">
            <div class="filter-item">
                <div class="label" v-text="$t('wallet.order.order_type')"></div>
                <div class="field" style="width:140px"><drop-down :height="32" :text-align="'left'" :value="history.orderType" :options="ORDER_TYPES" @change="changeHistoryOrderType"></drop-down></div>
            </div>
            <div class="filter-item">
                <div class="label" v-text="$t('wallet.order.date')"></div>
                <div class="field"><date-picker :height="32" :value="history.startTime" @change="changeHistoryOrderStartTime"></date-picker><div class="to" style="padding:0 4px">~</div><date-picker :height="32" :value="history.endTime" @change="changeHistoryOrderEndTime"></date-picker></div>
            </div>
        </div>
        <div class="order-list">
            <div class="head">
                <div class="row">
                    <div class="col col-time" v-text="$t('wallet.order.order_time')"></div>
                    <div class="col col-inst-type" v-text="$t('wallet.order.order_instrument')"></div>
                    <div class="col col-inst-id" v-text="$t('wallet.order.order_symbol')"></div>
                    <div class="col col-side enum" v-text="$t('wallet.order.order_side')"></div>
                    <div class="col col-price number" v-text="$t('wallet.order.order_price')"></div>
                    <div class="col col-count number" v-text="$t('wallet.order.order_volume')"></div>
                    <div class="col col-amount number" v-text="$t('wallet.order.order_turnover')"></div>
                    <div class="col col-fee number" v-text="$t('wallet.order.order_fee')"></div>
                    <div class="col col-state enum" v-text="$t('wallet.order.order_status')"></div>
                </div>
            </div>
            <div class="body">
                <div class="row" v-for="order in history.orderList" :key="order.ordId">
                    <div class="col col-time" v-text="$d(order.cTime, 'long')"></div>
                    <div class="col col-inst-type" v-text="order.instType"></div>
                    <div class="col col-inst-id" v-text="order.instId"></div>
                    <div class="col col-side enum" v-text="order.side"></div>
                    <div class="col col-price number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx) : '--') + ' | ' + abs.Util.formatNumber(order.px)"></div>
                    <div class="col col-count number" v-text="abs.Util.formatNumber(order.accFillSz) + ' | ' + abs.Util.formatNumber(order.sz)"></div>
                    <div class="col col-amount number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx * order.accFillSz) : '0') + ' | ' + abs.Util.formatNumber(order.px * order.sz)"></div>
                    <div class="col col-fee number" v-text="abs.Util.formatNumber(order.fee)"></div>
                    <div class="col col-state enum" v-text="order.state"></div>
                </div>
                <div class="empty" v-if="!history.orderList.length" v-text="$t('wallet.order.no_data')"></div>
            </div>
        </div>
    </div>
    <div class="order-panel" :class="{'active':tab===TABS.FUNDING}">
        <div class="filter">
            <div class="filter-item">
                <div class="label" v-text="$t('wallet.order.date')"></div>
                <div class="field"><date-picker :height="32" :value="funding.startTime" @change="changeFundingOrderStartTime"></date-picker><div class="to" style="padding:0 4px">~</div><date-picker :height="32" :value="funding.endTime" @change="changeFundingOrderEndTime"></date-picker></div>
            </div>
            <div class="filter-item">
                <div class="label" v-text="$t('wallet.order.crypto')"></div>
                <div class="field" style="width:240px"><currency-picker :height="32" :text-align="'left'" :all="true" :value="''" @changeValue="changeFundingCurrency"></currency-picker></div>
            </div>
            <div class="filter-item">
                <div class="label" v-text="$t('wallet.order.type')"></div>
                <div class="field" style="width:140px"><drop-down :height="32" :text-align="'left'" :value="funding.billType" :options="BILL_TYPES" @change="changeFundingBillType"></drop-down></div>
            </div>
        </div>
        <div class="order-list">
            <div class="head">
                <div class="row">
                    <div class="col col-bill-time" v-text="$t('wallet.order.date')"></div>
                    <div class="col col-bill-currency" v-text="$t('wallet.order.crypto')"></div>
                    <div class="col col-bill-type enum" v-text="$t('wallet.order.type')"></div>
                    <div class="col col-bill-amount number" v-text="$t('wallet.order.amount')"></div>
                    <div class="col col-bill-balance number" v-text="$t('wallet.order.balance')"></div>
                    <div class="col col-bill-fee number" v-text="$t('wallet.order.order_fee')"></div>
                </div>
            </div>
            <div class="body">
                <div class="row" v-for="bill in funding.billList" :key="bill.billId">
                    <div class="col col-bill-time" v-text="$d(bill.time, 'long')"></div>
                    <div class="col col-bill-currency" v-text="bill.ccy"></div>
                    <div class="col col-bill-type enum" v-text="bill.type"></div>
                    <div class="col col-bill-amount number" :class="{'index-up':bill.amount>0,'index-down':bill.amount<0}" v-text="abs.Util.formatNumber(bill.amount)"></div>
                    <div class="col col-bill-balance number" v-text="abs.Util.formatNumber(bill.balance)"></div>
                    <div class="col col-bill-fee number" v-text="'0.0'"></div>
                </div>
                <div class="empty" v-if="!funding.billList.length" v-text="$t('wallet.order.no_data')"></div>
            </div>
        </div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import DropDown from "@/components/DropDown";
import DatePicker from "@/components/DatePicker";
import CurrencyPicker from "@/components/CurrencyPicker";
export default {
    name: "UserAssetOrder",
    components: {CurrencyPicker, DatePicker, DropDown},
    data () {
        return {
            TABS: {
                PENDING: 'pending',
                HISTORY: 'history',
                FUNDING: 'funding'
            },
            ORDER_TYPES: [{
                id: this.abs.common.EOrderType.LIMIT, text: this.$t('wallet.order.order_type_limit')
            }, {
                id: this.abs.common.EOrderType.MARKET, text: this.$t('wallet.order.order_type_market')
            }],
            BILL_TYPES: [{
                id: '', text: this.$t('wallet.order.type_all')
            }, {
                id: '1', text: this.$t('wallet.order.type_deposit')
            }, {
                id: '2', text: this.$t('wallet.order.type_withdraw')
            }, {
                id: '130', text: this.$t('wallet.order.type_transfer_in')
            }, {
                id: '131', text: this.$t('wallet.order.type_transfer_out')
            }],
            tab: 'pending',
            instrumentType: this.abs.common.EInstrumentType.SPOT,
            pending: {
                orderType: this.abs.common.EOrderType.LIMIT,
                orderList: []
            },
            history: {
                orderType: this.abs.common.EOrderType.LIMIT,
                startTime: null,
                endTime: null,
                orderList: []
            },
            funding: {
                currency: null,
                billType: '',
                startTime: null,
                endTime: null,
                billList: [],
                LIMIT: 50
            }
        };
    },
    created () {
        this.tab = this.$route.params.section || this.TABS.PENDING;
        this.$watch(() => this.$route.params, (params) => {
            this.tab = params.section || this.TABS.PENDING;
        });
    },
    mounted () {
        this.history.endTime = new Date();
        this.history.startTime = new Date(this.history.endTime.getTime() - 86400000 * 15);
        this.funding.endTime = new Date();
        this.funding.startTime = new Date(this.funding.endTime.getTime() - 86400000 * 15);
        this.spotOrderPendingList();
        this.spotOrderHistoryList();
        this.assetBillList(this.funding.currency, this.funding.billType, this.funding.startTime, this.funding.endTime, this.funding.LIMIT);
        this.websocket.subscribe({
            channel: 'orders',
            instType: this.instrumentType
        }, 'user-asset-order', function (content) {
            console.log(content);//TODO:
        }, true);
    },
    unmounted () {
        this.websocket.unsubscribe({
            channel: 'orders',
            instType: this.instrumentType
        }, 'user-asset-order', true);
    },
    methods: {
        clickOrderTab (tab) {
            if (this.tab !== tab) {
                this.tab = tab;
                this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_ORDER + '/' + this.tab);
            }
        },
        changePendingOrderType (orderType) {
            this.pending.orderType = orderType;
            this.spotOrderPendingList();
        },
        changeHistoryOrderType (orderType) {
            this.history.orderType = orderType;
            this.spotOrderHistoryList();
        },
        changeHistoryOrderStartTime (startTime) {
            this.history.startTime = startTime;
            this.spotOrderHistoryList();
        },
        changeHistoryOrderEndTime (endTime) {
            this.history.endTime = endTime;
            this.spotOrderHistoryList();
        },
        changeFundingOrderStartTime (startTime) {
            this.funding.startTime = startTime;
            this.assetBillList(this.funding.currency, this.funding.billType, this.funding.startTime, this.funding.endTime, this.funding.LIMIT);
        },
        changeFundingOrderEndTime (endTime) {
            this.funding.endTime = endTime;
            this.assetBillList(this.funding.currency, this.funding.billType, this.funding.startTime, this.funding.endTime, this.funding.LIMIT);
        },
        changeFundingCurrency (currency) {
            this.funding.currency = currency;
            this.assetBillList(this.funding.currency, this.funding.billType, this.funding.startTime, this.funding.endTime, this.funding.LIMIT);
        },
        changeFundingBillType (billType) {
            this.funding.billType = billType;
            this.assetBillList(this.funding.currency, this.funding.billType, this.funding.startTime, this.funding.endTime, this.funding.LIMIT);
        },
        spotOrderPendingList () {
            this.api.spotOrderPendingList(this, {
                instrumentType: this.instrumentType,
                orderType: this.pending.orderType
            }, function (rspBody) {
                let orderList = [];
                for (let i = 0; i < rspBody.orders.length; i++) {
                    let order = rspBody.orders[i];
                    orderList.push({
                        ordId: order.ordId,
                        instId: order.instId,
                        instType: order.instType,
                        ordType: order.ordType,
                        side: order.side,
                        cTime: new Date(Number(order.cTime)),
                        px: Number(order.px),
                        sz: Number(order.sz),
                        avgPx: Number(order.avgPx),
                        accFillSz: Number(order.accFillSz),
                        state: order.state
                    });
                }
                this.pending.orderList = orderList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        spotOrderHistoryList () {
            this.api.spotOrderHistoryList(this, {
                instrumentType: this.instrumentType,
                orderType: this.history.orderType,
                startTime: this.history.startTime ? this.history.startTime.getTime() : 0,
                endTime: this.history.endTime ? this.history.endTime.getTime() : 0
            }, function (rspBody) {
                let orderList = [];
                for (let i = 0; i < rspBody.orders.length; i++) {
                    let order = rspBody.orders[i];
                    orderList.push({
                        ordId: order.ordId,
                        instId: order.instId,
                        instType: order.instType,
                        ordType: order.ordType,
                        side: order.side,
                        cTime: new Date (Number(order.cTime)),
                        px: Number(order.px),
                        sz: Number(order.sz),
                        avgPx: Number(order.avgPx),
                        accFillSz: Number(order.accFillSz),
                        fee: Number(order.fee),
                        state: order.state
                    });
                }
                this.history.orderList = orderList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetBillList (currency, billType, startTime, endTime, limit) {
            this.api.assetBillList(this, {
                currency: (currency ? currency.id : ''),
                billType: billType,
                startTime: (startTime ? startTime.getTime() : 0),
                endTime: (endTime ? endTime.getTime() : 0),
                limit: limit
            }, function (rspBody) {
                let fundingBillList = [];
                for (let i = 0; i < rspBody.billList.length; i++) {
                    let bill = rspBody.billList[i];
                    fundingBillList.push({
                        billId: bill.billId,
                        time: new Date(Number(bill.ts)),
                        type: this.abs.common.ECashBillTypes[bill.type] || '',
                        ccy: bill.ccy,
                        amount: Number(bill.balChg) || 0,
                        balance: Number(bill.bal),
                        status: this.abs.wallet.ETransferStatus['success']
                    });
                }
                this.funding.billList = fundingBillList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.order-center .order-tabs {
    display: flex;
    border-bottom: 2px solid var(--sep-color);
}
.order-center .order-tabs .order-tab {
    height: 36px;
    line-height: 36px;
    margin-right: 32px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.order-center .order-tabs .order-tab.active {
    color: var(--text-strong);
    border-bottom-color: var(--text-strong);
}
.order-center .order-panel {
    display: none;
}
.order-center .order-panel.active {
    display: block;
}
.order-center .order-panel .filter {
    padding: 12px 0;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.order-center .order-panel .filter .filter-item {
    margin-right: 32px;
    display: flex;
    align-items: center;
}
.order-center .order-panel .filter .filter-item .label {
    margin-right: 8px;
}
.order-center .order-panel .filter .filter-item .field {
    display: flex;
    align-items: center;
}
.order-center .order-panel .order-list .row {
    display: flex;
    align-items: center;
}
.order-center .order-panel .order-list .row .col {
    width: 10%;
}
.order-center .order-panel .order-list .row .col.col-time {
    width: 14%;
}
.order-center .order-panel .order-list .row .col.col-price {
    width: 12%;
}
.order-center .order-panel .order-list .row .col.col-count {
    width: 12%;
}
.order-center .order-panel .order-list .row .col.col-amount {
    width: 12%;
}
.order-center .order-panel .order-list .row .col.col-bill-time {
    width: 20%;
}
.order-center .order-panel .order-list .row .col.col-bill-currency {
    width: 15%;
}
.order-center .order-panel .order-list .row .col.col-bill-type {
    width: 15%;
}
.order-center .order-panel .order-list .row .col.col-bill-amount {
    width: 20%;
}
.order-center .order-panel .order-list .row .col.col-bill-balance {
    width: 20%;
}
.order-center .order-panel .order-list .row .col.col-bill-fee {
    width: 10%;
}
.order-center .order-panel .order-list .row .col.number {
    text-align: right;
}
.order-center .order-panel .order-list .row .col.enum {
    text-align: center;
}
.order-center .order-panel .order-list .head {
    padding: 32px 0 16px 0;
    border-bottom: 1px solid var(--sep-color);
}
.order-center .order-panel .order-list .body .row {
    min-height: 48px;
    line-height: 20px;
    border-bottom: 1px solid var(--sep-color);
}
.order-center .order-panel .order-list .body .row:hover {
    background-color: var(--background-hover);
}
.order-center .order-panel .order-list .body .empty {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>