<template>
<div class="currency-picker" :class="{'open':open}">
    <div class="mask" @click="clickMask"></div>
    <div class="control item" :style="'min-height:'+height+'px'" @click="clickControl">
        <span class="left" :class="{'align-left':textAlign===abs.base.EDirection.LEFT,'align-right':textAlign===abs.base.EDirection.RIGHT}"><span class="name" v-text="active ? active.text : ''"></span></span>
        <span class="right"><i class="icon chevron-down"></i></span>
    </div>
    <div class="panel">
        <div class="filter"><label class="holder"><input class="text" type="text" v-model="search" /><i class="icon search"></i></label></div>
        <div class="menu">
            <div class="item" :class="{'selected':active && option.id===active.id}" :style="'justify-content:' + (textAlign===abs.base.EDirection.LEFT ? 'flex-start' : (textAlign===abs.base.EDirection.RIGHT ? 'flex-end' : 'center'))" v-for="option in filteredOptions" :key="option.id" @click="clickOption(option)">
                <img class="icon" v-if="option.icon" :src="option.icon" alt="" />
                <span class="name" v-text="option.text"></span>
                <span class="attach" v-if="option.attach" v-text="option.attach"></span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "CurrencyPicker",
    props: {
        value: {
            type: String,
            default: () => {
                return '';
            }
        },
        action: {
            type: String,
            default: () => {
                return '';
            }
        },
        textAlign: {
            type: String,
            default: () => {
                return 'center';
            }
        },
        height: {
            type: Number,
            default: () => {
                return 40;
            }
        },
        all: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE_VALUE: 'changeValue'
            },
            search: '',
            active: null,
            options: [],
            chainLists: [],
            open: false
        };
    },
    computed: {
        filteredOptions () {
            if (!this.search) {
                return this.options;
            }
            let search = this.search.toLowerCase();
            let options = [];
            for (let i = 0; i < this.options.length; i++) {
                let option = this.options[i];
                if (option.text.toLowerCase().indexOf(search) >= 0) {
                    options.push(option);
                }
            }
            return options;
        }
    },
    watch: {
        value (value) {
            let active = null;
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].id === value) {
                    active = this.options[i];
                    break;
                }
            }
            this.active = active;
        }
    },
    mounted () {
        this.api.currencies(this, null, function (rspBody) {
            let options = [];
            if (this.all) {
                options.push({
                    id: '',
                    icon: '',
                    text: this.$t('common.all'),
                    attach: ''
                });
            }
            let chainLists = [];
            for (let i = 0; i < rspBody.currencies.length; i++) {
                let currency = rspBody.currencies[i];
                if (this.action === this.abs.wallet.EWalletAction.DEPOSIT && !currency.canDep) {
                    continue;
                }
                if (this.action === this.abs.wallet.EWalletAction.WITHDRAW && !currency.canWd) {
                    continue;
                }
                let chainList = chainLists[currency.ccy];
                if (!chainList) {
                    let option = {
                        id: currency.ccy,
                        icon: currency.logoLink,
                        text: currency.ccy,
                        attach: currency.name,
                        minDeposit: Number(currency.minDep),
                        minWithdraw: Number(currency.minWd),
                        maxWithdraw: Number(currency.maxWd),
                        withdrawTickSize: Number(currency.wdTickSz),
                        withdrawQuota: Number(currency.wdQuota),
                        withdrawQuotaUsed: Number(currency.usedWdQuota)
                    };
                    options.push(option);
                    chainList = [];
                    chainLists[currency.ccy] = chainList;
                }
                chainList.push({
                    id: currency.chain,
                    text: currency.chain,
                    canDeposit: currency.canDep,
                    canWithdraw: currency.canWd,
                    minFee: Number(currency.minFee),
                    maxFee: Number(currency.maxFee),
                    mainNet: currency.mainNet,
                    needTag: currency.needTag,
                    minDepositConfirm: Number(currency.minDepArrivalConfirm),
                    minWithdrawConfirm: Number(currency.minWdUnlockConfirm)
                });
            }
            this.options = options;
            this.chainLists = chainLists;

            let active = null;
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].id === this.value) {
                    active = this.options[i];
                    break;
                }
            }
            this.active = active;
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
    },
    methods: {
        clickControl () {
            this.open = !this.open;
        },
        clickMask () {
            this.open = false;
        },
        clickOption (option) {
            this.active = option;
            this.open = false;
            let chainList = this.chainLists[this.active.id];
            this.$emit(this.EVENTS.CHANGE_VALUE, this.active, chainList);
        }
    }
}
</script>

<style scoped>
.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    display: none;
    z-index: 1;
}
.currency-picker.open .mask {
    display: block;
}
.currency-picker {
    width: 100%;
}
.currency-picker .control {
    width: 100%;
    background-color: var(--background-secondary);
    border: 1px solid var(--background-tertiary);
    border-radius: 4px;
}
.currency-picker.open .control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.currency-picker .item {
    min-height: 40px;
    padding: 0 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.currency-picker .item:hover {
    background-color: var(--dropdown-item-hover);
}
.currency-picker .item.selected {
    background: var(--background-tertiary);
}
.currency-picker .item .left {
    flex-grow: 1;
    text-align: center;
}
.currency-picker .item .left.align-left {
    text-align: left;
}
.currency-picker .item .left.align-right {
    text-align: right;
}
.currency-picker .item .right {
    margin-left: 4px;
    display: flex;
    align-items: center;
}
.currency-picker .item .right .icon {
    width: 12px;
    height: 12px;
    color: var(--text-muted);
    background-color: var(--text-muted);
    display: inline-block;
}
.currency-picker .item .name {
    color: var(--text-default);
}
.currency-picker .panel {
    position: absolute;
    width: 100%;
    max-height: 360px;
    box-shadow: var(--dropdown-menu-shadow) 0 1px 5px 0;
    background-color: var(--background-primary);
    border: 1px solid var(--background-tertiary);
    border-radius: 4px;
    overflow: hidden auto;
    z-index: 1;
    display: none;
}
.currency-picker.open .panel {
    display: block;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.currency-picker .panel .filter {
    padding: 12px 16px;
}
.currency-picker .panel .filter .holder {
    display: block;
}
.currency-picker .panel .filter .icon {
    position: absolute;
    top: 14px;
    left: 14px;
    width: 12px;
    height: 12px;
}
.currency-picker .panel .filter .text {
    padding-left: 40px;
}
.currency-picker .panel {
    padding-bottom: 8px;
}
.currency-picker .panel .menu .item {
    padding-left: 16px;
    padding-right: 24px;
}
.currency-picker .panel .menu .item .icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}
.currency-picker .panel .menu .item .name {
    margin-right: 12px;
}
.currency-picker .panel .menu .item .attach {
    color: var(--text-secondary);
    font-size: 12px;
}
</style>