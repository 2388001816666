<template>
<div class="action-panel withdrawal-panel">
    <div class="title" v-text="$t('wallet.withdraw.withdraw')"></div>
    <div class="step" :class="{'doing':step===STEPS.ONE,'done':step!==STEPS.ONE}">
        <div class="num">1</div>
        <div class="label" v-text="$t('wallet.withdraw.select_crypto')"></div>
        <div class="block block-doing">
            <currency-picker class="field" :action="abs.wallet.EWalletAction.WITHDRAW" :text-align="'left'" @changeValue="changeCurrency"></currency-picker>
            <div class="next"><a class="button primary-button" @click="clickNext" :class="{'disabled':!currency}" v-text="$t('wallet.withdraw.next')"></a></div>
        </div>
        <div class="num num-doing">2</div>
        <div class="label label-doing" v-text="$t('wallet.withdraw.withdraw_details')"></div>
        <div class="block block-done">
            <div class="crypto-chain" v-text="$t('wallet.withdraw.withdraw') + ' ' + currencyText"></div>
            <div class="back"><a class="button link-button" @click="clickEdit" v-text="$t('wallet.withdraw.edit')"></a></div>
        </div>
    </div>
    <div class="step" :class="{'ending':step===STEPS.TWO}">
        <div class="num">2</div>
        <div class="label" v-text="$t('wallet.withdraw.withdraw_details')"></div>
        <div class="block">
            <div class="sub-label" style="margin-top:0" v-text="currencyText + ' ' + $t('wallet.withdraw.select_address')"></div>
            <div class="field"><label class="holder"><input class="text" type="text" v-model="address" /></label></div>
            <template v-if="extraLabel">
                <div class="sub-label" v-text="currencyText + ' ' + extraLabel"></div>
                <div class="field"><label class="holder"><input class="text" type="text" v-model="extraValue" /></label></div>
            </template>
            <div class="sub-label" v-text="$t('wallet.withdraw.select_chain', {currencyText:currencyText})"></div>
            <drop-down class="field" :text-align="'left'" :value="chainId" :options="chainList" @change="changeChain"></drop-down>
            <div class="notice">
                <div class="text" v-text="$t('wallet.withdraw.select_chain_ensure', {currencyText:currencyText})"></div>
                <i class="icon exclamation-circle"></i>
            </div>
            <div class="sub-label" v-text="$t('wallet.withdraw.amount')"></div>
            <div class="field"><label class="holder"><input class="text" type="text" v-model="amount" /><span class="unit" v-text="currencyText"></span></label></div>
            <div class="amount-available"><span class="text" v-text="$t('wallet.withdraw.available') + ': ' + abs.Util.formatNumber(amountAvailable) + ' ' + currencyText"></span><a class="button link-button" @click="clickMaxAvailable" v-text="$t('wallet.withdraw.max')"></a></div>
            <div class="amount-limit" v-text="$t('wallet.withdraw.h24_limit') + ': ' + abs.Util.formatNumber(h24LimitUsed) + ' / ' + abs.Util.formatNumber(h24Limit) + ' ' + currencyText"></div>
            <div class="sub-label" v-text="$t('wallet.withdraw.fees')"></div>
            <div class="field"><label class="holder"><input class="text" type="text" v-model="fee" /><span class="unit" v-text="currencyText"></span></label></div>
            <div class="est"><span class="est-label" v-text="$t('wallet.withdraw.estimated_total')"></span><span class="est-value" v-text="abs.Util.formatNumber(estimateValue, (currency ? currency.withdrawTickSize : 0)) + ' ' + currencyText"></span></div>
            <div class="next"><a class="button primary-button" :class="{'disabled':!chain || !address || !Number(amount) || !Number(fee)}" @click="clickSubmit" v-text="$t('wallet.withdraw.next')"></a></div>
        </div>
    </div>
    <div class="history">
        <div class="label" v-text="$t('wallet.withdraw.withdrawals')"></div>
        <div class="only" v-if="currency"><checkbox-field :label="$t('wallet.withdraw.crypto_only', {currencyText:currencyText})" :value="currencyOnly" @changeValue="changeCurrencyOnly"></checkbox-field></div>
        <div class="list">
            <div class="head">
                <div class="row">
                    <div class="col time" v-text="$t('wallet.withdraw.time')"></div>
                    <div class="col id" v-text="$t('wallet.withdraw.id')"></div>
                    <div class="col address" v-text="$t('wallet.withdraw.address')"></div>
                    <div class="col txid" v-text="$t('wallet.withdraw.txid')"></div>
                    <div class="col crypto" v-text="$t('wallet.withdraw.crypto')"></div>
                    <div class="col amount" v-text="$t('wallet.withdraw.amount')"></div>
                    <div class="col fee" v-text="$t('wallet.withdraw.fee')"></div>
                    <div class="col status" v-text="$t('wallet.withdraw.status')"></div>
                    <div class="col action" v-text="$t('wallet.withdraw.action')"></div>
                </div>
            </div>
            <div class="body">
                <div class="row" v-for="order in orderList" :key="order.wdId">
                    <div class="col time" v-text="$d(order.time, 'long')"></div>
                    <div class="col id" v-text="order.wdId"></div>
                    <div class="col address" v-text="order.address + ' (' + order.chain + ')'"></div>
                    <div class="col txid" v-text="order.txId"></div>
                    <div class="col crypto" v-text="order.currency"></div>
                    <div class="col amount" v-text="abs.Util.formatNumber(order.amount)"></div>
                    <div class="col fee" v-text="abs.Util.formatNumber(order.fee)"></div>
                    <div class="col status" v-text="abs.wallet.EWithdrawStatus[order.status]"></div>
                    <div class="col action"><a class="button link-button" v-if="order.status==='0'" @click="clickWithdrawCancel" v-text="$t('common.cancel')"></a></div>
                </div>
            </div>
            <div class="empty" v-if="!orderList.length" v-text="$t('wallet.withdraw.no_withdraw')"></div>
        </div>
        <div class="show-history"><a class="button link-button" @click="clickHistory" v-text="$t('wallet.withdraw.open_history')"></a></div>
    </div>

    <dialog-panel class="withdraw-confirm" v-if="confirm" :title="$t('wallet.withdraw.confirm_title')" @confirm="clickConfirm" @cancel="clickCancel" @close="clickClose">
        <div class="content">
            <div class="ensure"><i class="icon exclamation-circle"></i><span v-text="$t('wallet.withdraw.confirm_ensure')"></span></div>
            <div class="fields">
                <div class="field">
                    <div class="label" v-text="$t('wallet.withdraw.confirm_chain')"></div><div class="value" v-text="chainText"></div>
                </div>
                <div class="field">
                    <div class="label" v-text="$t('wallet.withdraw.confirm_address')"></div><div class="value" v-text="address"></div>
                </div>
                <div class="field">
                    <div class="label" v-text="$t('wallet.withdraw.confirm_amount')"></div><div class="value" v-text="abs.Util.formatNumber(amount) + ' ' + currencyText"></div>
                </div>
                <div class="field">
                    <div class="label" v-text="$t('wallet.withdraw.confirm_fees')"></div><div class="value" v-text="abs.Util.formatNumber(fee) + ' ' + currencyText"></div>
                </div>
            </div>
            <div class="confirm" v-if="false">
                <div class="label" v-text="$t('wallet.withdraw.email_code')"></div>
                <div class="field"><label class="holder"><input class="text" type="text" /><a class="button link-button" v-text="$t('wallet.withdraw.send_code')"></a></label></div>
            </div>
            <div class="confirm" v-if="false">
                <div class="label" v-text="$t('wallet.withdraw.sms_code')"></div>
                <div class="field"><label class="holder"><input class="text" type="text" /><a class="button link-button" v-text="$t('wallet.withdraw.send_code')"></a></label></div>
            </div>
            <div class="notices">
                <div class="notice" v-text="$t('wallet.withdraw.notice_1')"></div>
                <div class="notice" v-text="$t('wallet.withdraw.notice_2')"></div>
            </div>
        </div>
    </dialog-panel>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import CurrencyPicker from "@/components/CurrencyPicker";
import DropDown from "@/components/DropDown";
import CheckboxField from "@/components/CheckboxField";
import DialogPanel from "@/components/DialogPanel";
export default {
    name: "UserAssetWithdraw",
    components: {DialogPanel, CheckboxField, DropDown, CurrencyPicker},
    props: {
        baseLotSize: {
            type: Number,
            default: () => {return 8;}
        }
    },
    data () {
        return {
            STEPS: {
                ONE: '1',
                TWO: '2'
            },
            step: '1',
            currency: null,
            chain: null,
            chainList: [],
            address: '',
            extraLabel: '',
            extraValue: '',
            amount: '',
            amountMin: 0,
            h24Limit: 0,
            h24LimitUsed: 0,
            fee: '0',
            confirm: false,
            fundingAssetValueList: [],
            currencyOnly: false,
            orderList: []
        };
    },
    computed: {
        currencyId () {
            return this.currency ? this.currency.id : '';
        },
        currencyText () {
            return this.currency ? this.currency.text : '';
        },
        chainId () {
            return this.chain ? this.chain.id : '';
        },
        chainText () {
            return this.chain ? this.chain.text : '';
        },
        amountAvailable () {
            if (!this.currency) {
                return 0;
            }
            for (let i = 0; i < this.fundingAssetValueList.length; i++) {
                let asset = this.fundingAssetValueList[i];
                if (asset.ccy === this.currency.id) {
                    return asset.value;
                }
            }
            return 0;
        },
        estimateValue () {
            let amount = Math.max(Number(this.amount) || 0, 0);
            let fee = Math.max(Number(this.fee) || 0, 0);
            if (amount <= 0) {
                return 0;
            }
            else {
                if (this.amountAvailable - amount - fee >= 0) {
                    return amount;
                }
                else {
                    return Math.max(amount - fee, 0);
                }
            }
        }
    },
    mounted () {
        this.assetBalances();
        this.assetWithdrawalList();
        this.websocket.subscribe({
            channel: 'withdrawal-info'
        }, 'user-asset-withdraw', function (content) {
            if (!content.data || !content.data.length) {
                return;
            }
            for (let i = 0; i < content.data.length; i++) {
                let item = content.data[i];

                let exist = false;
                for (let j = 0; j < this.orderList.length; j++) {
                    let order = this.orderList[j];
                    if (order.wdId === item.wdId) {
                        order.status = item.state;
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    this.orderList.unshift({
                        wdId: item.wdId,
                        txId: item.txId,
                        time: new Date(Number(item.ts)),
                        address: item.to,
                        chain: item.chain,
                        currency: item.ccy,
                        amount: Number(item.amt),
                        fee: Number(item.fee),
                        status: item.state
                    });
                }
            }
        }, true);
    },
    unmounted () {
        this.websocket.unsubscribe({
            channel: 'withdrawal-info'
        }, 'user-asset-withdraw', true);
    },
    methods: {
        changeCurrency (currency, chainList) {
            this.currency = currency;
            this.chain = null;
            this.chainList = chainList;
            this.address = '';
            this.extraLabel = '';
            this.extraValue = '';
            this.amount = '';
            this.amountMin = currency.minWithdraw;
            this.h24Limit = currency.withdrawQuota;
            this.h24LimitUsed = currency.withdrawQuotaUsed;
            this.fee = '0';
            this.assetWithdrawalList();
        },
        changeChain (chainId, chain) {
            this.chain = chain;
            this.extraLabel = chain.needTag ? 'tag' : '';
            this.extraValue = '';
            this.fee = chain.minFee;
        },
        clickNext () {
            if (!this.currency) {
                return;
            }
            this.step = this.STEPS.TWO;
        },
        clickEdit () {
            this.address = '';
            this.extraLabel = '';
            this.extraValue = '';
            this.amount = '';
            this.step = this.STEPS.ONE;
        },
        clickMaxAvailable () {
            this.amount = this.amountAvailable;
        },
        clickSubmit () {
            if (!this.currency || !this.chain || !this.address || !Number(this.amount) || !Number(this.fee)) {
                return;
            }
            this.confirm = true;
        },
        clickConfirm () {
            this.assetWithdrawal();
        },
        clickCancel () {
            this.confirm = false;
        },
        clickClose () {
            this.confirm = false;
        },
        clickWithdrawCancel (withdrawalId) {
            this.assetWithdrawalCancel(withdrawalId);
        },
        changeCurrencyOnly (currencyOnly) {
            this.currencyOnly = currencyOnly;
            this.assetWithdrawalList();
        },
        clickHistory () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_ORDER + '/' + this.abs.routes.USER_ASSET_MODULE_ORDER_FUNDING);
        },
        assetBalances () {
            this.api.assetBalances(this, null, function (rspBody) {
                let fundingAssetValueList = [];
                for (let i = 0; i < rspBody.balances.length; i++) {
                    let balance = rspBody.balances[i];
                    fundingAssetValueList.push({
                        ccy: balance.ccy,
                        ccyLogo: balance.ccyLogo,
                        value: Number(balance.bal)
                    });
                }
                this.fundingAssetValueList = fundingAssetValueList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetWithdrawal () {
            this.api.assetWithdrawal(this, {
                currency: this.currencyId,
                chain: this.chainId,
                withdrawalType: this.abs.wallet.EWithdrawType.CHAIN,
                address: this.address,
                amount: Number(this.amount) || 0,
                fee: Number(this.fee) || 0
            }, function () {
                this.confirm = false;
                this.abs.Toast.show(this.$t('withdraw.submitted'));
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetWithdrawalCancel (withdrawalId) {
            this.api.assetWithdrawalCancel(this, {
                withdrawalId: withdrawalId
            }, function () {
                this.abs.Toast.show(this.$t('withdraw.cancel_submitted'));
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetWithdrawalList () {
            this.api.assetWithdrawalList(this, {
                currency: (this.currencyOnly ? this.currency.id : '')
            }, function (rspBody) {
                let orderList = [];
                for (let i = 0; i < rspBody.withdrawalList.length; i++) {
                    let withdrawal = rspBody.withdrawalList[i];
                    orderList.push({
                        wdId: withdrawal.wdId,
                        txId: ((withdrawal.txId && withdrawal.txId.length > 8) ? (withdrawal.txId.substr(0, 4) + '....' + withdrawal.txId.substr(withdrawal.txId.length - 4)) : withdrawal.txId),
                        time: new Date(Number(withdrawal.ts)),
                        address: withdrawal.to,
                        chain: withdrawal.chain,
                        currency: withdrawal.ccy,
                        amount: Number(withdrawal.amt),
                        fee: Number(withdrawal.fee),
                        status: withdrawal.state
                    });
                }
                this.orderList = orderList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.action-panel .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 24px;
}
.action-panel .step {
    padding-left: 18px;
    display: none;
}
.action-panel .step.doing, .action-panel .step.done, .action-panel .step.ending {
    display: block;
}
.action-panel .step .num {
    position: absolute;
    left: 6px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-panel .step.doing .num, .action-panel .step.ending .num {
    border: 1px solid var(--text-default);
}
.action-panel .step .num.num-doing {
    color: var(--text-muted);
    border: 1px solid var(--text-muted);
}
.action-panel .step.done .num.num-doing {
    display: none;
}
.action-panel .step.done .num {
    color: var(--background-primary);
    background-color: var(--text-default);
}
.action-panel .step .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    padding: 0 18px;
}
.action-panel .step .label.label-doing {
    color: var(--text-muted);
}
.action-panel .step.done .label.label-doing {
    display: none;
}
.action-panel .step .block {
    width: 460px;
    padding: 24px 18px;
}
.action-panel .step.doing .block {
    border-left: 1px dashed var(--text-tertiary);
}
.action-panel .step.done .block {
    border-left: 1px solid var(--text-default);
}
.action-panel .step.doing .block.block-done {
    display: none;
}
.action-panel .step.done .block.block-doing {
    display: none;
}
.action-panel .step .block .sub-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    margin-top: 36px;
    margin-bottom: 8px;
}
.action-panel .step .block .notice {
    margin: 8px 0;
    padding: 8px 8px 8px 32px;
    border-radius: 4px;
    background-color: var(--background-secondary);
}
.action-panel .step .block .notice .icon {
    position: absolute;
    top: 10px;
    left: 8px;
}
.action-panel .step .block .notice .text {
    color: var(--text-strong);
    line-height: 20px;
}
.action-panel .step .block .next {
    margin-top: 36px;
}
.action-panel .step .block .next .button {
    height: 40px;
    border-radius: 20px;
}
.action-panel .step .block .crypto-chain {
    color: var(--text-strong);
    font-weight: 500;
    line-height: 20px;
}
.action-panel .step .block .back {
    position: absolute;
    top: 24px;
    right: 18px;
}
.action-panel .step .block .back .button {
    height: 20px;
    color: var(--text-active);
}
.action-panel .step .block .address {
    padding: 8px;
    border-radius: 4px;
    background-color: var(--background-secondary-alt);
    display: flex;
    align-items: center;
}
.action-panel .step .block .address .nil {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.action-panel .step .block .address .text {
    flex: 1;
    line-height: 20px;
    background-color: var(--background-secondary-alt);
    word-wrap: break-word;
    overflow: hidden;
}
.action-panel .step .block .address .icon {
    flex-shrink: 0;
    margin-left: 8px;
    cursor: pointer;
}
.action-panel .step .block .field .holder {
    border-radius: 3px;
    background-color: var(--background-tertiary);
    display: flex;
    align-items: center;
}
.action-panel .step .block .field .holder .unit {
    padding: 0 8px;
}
.action-panel .step .block .amount-available {
    padding: 0 8px;
    border-radius: 3px;
    background-color: var(--background-secondary-alt);
    display: flex;
    align-items: center;
}
.action-panel .step .block .amount-available .text {
    font-size: 12px;
    line-height: 30px;
}
.action-panel .step .block .amount-available .button {
    height: 30px;
}
.action-panel .step .block .amount-limit {
    font-size: 12px;
    line-height: 30px;
}
.action-panel .step .block .est {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
}
.action-panel .step .block .est .est-label {
    color: var(--text-secondary);
}
.action-panel .step .block .est .est-value {
    color: var(--text-strong);
}
.action-panel .step .block .account-exchange {
    display: flex;
    align-items: center;
}
.action-panel .step .block .account-exchange .account {
    flex: 1;
}
.action-panel .step .block .account-exchange .account .acc-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    margin-top: 36px;
    margin-bottom: 8px;
}
.action-panel .step .block .account-exchange .account .acc-field {
    height: 40px;
    padding: 0 8px;
    background-color: var(--background-secondary);
    border: 1px solid var(--background-tertiary);
    border-radius: 3px;
    display: flex;
    align-items: center;
}
.action-panel .step .block .account-exchange .ex {
    margin: 64px 8px 0 8px;
    cursor: pointer;
}
.action-panel .step .block .memos {
    margin-top: 64px;
    padding: 16px;
    background-color: var(--background-secondary);
    border-radius: 4px;
}
.action-panel .step .block .memos .memo {
    padding: 8px 0;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}
.action-panel .step .block .memos .memo .memo-desc {
    color: var(--text-secondary);
}
.action-panel .step .block .memos .memo .memo-value {
    color: var(--text-strong);
}

.action-panel .history {
    margin-top: 36px;
}
.action-panel .history .label-tabs {
    display: flex;
    align-items: center;
}
.action-panel .history .label {
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
}
.action-panel .history .label.label-tab {
    margin-right: 40px;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.action-panel .history .label.label-tab.active {
    border-bottom-color: var(--text-default);
}
.action-panel .history .only {
    position: absolute;
    top: 0;
    left: 260px;
    line-height: 20px;
}
.action-panel .history .list {
    margin-top: 16px;
}
.action-panel .history .row {
    height: 52px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.action-panel .history .body.body-tab {
    display: none;
}
.action-panel .history .body.body-tab.active {
    display: block;
}
.action-panel .history .body .row:hover {
    background-color: var(--background-hover);
}
.action-panel .history .head .col {
    color: var(--text-tertiary);
}
.action-panel .history .empty {
    height: 180px;
    color: var(--text-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-panel .history .show-history {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 20px;
}
.action-panel .history .show-history .button {
    min-width: 0;
    height: 20px;
    color: var(--text-active);
}

.withdrawal-panel .history .col.time {
    width: 14%;
}
.withdrawal-panel .history .col.id {
    width: 10%;
}
.withdrawal-panel .history .col.address {
    width: 20%;
    padding-right: 24px;
    overflow-wrap: break-word;
}
.withdrawal-panel .history .col.txid {
    width: 10%;
}
.withdrawal-panel .history .col.crypto {
    width: 8%;
}
.withdrawal-panel .history .col.amount {
    width: 10%;
}
.withdrawal-panel .history .col.fee {
    width: 10%;
}
.withdrawal-panel .history .col.status {
    width: 12%;
}
.withdrawal-panel .history .col.action {
    width: 6%;
    text-align: right;
}

.withdraw-confirm .content {
    padding: 16px;
}
.withdraw-confirm .ensure {
    padding: 8px;
    line-height: 20px;
    border-radius: 3px;
    background-color: var(--background-secondary);
    display: flex;
    align-items: center;
}
.withdraw-confirm .ensure .icon {
    margin-right: 8px;
}
.withdraw-confirm .fields {
    margin: 16px 0;
}
.withdraw-confirm .fields .field {
    padding: 8px 0;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
}
.withdraw-confirm .fields .field .value {
    color: var(--text-strong);
    font-weight: 500;
}
.withdraw-confirm .confirm {
    margin-top: 8px;
}
.withdraw-confirm .confirm .label {
    padding-bottom: 4px;
    line-height: 20px;
}
.withdraw-confirm .confirm .field .holder {
    display: flex;
    align-items: center;
}
.withdraw-confirm .confirm .field .holder .text {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.withdraw-confirm .confirm .field .holder .button {
    width: 100px;
    height: 40px;
    background-color: var(--background-tertiary);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.withdraw-confirm .notices {
    margin-top: 24px;
    padding: 8px;
    line-height: 20px;
    border-radius: 3px;
    background-color: var(--background-secondary);
}
</style>