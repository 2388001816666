<template>
<div class="overview">
    <div class="account">
        <div class="avatar"><i class="icon user-alt"></i></div>
        <div class="nickname">
            <div class="label" v-text="$t('account.overview.nickname')"></div>
            <div class="value" v-text="nickname || '-'"></div>
        </div>
        <div class="fields">
            <div class="field">
                <div class="label" v-text="$t('account.overview.user_id')"></div>
                <div class="value" v-text="code || '-'"></div>
            </div>
            <div class="field">
                <div class="label" v-text="$t('account.overview.trade_fee_tier')"></div>
                <div class="value" style="text-transform:capitalize" v-text="level || '-'"></div>
            </div>
            <div class="field">
                <div class="label" v-text="$t('account.overview.email')"></div>
                <div class="value" v-text="email || '-'"></div>
            </div>
            <div class="field">
                <div class="label" v-text="$t('account.overview.phone')"></div>
                <div class="value" v-text="phone || '-'"></div>
            </div>
        </div>
    </div>
    <div class="asset-overview">
        <div class="side-left">
            <div class="border-box border-box-panel balance">
                <div class="label" v-text="$t('wallet.overview.total')"></div>
                <div class="amount">
                    <div class="value">
                        <div class="text" v-text="innerUnit.symbol + abs.Util.formatNumber(totalAmount, innerUnit.lotSize)"></div>
                        <drop-down class="unit" :width="80" :height="36" :value="innerUnit.id" :options="units" @change="changeUnit"></drop-down>
                    </div>
                    <div class="actions">
                        <a class="action" @click="clickDeposit" v-text="$t('wallet.deposit.deposit')"></a>
                        <a class="action" @click="clickWithdraw" v-text="$t('wallet.withdraw.withdraw')"></a>
                        <a class="action" @click="clickTransfer" v-text="$t('wallet.transfer.transfer')"></a>
                    </div>
                </div>
            </div>
            <div class="border-box account-detail">
                <div class="border-box-title" v-text="$t('wallet.overview.my_assets')"></div>
                <div class="panel">
                    <div class="group">
                        <div class="name" v-text="$t('wallet.overview.accounts')"></div>
                        <div class="rows">
                            <div class="row">
                                <div class="col account">
                                    <i class="icon cash"></i>
                                    <div class="text" v-text="$t('wallet.funding.funding')"></div>
                                </div>
                                <div class="col amount">
                                    <div class="text number" v-text="innerUnit.symbol + abs.Util.formatNumber(totalFundingAmount, baseLotSize)"></div>
                                </div>
                                <div class="col rate">
                                    <div class="text number" v-text="abs.Util.formatNumber(totalFundingRate, 2) + '%'"></div>
                                </div>
                                <div class="col opers">
                                    <a class="oper" @click="clickDeposit" v-text="$t('wallet.deposit.deposit')"></a>
                                    <a class="oper" @click="clickWithdraw" v-text="$t('wallet.withdraw.withdraw')"></a>
                                    <a class="oper" @click="clickTransfer" v-text="$t('wallet.transfer.transfer')"></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col account">
                                    <i class="icon trade"></i>
                                    <div class="text" v-text="$t('wallet.trading.trading')"></div>
                                </div>
                                <div class="col amount">
                                    <div class="text number" v-text="innerUnit.symbol + abs.Util.formatNumber(totalTradingAmount, baseLotSize)"></div>
                                </div>
                                <div class="col rate">
                                    <div class="text number" v-text="abs.Util.formatNumber(totalTradingRate, 2) + '%'"></div>
                                </div>
                                <div class="col opers">
                                    <a class="oper" @click="clickTrade" v-text="$t('wallet.trading.trade')"></a>
                                    <a class="oper" @click="clickTransfer" v-text="$t('wallet.transfer.transfer')"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="side-right">
            <div class="border-box currency-detail">
                <div class="border-box-title" v-text="$t('wallet.overview.assets')"></div>
                <div class="panel">
                    <div class="group" v-if="fundingAssetAmountList.length">
                        <div class="name" v-text="$t('wallet.funding.funding')"></div>
                        <div class="rows">
                            <div class="row" v-for="asset in fundingAssetAmountList" :key="asset.ccy">
                                <div class="col currency">
                                    <div class="icon" :style="'background-image:url(' + asset.ccyLogo + ')'"></div>
                                    <div class="text" v-text="asset.ccy"></div>
                                </div>
                                <div class="col amount">
                                    <div class="text number" v-text="innerUnit.symbol + abs.Util.formatNumber(asset.amount, baseLotSize)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="more">
                            <a class="button link-button" v-text="$t('wallet.overview.view_all')" @click="clickFunding"></a>
                        </div>
                    </div>
                    <div class="group" v-if="tradingAssetAmountList.length">
                        <div class="name" v-text="$t('wallet.trading.trading')"></div>
                        <div class="rows">
                            <div class="row" v-for="asset in tradingAssetAmountList" :key="asset.ccy">
                                <div class="col currency">
                                    <div class="icon" :style="'background-image:url(' + asset.ccyLogo + ')'"></div>
                                    <div class="text" v-text="asset.ccy"></div>
                                </div>
                                <div class="col amount">
                                    <div class="text number" v-text="innerUnit.symbol + abs.Util.formatNumber(asset.amount, baseLotSize)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="more">
                            <a class="button link-button" v-text="$t('wallet.overview.view_all')" @click="clickTrading"></a>
                        </div>
                    </div>
                    <div class="empty" v-if="!fundingAssetAmountList.length && !tradingAssetAmountList.length" v-text="$t('wallet.overview.no_records')"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import DropDown from "@/components/DropDown";
export default {
    name: "UserAccountOverview",
    components: {DropDown},
    props: {
        baseLotSize: {
            type: Number,
            default: () => {return 8;}
        },
        baseUnit: {
            type: Object,
            default: () => {return {id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2};}
        },
        baseUnitTickers: {
            type: Array,
            default: () => {return [];}
        },
        btcUnitTicker: {
            type: Number,
            default: () => {return 0;}
        },
        usdUnitTicker: {
            type: Number,
            default: () => {return 0;}
        },
        unit: {
            type: Object,
            default: () => {return {id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2};}
        },
        units: {
            type: Array,
            default: () => {return [];}
        }
    },
    data () {
        return {
            uticket: '',
            code: '',
            nickname: '',
            level: '',
            email: '',
            phone: '',
            innerUnit: {id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2},
            totalValue: 0,
            totalFundingValue: 0,
            totalTradingValue: 0,
            fundingAssetValueList: [],
            tradingAssetValueList: [],
            ASSET_LIMIT: 10
        };
    },
    computed: {
        totalAmount () {
            if (this.innerUnit.id === 'BTC') {
                return this.btcUnitTicker ? (this.totalValue / this.btcUnitTicker) : 0;
            }
            else if (this.innerUnit.id === 'USD') {
                return this.usdUnitTicker ? (this.totalValue * this.usdUnitTicker) : 0;
            }
            else {
                return this.totalValue;
            }
        },
        totalFundingAmount () {
            if (this.innerUnit.id === 'BTC') {
                return this.btcUnitTicker ? (this.totalFundingValue / this.btcUnitTicker) : 0;
            }
            else if (this.innerUnit.id === 'USD') {
                return this.usdUnitTicker ? (this.totalFundingValue * this.usdUnitTicker) : 0;
            }
            else {
                return this.totalFundingValue;
            }
        },
        totalTradingAmount () {
            if (this.innerUnit.id === 'BTC') {
                return this.btcUnitTicker ? (this.totalTradingValue / this.btcUnitTicker) : 0;
            }
            else if (this.innerUnit.id === 'USD') {
                return this.usdUnitTicker ? (this.totalTradingValue * this.usdUnitTicker) : 0;
            }
            else {
                return this.totalTradingValue;
            }
        },
        totalFundingRate () {
            return (this.totalFundingValue && this.totalValue) ? (this.totalFundingValue / this.totalValue * 100) : 0;
        },
        totalTradingRate () {
            return (this.totalTradingValue && this.totalValue) ? (this.totalTradingValue / this.totalValue * 100) : 0;
        },
        fundingAssetAmountList () {
            let list = [];
            for (let i = 0; i < this.fundingAssetValueList.length && i < this.ASSET_LIMIT; i++) {
                let asset = this.fundingAssetValueList[i];
                let amount = asset.value;
                if (this.innerUnit.id === 'BTC') {
                    amount = amount / this.btcUnitTicker;
                }
                else if (this.innerUnit.id === 'USD') {
                    amount = amount * this.usdUnitTicker;
                }
                list.push({
                    ccy: asset.ccy,
                    ccyLogo: asset.ccyLogo,
                    amount: amount
                });
            }
            return list;
        },
        tradingAssetAmountList () {
            let list = [];
            for (let i = 0; i < this.tradingAssetValueList.length && i < this.ASSET_LIMIT; i++) {
                let asset = this.tradingAssetValueList[i];
                let amount = asset.value;
                if (this.innerUnit.id === 'BTC') {
                    amount = amount / this.btcUnitTicker;
                }
                else if (this.innerUnit.id === 'USD') {
                    amount = amount * this.usdUnitTicker;
                }
                list.push({
                    ccy: asset.ccy,
                    ccyLogo: asset.ccyLogo,
                    amount: amount
                });
            }
            return list;
        }
    },
    watch: {
        unit (unit) {
            if (this.innerUnit !== unit) {
                this.innerUnit = unit;
            }
        }
    },
    created () {
        this.uticket = this.abs.Store.uticket();
    },
    mounted () {
        if (this.uticket) {
            this.api.account(this, null, function (rspBody) {
                this.code = rspBody.user.code;
                this.nickname = rspBody.user.userNickname;
                this.phone = rspBody.user.phone;
                this.email = rspBody.user.email;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
            this.api.tradeFee(this, {
                instrumentType: this.abs.common.EInstrumentType.SPOT
            }, function (rspBody) {
                this.level = rspBody.tradeFee.level;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });

            this.valuation();
            this.assetBalances();
            this.accountBalance();
        }
    },
    methods: {
        changeUnit (id, innerUnit) {
            this.innerUnit = innerUnit;
        },
        clickDeposit () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_DEPOSIT);
        },
        clickWithdraw () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_WITHDRAW);
        },
        clickTransfer () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_TRANSFER);
        },
        clickTrade () {
            this.$router.push(this.abs.routes.SPOT_BASE);
        },
        clickFunding () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_CASH);
        },
        clickTrading () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_TRADE);
        },
        valuation () {
            this.api.valuation(this, {
                currency: this.baseUnit.id
            }, function (rspBody) {
                this.totalValue = Number(rspBody.valuation.totalBal);
                this.totalFundingValue = Number(rspBody.valuation.details.funding);
                this.totalTradingValue = Number(rspBody.valuation.details.trading);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetBalances () {
            this.api.assetBalances(this, null, function (rspBody) {
                let totalFundingValue = 0;
                let fundingAssetValueList = [];
                for (let i = 0; i < rspBody.balances.length; i++) {
                    let balance = rspBody.balances[i];
                    let value = 0;
                    if (balance.ccy === this.baseUnit.id) {
                        value = Number(balance.bal);
                    }
                    else {
                        let baseUnitTicker = this.baseUnitTickers[balance.ccy + '-' + this.baseUnit.id];
                        value = baseUnitTicker ? Number(balance.bal) * Number(baseUnitTicker.idxPx) : 0;
                    }
                    totalFundingValue += value;
                    fundingAssetValueList.push({
                        ccy: balance.ccy,
                        ccyLogo: balance.ccyLogo,
                        value: value
                    });
                }
                this.totalFundingValue = totalFundingValue;
                this.fundingAssetValueList = fundingAssetValueList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        accountBalance () {
            this.api.accountBalance(this, null, function (rspBody) {
                let totalTradingValue = 0;
                let tradingAssetValueList = [];
                for (let i = 0; i < rspBody.balance.details.length; i++) {
                    let detail = rspBody.balance.details[i];
                    let value = 0;
                    if (detail.ccy === this.baseUnit.id) {
                        value = Number(detail.eq);
                    }
                    else {
                        let baseUnitTicker = this.baseUnitTickers[detail.ccy + '-' + this.baseUnit.id];
                        value = baseUnitTicker ? Number(detail.eq) * Number(baseUnitTicker.idxPx) : 0;
                    }
                    totalTradingValue += value;
                    tradingAssetValueList.push({
                        ccy: detail.ccy,
                        ccyLogo: detail.ccyLogo,
                        value: value
                    });
                }
                this.totalTradingValue = totalTradingValue;
                this.tradingAssetValueList = tradingAssetValueList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.overview > .account {
    padding: 16px 92px 16px 92px;
    border: 1px solid var(--sep-color);
}
.overview > .account .avatar {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--background-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.overview > .account .avatar .icon {
    width: 24px;
    height: 24px;
}
.overview > .account .nickname .label {
    line-height: 20px;
}
.overview > .account .nickname .value {
    color: var(--text-strong);
    font-size: 24px;
}
.overview > .account .fields {
    margin-top: 16px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
}
.overview > .account .fields .value {
    color: var(--text-strong);
    font-size: 16px;
}

.side-left {
    flex: 1;
    margin-right: 24px;
}
.side-right {
    width: 30%;
}

.border-box {
    border: 1px solid var(--sep-color);
    border-radius: 2px;
}
.border-box-title {
    height: 64px;
    padding: 0 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--sep-color);
}
.border-box-panel {
    padding: 24px 24px 36px 24px;
}

.row .col .text.number {
    text-align: right;
}

.balance .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 8px;
}
.balance .amount {
    display: flex;
    justify-content: space-between;
}
.balance .amount .value {
    display: flex;
    align-items: center;
}
.balance .amount .value .text {
    font-size: 30px;
    margin-right: 16px;
}
.balance .amount .actions {
    display: flex;
    align-items: center;
}
.balance .amount .actions .action {
    width: 76px;
    height: 32px;
    line-height: 30px;
    margin-right: 16px;
    text-align: center;
    border: 1px solid var(--sep-color);
    border-radius: 16px;
    cursor: pointer;
}
.balance .amount .actions .action:hover {
    color: var(--text-strong-ant);
    background-color: var(--text-strong);
    border-color: var(--text-strong);
}

.opers {
    text-align: right;
}
.opers .oper {
    display: inline-block;
    width: 76px;
    height: 28px;
    line-height: 26px;
    margin-right: 8px;
    text-align: center;
    border: 1px solid var(--sep-color);
    border-radius: 14px;
    cursor: pointer;
}
.opers .oper:hover {
    color: var(--text-strong-ant);
    background-color: var(--text-strong);
    border-color: var(--text-strong);
}

.asset-overview {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
}

.asset-overview .account-detail {
    margin-top: 24px;
}
.asset-overview .account-detail .panel {
    padding-bottom: 36px;
}
.asset-overview .account-detail .panel .group {
    padding-top: 12px;
}
.asset-overview .account-detail .panel .group .name {
    height: 40px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
}
.asset-overview .account-detail .panel .group .rows {
    padding: 0 24px;
}
.asset-overview .account-detail .panel .group .row {
    height: 64px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.asset-overview .account-detail .panel .group .row:hover {
    background-color: var(--background-hover);
}
.asset-overview .account-detail .panel .group .row .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}
.asset-overview .account-detail .panel .group .row .account {
    width: 20%;
    padding-left: 8px;
    display: flex;
    align-items: center;
}
.asset-overview .account-detail .panel .group .row .account .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: var(--background-inactive);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
}
.asset-overview .account-detail .panel .group .row .account .icon.cash {
    background-image: url(./assets/img/wallet-cash.png);
}
.asset-overview .account-detail .panel .group .row .account .icon.trade {
    background-image: url(./assets/img/wallet-trade.png);
}
.asset-overview .account-detail .panel .group .row .amount {
    width: 20%;
}
.asset-overview .account-detail .panel .group .row .rate {
    width: 20%;
}
.asset-overview .account-detail .panel .group .row .opers {
    width: 40%;
    padding-left: 24px;
}

.asset-overview .currency-detail .panel .group {
    padding-top: 12px;
}
.asset-overview .currency-detail .panel .group .name {
    height: 40px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
}
.asset-overview .currency-detail .panel .group .row {
    height: 64px;
    padding: 0 24px;
    display: flex;
    align-items: center;
}
.asset-overview .currency-detail .panel .group .row:hover {
    background-color: var(--background-hover);
}
.asset-overview .currency-detail .panel .group .row .currency {
    width: 40%;
    display: flex;
    align-items: center;
}
.asset-overview .currency-detail .panel .group .row .currency .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}
.asset-overview .currency-detail .panel .group .row .amount {
    width: 60%;
}
.asset-overview .currency-detail .panel .group .more {
    margin-bottom: 16px;
    padding: 0 24px;
    text-align: right;
}
.asset-overview .currency-detail .panel .group .more .button {
    display: inline;
    color: var(--text-active);
}
.asset-overview .currency-detail .panel .empty {
    min-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>