import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from "vue-i18n";

import en_us from "@/assets/res/en_us";
import zh_cn from "@/assets/res/zh_cn";

import "@/assets/css/abs.css";
import abs from "@/assets/js/abs";
import api from "@/assets/js/api";
import websocket from "@/assets/js/websocket";
import MsgAlert from "@/components/MsgAlert";
import MsgConfirm from "@/components/MsgConfirm";
import TopBar from "@/components/TopBar";
import BottomBar from "@/components/BottomBar";

import App from './App.vue'
import HomeIndex from "@/HomeIndex";
import HomeRegister from "@/HomeRegister";
import HomeLogin from "@/HomeLogin";
import MarketBase from "@/MarketBase";
import SpotBase from "@/SpotBase";
import SpotMargin from "@/SpotMargin";
import SpotConvert from "@/SpotConvert";
import ContractSwap from "@/ContractSwap";
import ContractFutures from "@/ContractFutures";
import ContractOption from "@/ContractOption";
import UserAsset from "@/UserAsset";
import UserAccount from "@/UserAccount";
import MsgToast from "@/components/MsgToast";

const router = createRouter({
    history: createWebHistory(),
    routes: [{
        path: abs.routes.HOME_INDEX,
        component: HomeIndex
    }, {
        path: abs.routes.HOME_REGISTER,
        component: HomeRegister
    }, {
        path: abs.routes.HOME_LOGIN + '/:redirect?',
        component: HomeLogin
    }, {
        path: abs.routes.MARKET_BASE,
        component: MarketBase
    }, {
        path: abs.routes.SPOT_BASE + '/:inst?',
        component: SpotBase
    }, {
        path: abs.routes.SPOT_MARGIN + '/:inst?',
        component: SpotMargin
    }, {
        path: abs.routes.SPOT_CONVERT,
        component: SpotConvert
    }, {
        path: abs.routes.CONTRACT_SWAP + '/:inst?',
        component: ContractSwap
    }, {
        path: abs.routes.CONTRACT_FUTURES + '/:inst?',
        component: ContractFutures
    }, {
        path: abs.routes.CONTRACT_OPTION,
        component: ContractOption
    }, {
        path: abs.routes.USER_ASSET_MODULE,
        component: UserAsset
    }, {
        path: abs.routes.USER_ACCOUNT_MODULE,
        component: UserAccount
    }]
});

const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'en-US',
    datetimeFormats: {
        'en-US': {
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
            short: {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            },
            year: {
                year: 'numeric'
            },
            year_month: {
                year: 'numeric',
                month: 'short'
            },
            month_date: {
                month: 'short',
                day: 'numeric'
            },
            long_month: {
                month: 'long'
            },
            short_month: {
                month: 'short'
            },
            long_time: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
            short_time: {
                hour: 'numeric',
                minute: 'numeric'
            },
            long_weekday: {
                weekday: 'long'
            },
            short_weekday: {
                weekday: 'short'
            }
        },
        'zh-CN': {
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
            short: {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            },
            year: {
                year: 'numeric'
            },
            year_month: {
                year: 'numeric',
                month: 'short'
            },
            month_date: {
                month: 'short',
                day: 'numeric'
            },
            long_month: {
                month: 'long'
            },
            short_month: {
                month: 'long'
            },
            long_time: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            },
            short_time: {
                hour: 'numeric',
                minute: 'numeric'
            },
            long_weekday: {
                weekday: 'long'
            },
            short_weekday: {
                weekday: 'short'
            }
        }
    },
    messages: {
        'en-US': en_us,
        'zh-CN': zh_cn
    }
});

const app = createApp(App)
app.use(router)
app.use(i18n)
app.config.globalProperties.abs = abs;
app.config.globalProperties.api = api;
app.config.globalProperties.websocket = websocket;
app.component('MsgAlert', MsgAlert);
app.component('MsgConfirm', MsgConfirm);
app.component('MsgToast', MsgToast);
app.component('TopBar', TopBar);
app.component('BottomBar', BottomBar);
app.mount('#app')
