<template>
<div class="change-email">
    <div class="crumbs">
        <div class="crumb clickable" v-text="$t('account.security.security')"></div>
        <i class="icon chevron-right"></i>
        <div class="crumb" v-text="$t('account.change_email.title')"></div>
    </div>
    <div class="title" v-text="$t('account.change_email.title')"></div>
    <div class="fields">
        <div class="field">
            <div class="label" v-text="$t('account.change_email.new_email')"></div>
            <div class="value"><label class="holder"><input class="text" type="text" v-model="email" :placeholder="$t('account.change_email.new_email_holder')" /></label></div>
        </div>
        <div class="field">
            <div class="label" v-text="$t('account.change_email.new_email_code')"></div>
            <div class="value combine"><label class="holder"><input class="text" type="text" v-model="code" :placeholder="$t('account.change_email.new_email_code_holder')" /><a class="button link-button" v-if="codeTicker<=0" @click="clickSendCode" v-text="$t('account.change_email.send_code')"></a><span class="timer" v-if="codeTicker>0" v-text="codeTicker + 's'"></span></label></div>
        </div>
        <div class="field" v-if="userEmail">
            <div class="label" v-text="$t('account.change_email.email_code')"></div>
            <div class="value combine"><label class="holder"><input class="text" type="text" v-model="emailCode" :placeholder="$t('account.change_email.email_code_holder')" /><a class="button link-button" v-if="emailTicker<=0" @click="clickSendEmailCode" v-text="$t('account.change_email.send_code')"></a><span class="timer" v-if="emailTicker>0" v-text="emailTicker + 's'"></span></label></div>
        </div>
        <div class="field" v-if="userPhone">
            <div class="label" v-text="$t('account.change_email.sms_code')"></div>
            <div class="value combine"><label class="holder"><input class="text" type="text" v-model="phoneCode" :placeholder="$t('account.change_email.sms_code_holder')" /><a class="button link-button" v-if="phoneTicker<=0" @click="clickSendPhoneCode" v-text="$t('account.change_email.send_code')"></a><span class="timer" v-if="phoneTicker>0" v-text="phoneTicker + 's'"></span></label></div>
        </div>
    </div>
    <div class="notice"><checkbox-field :label="$t('account.change_email.notice')" @changeValue="changeNotice"></checkbox-field></div>
    <div class="actions">
        <a class="button primary-button" :class="{'disabled':disabled}" @click="clickConfirm" v-text="$t('account.change_email.confirm')"></a>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import CheckboxField from "@/components/CheckboxField";
export default {
    name: "UserAccountChangeEmail",
    components: {CheckboxField},
    data () {
        return {
            userEmail: '',
            userPhone: '',
            email: '',
            code: '',
            emailCode: '',
            phoneCode: '',
            codeTimer: null,
            emailCodeTimer: null,
            phoneCodeTimer: null,
            codeTicker: 0,
            emailTicker: 0,
            phoneTicker: 0,
            tickerLimit: 60,
            notice: false
        };
    },
    computed: {
        disabled () {
            return !this.email || !this.code ||
                (this.userEmail && !this.emailCode) || (this.userPhone && !this.phoneCode) || !this.notice;
        }
    },
    mounted () {
        this.api.account(this, null, function (rspBody) {
            this.userEmail = rspBody.user.email;
            this.userPhone = rspBody.user.phone;
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
    },
    methods: {
        clickSendCode () {
            if (this.codeTimer || this.codeTicker > 0) {
                return;
            }
            this.api.vcode(this, {
                account: this.email,
                vcodeScene: this.abs.account.EVcodeScene.RESET_EMAIL,
                accountScene: this.abs.account.EAccountScene.EMAIL
            }, function () {
                this.codeTicker = this.tickerLimit;
                let _this = this;
                this.codeTimer = setInterval(() => {
                    _this.codeTicker--;
                    if (_this.codeTicker <= 0) {
                        clearInterval(_this.codeTimer);
                        _this.codeTimer = null;
                    }
                }, 1000);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        clickSendEmailCode () {
            if (this.emailCodeTimer || this.emailTicker > 0) {
                return;
            }
            this.api.vercode(this, {
                vcodeScene: this.abs.account.EVcodeScene.RESET_EMAIL,
                accountScene: this.abs.account.EAccountScene.INNER_EMAIL
            }, function () {
                this.emailTicker = this.tickerLimit;
                let _this = this;
                this.emailCodeTimer = setInterval(() => {
                    _this.emailTicker--;
                    if (_this.emailTicker <= 0) {
                        clearInterval(_this.emailCodeTimer);
                        _this.emailCodeTimer = null;
                    }
                }, 1000);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        clickSendPhoneCode () {
            if (this.phoneCodeTimer || this.phoneTicker > 0) {
                return;
            }
            this.api.vercode(this, {
                vcodeScene: this.abs.account.EVcodeScene.RESET_EMAIL,
                accountScene: this.abs.account.EAccountScene.INNER_PHONE
            }, function () {
                this.phoneTicker = this.tickerLimit;
                let _this = this;
                this.phoneCodeTimer = setInterval(() => {
                    _this.phoneTicker--;
                    if (_this.phoneTicker <= 0) {
                        clearInterval(_this.phoneCodeTimer);
                        _this.phoneCodeTimer = null;
                    }
                }, 1000);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        changeNotice (notice) {
            this.notice = notice;
        },
        clickConfirm () {
            if (this.disabled) {
                return;
            }
            this.api.bindEmail(this, {
                email: this.email,
                code: this.code,
                phoneCode: this.phoneCode,
                emailCode: this.emailCode
            }, function () {
                this.email = '';
                this.code = '';
                this.emailCode = '';
                this.phoneCode = '';
                this.abs.Toast.show(this.$t('account.change_email.bind_email_successfully'));
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.change-email {
    width: 600px;
}
.crumbs {
    display: flex;
    align-items: center;
}
.crumbs .crumb {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}
.crumbs .crumb.clickable {
    color: var(--text-strong);
    cursor: pointer;
}
.crumbs .icon {
    width: 12px;
    height: 12px;
    margin: 0 8px;
}
.title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    padding: 16px 0;
}
.fields .field {
    margin-bottom: 16px;
}
.fields .field .label {
    line-height: 20px;
    margin-bottom: 4px;
}
.fields .field .value .holder {
    display: flex;
    align-items: center;
}
.fields .field .value.combine .holder .text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.fields .field .value.combine .holder .button {
    width: 100px;
    height: 40px;
    flex-shrink: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: var(--background-tertiary);
}
.fields .field .value.combine .holder .timer {
    width: 60px;
    height: 40px;
    flex-shrink: 0;
    color: var(--text-muted);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: var(--background-tertiary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.notice {
    margin: 32px 0;
}
.actions .button {
    width: 200px;
    height: 40px;
    border-radius: 20px;
}
</style>