let _onok = null;
let _onyes = null;
let _onno = null;
let _toastTimer = null;

export default {
    routes: {
        HOME_INDEX: '/',
        HOME_REGISTER: '/register',
        HOME_LOGIN: '/login',
        WALLET_BASE: '/wallet',
        SPOT_BASE: '/spot',
        SPOT_MARGIN: '/margin',
        SPOT_CONVERT: '/convert',
        CONTRACT_SWAP: '/swap',
        CONTRACT_FUTURES: '/futures',
        CONTRACT_OPTION: '/option',
        MARKET_BASE: '/market',
        USER_ASSET: '/asset',
        USER_ASSET_MODULE: '/asset/:module?/:section?',
        USER_ASSET_MODULE_OVERVIEW: 'overview',
        USER_ASSET_MODULE_CASH: 'cash',
        USER_ASSET_MODULE_TRADE: 'trade',
        USER_ASSET_MODULE_ANALYSIS: 'analysis',
        USER_ASSET_MODULE_ORDER: 'order',
        USER_ASSET_MODULE_ORDER_PENDING: 'pending',
        USER_ASSET_MODULE_ORDER_HISTORY: 'history',
        USER_ASSET_MODULE_ORDER_FUNDING: 'funding',
        USER_ASSET_MODULE_FEE: 'fee',
        USER_ASSET_MODULE_DEPOSIT: 'deposit',
        USER_ASSET_MODULE_WITHDRAW: 'withdraw',
        USER_ASSET_MODULE_TRANSFER: 'transfer',
        USER_ACCOUNT: '/account',
        USER_ACCOUNT_MODULE: '/account/:module?/:section?',
        USER_ACCOUNT_MODULE_OVERVIEW: 'overview',
        USER_ACCOUNT_MODULE_SECURITY: 'security',
        USER_ACCOUNT_MODULE_PREFERENCE: 'preference',
        USER_ACCOUNT_MODULE_PASSWORD: 'password',
        USER_ACCOUNT_MODULE_PHONE: 'phone',
        USER_ACCOUNT_MODULE_EMAIL: 'email'
    },
    base: {
        EBoolean: {
            YES: 1,
            NO: 0
        },
        ELanguage: {
            EN_US: {
                code: 'en-US',
                name: 'English, US'
            },
            ZH_CN: {
                code: 'zh-CN',
                name: '简体中文'
            }
        },
        EDirection: {
            TOP: 'top',
            MIDDLE: 'middle',
            BOTTOM: 'bottom',
            LEFT: 'left',
            CENTER: 'center',
            RIGHT: 'right'
        }
    },
    common: {
        EInstrumentType: {
            SPOT: 'SPOT',
            MARGIN: 'MARGIN',
            SWAP: 'SWAP',
            FUTURES: 'FUTURES',
            OPTION: 'OPTION',
            ANY: 'ANY'
        },
        EOrderSide: {
            BUY: 'buy',
            SELL: 'sell'
        },
        EOrderType: {
            LIMIT: 'limit',
            MARKET: 'market',
            POST_ONLY: 'post_only',
            FOK: 'fok',
            IOC: 'ioc',
            OPTIMAL_LIMIT_IOC: 'optimal_limit_ioc'
        },
        EOrderState: {
            LIVE: 'live',
            PARTIALLY: 'partially_filled',
            FILLED: 'filled',
            CANCELED: 'canceled'
        },
        ECashBillTypes: {
            '1': 'deposit',
            '2': 'withdraw',
            '130': 'transfer_in',
            '131': 'transfer_out'
        },
        ETradeBillTypes: {
            '1': 'transfer',
            '2': 'trade'
        },
        EIndexMode: {
            REGULAR: 'regular',
            REVERSE: 'reverse'
        }
    },
    account: {
        EPlatform: {
            WEB: 'web',
            H5: 'h5',
            IOS: 'ios',
            ADR: 'adr'
        },
        EAccountScene: {
            EMAIL: 1,
            PHONE: 2,
            INNER_EMAIL: 3,
            INNER_PHONE: 4
        },
        EVcodeScene: {
            REGISTER: 1,
            LOGIN: 2,
            RESET_PASSWORD: 3,
            RESET_EMAIL: 4,
            RESET_PHONE: 5
        }
    },
    wallet: {
        EWalletType: {
            FUNDING: '6',
            TRADING: '18'
        },
        EWalletAction: {
            DEPOSIT: 'deposit',
            WITHDRAW: 'withdraw',
            TRANSFER: 'transfer'
        },
        EDepositStatus: {
            '0': 'waiting_for_confirmation',
            '1': 'deposit_credited',
            '2': 'deposit_successful',
            '8': 'pending_due_to_temporary_deposit_suspension_on_this_crypto_currency',
            '11': 'match_the_address_blacklist',
            '12': 'account_or_deposit_is_frozen',
            '13': 'sub-account_deposit_interception',
            '14': 'KYC_limit'
        },
        EWithdrawType: {
            INTERNAL: '3',
            CHAIN: '4'
        },
        EWithdrawStatus: {
            '-3': 'canceling',
            '-2': 'canceled',
            '-1': 'failed',
            '0': 'waiting_withdrawal',
            '1': 'withdrawing',
            '2': 'withdraw_success',
            '7': 'approved',
            '10': 'waiting_transfer',
            '4': 'waiting_mannual_review',
            '5': 'waiting_mannual_review',
            '6': 'waiting_mannual_review',
            '8': 'waiting_mannual_review',
            '9': 'waiting_mannual_review',
            '12': 'waiting_mannual_review'
        },
        ETransferStatus: {
            'success': 'success',
            'pending': 'pending',
            'failed': 'failed'
        }
    },
    Toast: new function () {
        this.show = function (content, timeout) {
            let toast = document.querySelector('.msg-toast');
            toast.querySelector('.content .text').innerText = content;
            let classList = toast.className.split(' ');
            classList.push('show');
            toast.className = classList.join(' ');

            if (_toastTimer) {
                clearTimeout(_toastTimer);
                _toastTimer = null;
            }
            _toastTimer = setTimeout(() => {
                let classList = toast.className.split(' ');
                for (let i = 0; i < classList.length; i++) {
                    if (classList[i].trim() === 'show') {
                        classList.splice(i, 1);
                        break;
                    }
                }
                toast.className = classList.join(' ');
                _toastTimer = null;
            }, (timeout || 5000));
        };
        this.alert = function (content) {
            let toast = document.querySelector('.msg-toast');
            toast.querySelector('.content .text').innerText = content;
            let classList = toast.className.split(' ');
            classList.push('show');
            toast.className = classList.join(' ');

            if (_toastTimer) {
                clearTimeout(_toastTimer);
                _toastTimer = null;
            }
        };
    },
    MsgBox: new function () {
        this.alert = function (content, onok) {
            _onok = onok || null;
            document.querySelector('.msg-box.alert .content .msg .body').innerText = content;
            document.querySelector('.msg-box.alert').classList.add('show');
        };
        this.ok = function () {
            document.querySelector('.msg-box.alert').classList.remove('show');
            _onok && _onok();
            _onok = null;
        };
        this.confirm = function (content, onyes, onno) {
            _onyes = onyes || null;
            _onno = onno || null;
            document.querySelector('.msg-box.confirm .content .msg .body').innerText = content;
            document.querySelector('.msg-box.confirm').classList.add('show');
        };
        this.yes = function () {
            document.querySelector('.msg-box.confirm').classList.remove('show');
            _onyes && _onyes();
            _onyes = null;
            _onno = null;
        };
        this.no = function () {
            document.querySelector('.msg-box.confirm').classList.remove('show');
            _onno && _onno();
            _onyes = null;
            _onno = null;
        };
    },
    Store: {
        set (key, value) {
            localStorage.setItem(key, value);
        },
        get (key) {
            return localStorage.getItem(key);
        },
        client (client) {
            if (client === undefined) {
                return this.get('client');
            }
            else {
                this.set('client', client);
            }
        },
        locale (locale) {
            if (locale === undefined) {
                return this.get('locale') || 'en-US';
            }
            else {
                this.set('locale', locale);
            }
        },
        uticket (uticket) {
            if (uticket === undefined) {
                return localStorage.getItem('uticket');
            }
            else {
                localStorage.setItem('uticket', uticket);
            }
        }
    },
    Cookie: {
        set (key, value, seconds) {
            if (seconds) {
                let expires = new Date();
                expires.setTime(expires.getTime() + seconds * 1000);
                document.cookie = key + '=' + escape(value) + ';expires=' + expires.toGMTString() + ';path=/';
            }
            else {
                document.cookie = key + '=' + escape(value) + ';path=/';
            }
        },
        get (key) {
            key = key + '=';
            let ks = document.cookie.indexOf(key);
            while (ks >= 0) {
                let vs = ks + key.length;
                let ve = document.cookie.indexOf(';', vs);
                if (ve < 0) {
                    ve = document.cookie.length;
                }
                let value = unescape(document.cookie.substring(vs, ve));
                if (value) {
                    return value;
                }
                ks = document.cookie.indexOf(key, ve);
            }
            return null;
        }
    },
    Url: {
        encode (text) {
            return text ? encodeURIComponent(text) : '';
        },
        decode (text) {
            return text ? decodeURIComponent(text) : '';
        }
    },
    Dom: new function () {
        this.addClass = function (el, clazz) {
            if (!clazz) {
                return;
            }
            let classList = el.className.split(' ');
            classList.push(clazz);
            el.className = classList.join(' ');
        };
        this.removeClass = function (el, clazz) {
            if (!clazz) {
                return;
            }
            let classList = el.className.split(' ');
            for (let i = 0; i < classList.length; i++) {
                if (classList[i].trim() === clazz) {
                    classList.splice(i, 1);
                    break;
                }
            }
            el.className = classList.join(' ');
        };
        this.replaceClass = function (el, oldClazz, newClazz) {
            this.removeClass(el, oldClazz);
            this.addClass(el, newClazz);
        };
    },
    Util: {
        inputNumberBefore (e, valueNumber, valueText, lotSize, minSize) {
            let c = e.key;
            if (c !== '0' && c !== '1' && c !== '2' && c !== '3' && c !== '4'
                && c !== '5' && c !== '6' && c !== '7' && c !== '8' && c !== '9'
                && c !== '.' && c !== 'Backspace' && c !== 'ArrowLeft' && c !== 'ArrowRight') {
                return false;
            }
            if (c === 'ArrowLeft' || c === 'ArrowRight') {
                return true;
            }
            let start = e.target.selectionStart;
            let end = e.target.selectionEnd;
            if (c === 'Backspace') {
                if (start === 0 && end === 0) {
                    return true;
                }
                if (start === end) {
                    start -= 1;
                }
            }
            let vtext = valueText.substring(0, start) + (c === 'Backspace' ? '' : c) + valueText.substring(end);
            vtext = vtext.replace(/,/g, '');
            if (vtext.startsWith('.')) {
                vtext = '0' + vtext;
            }
            if (vtext.startsWith('0') && vtext.length > 1 && !vtext.startsWith('0.')) {
                return false;
            }
            if (vtext.indexOf('.') !== vtext.lastIndexOf('.')) {
                return false;
            }
            if (vtext.endsWith('.')) {
                vtext = vtext.substring(0, vtext.length - 1);
            }
            let vnumber = Number(vtext);
            if (isNaN(vnumber)) {
                return false;
            }
            let index = vtext.indexOf('.');
            if (index > 0 && vtext.length - index - 1 >= minSize && !vnumber) {
                return false;
            }
            return !(index > 0 && vtext.length - index - 1 > lotSize);
        },
        inputNumberAfter (valueText) {
            if (!valueText) {
                return {
                    valueNumber: 0,
                    valueText: ''
                };
            }
            valueText = valueText.replace(/,/g, '');
            if (valueText.startsWith('.')) {
                valueText = '0' + valueText;
            }
            let valueNumber = Number(valueText);
            let parts = valueText.split('.');
            valueText = parts[0].replace(/\d(?=(?:\d{3})+\b)/g, '$&,') + (parts.length > 1 ? '.' + parts[1] : '');
            return {
                valueNumber: valueNumber,
                valueText: valueText
            };
        },
        formatNumber (number, bits) {
            number = Number(number) || 0;
            let unit = '';
            if (number > 10000000000) {
                number = Math.floor(number / 10) / 100;
                unit = 'K';
                if (number > 10000000000) {
                    number = Math.floor(number / 10) / 100;
                    unit = 'M';
                    if (number > 10000000000) {
                        number = Math.floor(number / 100) / 100;
                        unit = 'B';
                    }
                }
            }
            if (bits === 0 || bits > 0) {
                number = number * Math.pow(10, bits);
                number = Math.round(number);
                number = number / Math.pow(10, bits);
                number = number.toFixed(bits);
            }
            else {
                let match = number.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
                number = number.toFixed(Math.max(0, (match[1] || '').length - match[2]));
            }
            let parts = number.split('.');
            return parts[0].replace(/\d(?=(?:\d{3})+\b)/g, '$&,') + (parts.length > 1 ? '.' + parts[1] : '') + unit;
        },
        round (number, bits) {
            number = Number(number) || 0;
            let power = Math.pow(10, bits);
            return Math.round(number * power) / power;
        },
        floor (number, bits) {
            number = Number(number) || 0;
            let power = Math.pow(10, bits);
            return Math.floor(number * power) / power;
        },
        ceil (number, bits) {
            number = Number(number) || 0;
            let power = Math.pow(10, bits);
            return Math.ceil(number * power) / power;
        }
    },
    Hexer: {
        encode (text) {
            let utf8 = [];
            for (let i = 0; i < text.length; i++) {
                let charcode = text.charCodeAt(i);
                if (charcode < 0x80) {
                    utf8.push(charcode);
                }
                else if (charcode < 0x800) {
                    utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
                }
                else if (charcode < 0xd800 || charcode >= 0xe000) {
                    utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode>>6) & 0x3f), 0x80 | (charcode & 0x3f));
                }
                else {
                    i++;
                    charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (text.charCodeAt(i) & 0x3ff));
                    utf8.push(0xf0 | (charcode >>18), 0x80 | ((charcode>>12) & 0x3f), 0x80 | ((charcode>>6) & 0x3f), 0x80 | (charcode & 0x3f));
                }
            }
            let hex = utf8.map(n => n.toString(16));
            return '0x' + hex.join('');
        }
    }
}