<template>
<div class="order-books">
    <div class="title">
        <div class="styles">
            <div class="style center" :class="{'active':style===STYLES.CENTER}" @click="clickStyle(STYLES.CENTER)"></div>
            <div class="style bottom" :class="{'active':style===STYLES.BOTTOM}" @click="clickStyle(STYLES.BOTTOM)"></div>
            <div class="style top" :class="{'active':style===STYLES.TOP}" @click="clickStyle(STYLES.TOP)"></div>
        </div>
    </div>
    <div class="panel">
        <div class="head">
            <div class="row">
                <div class="col col-price" v-text="$t('component.order_books.price') + (inst.quoteCcy ? ' (' + inst.quoteCcy + ')' : '')"></div>
                <div class="col col-count" v-text="$t('component.order_books.amount') + (inst.quoteCcy ? ' (' + inst.baseCcy + ')' : '')"></div>
                <div class="col col-amount" v-text="$t('component.order_books.total') + (inst.quoteCcy ? ' (' + inst.baseCcy + ')' : '')"></div>
            </div>
        </div>
        <div class="body" :class="{'top':style===STYLES.TOP,'bottom':style===STYLES.BOTTOM}">
            <div class="sell-list hidden-scroll" @scroll="scrollSellList">
                <div class="row" v-for="ask in books.asks" :key="ask.price">
                    <div class="col col-price index-down" v-text="abs.Util.formatNumber(ask.price, inst.tickSize)"></div>
                    <div class="col col-count" v-text="abs.Util.formatNumber(ask.amount, inst.lotSize)"></div>
                    <div class="col col-amount" v-text="abs.Util.formatNumber(ask.total, 3)"></div>
                    <div class="count-mask" :style="'left:' + (100 - ask.amount / books.maxAsk * 100) + '%'"></div>
                </div>
            </div>
            <div class="current">
                <div class="cur-price" :class="{'index-up':updown>=0,'index-down':updown<0}" v-text="abs.Util.formatNumber(last, inst.tickSize)"></div>
                <i class="icon" :class="{'arrow-up':updown>0,'arrow-down':updown<0,'background-index-up':updown>0,'background-index-down':updown<0}" v-if="updown!==0"></i>
                <div class="cur-price-usd" v-text="'$' + abs.Util.formatNumber(last * inst.quoteIndex, 2)"></div>
            </div>
            <div class="buy-list hidden-scroll" @scroll="scrollBuyList">
                <div class="row" v-for="bid in books.bids" :key="bid.price">
                    <div class="col col-price index-up" v-text="abs.Util.formatNumber(bid.price, inst.tickSize)"></div>
                    <div class="col col-count" v-text="abs.Util.formatNumber(bid.amount, inst.lotSize)"></div>
                    <div class="col col-amount" v-text="abs.Util.formatNumber(bid.total, 3)"></div>
                    <div class="count-mask" :style="'left:' + (100 - bid.amount / books.maxBid * 100) + '%'"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "OrderBooks",
    props: {
        inst: {
            type: Object,
            default: () => {
                return {
                    instId: '',
                    instType: '',
                    baseCcy: '',
                    quoteCcy: '',
                    baseIndex: 0,
                    quoteIndex: 0,
                    tickSize: 0,
                    lotSize: 0
                };
            }
        }
    },
    data () {
        return {
            STYLES: {
                CENTER: 'center',
                TOP: 'top',
                BOTTOM: 'bottom'
            },
            BOOK_SIZE: 50,
            style: 'center',
            last: 0,
            updown: 0,
            books: {
                asks: [],
                bids: [],
                as: [],
                bs: [],
                maxAsk: 0,
                maxBid: 0,
                askClientHeight: 0,
                askScrollHeight: 0,
                askScrollTop: 0,
                bidClientHeight: 0,
                bidScrollHeight: 0,
                bidScrollTop: 0
            }
        };
    },
    watch: {
        //*/
        'inst.instId' (newInstId, oldInstId) {
            if (oldInstId) {
                this.websocket.unsubscribe({
                    'channel': 'tickers',
                    'instId': oldInstId
                }, 'order-books');
                this.websocket.unsubscribe({
                    'channel': 'books',
                    'instId': oldInstId
                }, 'order-books');
            }

            this.last = 0;
            this.updown = 0;
            this.books.asks = [];
            this.books.bids = [];
            this.books.as = [];
            this.books.bs = [];
            this.maxAsk = 0;
            this.maxBid = 0;

            let _this = this;
            this.websocket.subscribe({
                'channel': 'tickers',
                'instId': newInstId
            }, 'order-books', function (result) {
                let ticker = result.data[0];
                if (ticker.instId !== _this.inst.instId) {
                    return;
                }
                _this.updown = (Number(ticker.last) - Number(ticker.open24h)) / Number(ticker.open24h) * 100;
                _this.last = Number(ticker.last);
            });
            this.websocket.subscribe({
                'channel': 'books',
                'instId': newInstId
            }, 'order-books', function (result) {
                let askList = result.data[0].asks;//低-高
                let bidList = result.data[0].bids;//高-低
                if (result.action === 'snapshot') {
                    let maxAskAmount = 0;
                    let totalAskAmount = 0;
                    let asks = [];
                    let as = [];
                    for (let i = 0; i < askList.length && i < _this.BOOK_SIZE; i++) {
                        let item = askList[i];
                        let amount = Number(item[1]);
                        maxAskAmount = amount > maxAskAmount ? amount : maxAskAmount;
                        totalAskAmount += amount;
                        let ask = {
                            price: item[0],
                            amount: amount,
                            total: totalAskAmount
                        };
                        asks.push(ask);
                        as[ask.price] = ask;
                    }

                    let maxBidAmount = 0;
                    let totalBidAmount = 0;
                    let bids = [];
                    let bs = [];
                    for (let i = 0; i < bidList.length && i < _this.BOOK_SIZE; i++) {
                        let item = bidList[i];
                        let amount = Number(item[1]);
                        maxBidAmount = amount > maxBidAmount ? amount : maxBidAmount;
                        totalBidAmount += amount;
                        let bid = {
                            price: item[0],
                            amount: amount,
                            total: totalBidAmount
                        };
                        bids.push(bid);
                        bs[bid.price] = bid;
                    }

                    _this.books.asks = asks.reverse();//高-低
                    _this.books.bids = bids;//高-低
                    _this.books.as = as;
                    _this.books.bs = bs;
                    _this.maxAsk = maxAskAmount;
                    _this.maxBid = maxBidAmount;

                    _this.$nextTick(() => {
                        if (_this.style === _this.STYLES.BOTTOM) {
                            document.querySelector('.panel .body .buy-list').scrollTop = _this.books.bidScrollTop;
                        } else if (_this.style === _this.STYLES.TOP) {
                            document.querySelector('.panel .body .sell-list').scrollTop = _this.books.askScrollTop;
                        }
                    });
                }
                else if (result.action === 'update') {
                    //更新asks
                    let newAsk = false;
                    for (let i = 0; i < askList.length; i++) {
                        let item = askList[i];
                        let price = item[0];
                        let amount = Number(item[1]);
                        let a = _this.books.as[price];
                        if (a) {
                            if (amount) {
                                a.amount = amount;
                            }
                            else {
                                _this.books.asks.splice(_this.books.asks.indexOf(a), 1);
                                delete _this.books.as[price];
                            }
                        }
                        else if (amount) {
                            let ask = {
                                price: price,
                                amount: amount,
                                total: 0
                            };
                            _this.books.asks.push(ask);
                            _this.books.as[ask.price] = ask;
                            newAsk = true;
                        }
                    }
                    //排序+截取
                    if (newAsk) {
                        _this.books.asks.sort((ask1, ask2) => {
                            if (ask1.price < ask2.price) {
                                return 1;
                            }
                            else if (ask1.price > ask2.price) {
                                return -1;
                            }
                            return 0;
                        });
                        if (_this.books.asks.length > _this.BOOK_SIZE) {
                            let removes = _this.books.asks.splice(0, _this.books.asks.length - _this.BOOK_SIZE);
                            for (let i = 0; i < removes.length; i++) {
                                delete _this.books.as[removes[i].price];
                            }
                        }
                    }
                    //计算合计+最大数量
                    let maxAskAmount = 0;
                    let totalAskAmount = 0;
                    for (let i = _this.books.asks.length - 1; i >= 0; i--) {
                        let ask = _this.books.asks[i];
                        maxAskAmount = ask.amount > maxAskAmount ? ask.amount : maxAskAmount;
                        totalAskAmount += ask.amount;
                        ask.total = totalAskAmount;
                    }
                    _this.books.maxAsk = maxAskAmount;

                    //更新bids
                    let newBid = false;
                    for (let i = 0; i < bidList.length; i++) {
                        let item = bidList[i];
                        let price = item[0];
                        let amount = Number(item[1]);
                        let b = _this.books.bs[price];
                        if (b) {
                            if (amount) {
                                b.amount = amount;
                            }
                            else {
                                _this.books.bids.splice(_this.books.bids.indexOf(b), 1);
                                delete _this.books.bs[price];
                            }
                        }
                        else if (amount) {
                            let bid = {
                                price: price,
                                amount: amount,
                                total: 0
                            };
                            _this.books.bids.push(bid);
                            _this.books.bs[bid.price] = bid;
                            newBid = true;
                        }
                    }
                    //排序+截取
                    if (newBid) {
                        _this.books.bids.sort((bid1, bid2) => {
                            if (bid1.price < bid2.price) {
                                return 1;
                            }
                            else if (bid1.price > bid2.price) {
                                return -1;
                            }
                            return 0;
                        });
                        if (_this.books.bids.length > _this.BOOK_SIZE) {
                            let removes = _this.books.bids.splice(_this.BOOK_SIZE);
                            for (let i = 0; i < removes.length; i++) {
                                delete _this.books.bs[removes[i].price];
                            }
                        }
                    }
                    //计算合计+最大数量
                    let maxBidAmount = 0;
                    let totalBidAmount = 0;
                    for (let i = 0; i < _this.books.bids.length; i++) {
                        let bid = _this.books.bids[i];
                        maxBidAmount = bid.amount > maxBidAmount ? bid.amount : maxBidAmount;
                        totalBidAmount += bid.amount;
                        bid.total = totalBidAmount;
                    }
                    _this.books.maxBid = maxBidAmount;

                    //定位
                    _this.$nextTick(() => {
                        if (_this.style === _this.STYLES.BOTTOM) {
                            document.querySelector('.panel .body .buy-list').scrollTop = _this.books.bidScrollTop;
                        } else if (_this.style === _this.STYLES.TOP) {
                            document.querySelector('.panel .body .sell-list').scrollTop = _this.books.askScrollTop;
                        }
                    });
                }
            });
        }
        //*/
    },
    methods: {
        clickStyle (style) {
            this.style = style;
            this.$nextTick(() => {
                let askList = document.querySelector('.panel .body .sell-list');
                this.books.askClientHeight = askList.clientHeight;
                this.books.askScrollHeight = askList.scrollHeight;
                this.books.askScrollTop = this.books.askScrollHeight - this.books.askClientHeight;
                let bidList = document.querySelector('.panel .body .buy-list');
                this.books.bidClientHeight = bidList.clientHeight;
                this.books.bidScrollHeight = bidList.scrollHeight;
                this.books.bidScrollTop = 0;

                if (this.style === this.STYLES.BOTTOM) {
                    document.querySelector('.panel .body .buy-list').scrollTop = this.books.bidScrollTop;
                }
                else if (this.style === this.STYLES.TOP) {
                    document.querySelector('.panel .body .sell-list').scrollTop = this.books.askScrollTop;
                }
            });
        },
        scrollSellList (e) {
            this.books.askScrollTop = e.target.scrollTop;
        },
        scrollBuyList (e) {
            this.books.bidScrollTop = e.target.scrollTop;
        }
    }
}
</script>

<style scoped>
.title {
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
}
.title .styles {
    flex: 1;
    display: flex;
}
.title .styles .style {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-image: url(../assets/img/bar.png);
    background-repeat: no-repeat;
    background-size: 251px 20px;
    cursor: pointer;
}
.title .styles .style.center {
    background-position: 0 0;
}
.title .styles .style.center.active {
    background-position: -21px 0;
}
.title .styles .style.bottom {
    background-position: -168px 0;
}
.title .styles .style.bottom.active {
    background-position: -189px 0;
}
.title .styles .style.top {
    background-position: -84px 0;
}
.title .styles .style.top.active {
    background-position: -105px 0;
}
.title .ladder {
    width: 60px;
}

.panel {
    position: absolute;
    width: 100%;
    top: 40px;
    bottom: 0;
    padding: 0 8px;
}
.panel .row {
    overflow-x: hidden;
    display: flex;
}
.panel .col {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
}
.panel .col.col-price {
    width: 30%;
}
.panel .col.col-count {
    width: 35%;
    text-align: right;
}
.panel .col.col-amount {
    width: 35%;
    text-align: right;
}
.panel .head {
    padding: 0 8px;
}
.panel .body {
    position: absolute;
    left: 8px;
    right: 8px;
    top: 20px;
    bottom: 16px;
    padding: 0 8px;
    overflow: hidden;
}
.panel .body .row {
    cursor: pointer;
    margin-bottom: 2px;
}
.panel .body .row:hover {
    background-color: var(--background-hover);
}
.panel .body .row .count-mask {
    position: absolute;
    width: 100%;
    height: 20px;
}
.panel .body .sell-list {
    position: absolute;
    bottom: 50%;
    left: 8px;
    right: 8px;
    margin-bottom: 16px;
    overflow-y: auto;
}
.panel .body.top .sell-list {
    top: 0;
    bottom: 32px;
    margin-bottom: 0;
}
.panel .body.bottom .sell-list {
    bottom: 100%;
    margin-bottom: 0;
}
.panel .body .sell-list .row .count-mask {
    background-color: rgba(246,70,93,0.1);
}
.panel .body .buy-list {
    position: absolute;
    top: 50%;
    left: 8px;
    right: 8px;
    margin-top: 16px;
    overflow-y: auto;
}
.panel .body.top .buy-list {
    top: 100%;
    margin-top: 0;
}
.panel .body.bottom .buy-list {
    top: 32px;
    bottom: 0;
    margin-top: 0;
}
.panel .body .buy-list .row .count-mask {
    background-color: rgba(14,203,129,0.1);
}
.panel .body .current {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -16px;
    height: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
}
.panel .body.top .current {
    top: 100%;
    margin-top: -32px;
}
.panel .body.bottom .current {
    top: 0;
    margin-top: 0;
}
.panel .body .current .cur-price {
    margin-right: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}
.panel .current .cur-price-usd {
    margin-left: 8px;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-secondary);
}
</style>