<template>
<div class="phone-field">
    <div class="phone-code">
        <div class="control" @click="clickPhoneCode">
            <div class="field" v-text="this.innerCode ? ('+' + this.innerCode) : '--'"></div><div class="field-tail"><i class="icon chevron-down small"></i></div>
        </div>
        <div class="countries" :class="{'show':showCountries}">
            <div class="mask" @click="clickCountriesMask"></div>
            <div class="filter"><label class="holder"><i class="icon search small"></i><input class="text" type="text" v-model="searchCountry" /></label></div>
            <div class="list small-scroll">
                <div class="item" v-for="country in countryList" :key="country.countryId" @click="clickCountryItem(country)"><div class="common-name" v-text="country.logo+country.commonName"></div><div class="code" v-text="'+'+country.phoneCode"></div></div>
            </div>
        </div>
    </div>
    <div class="phone-number">
        <label class="holder">
            <input class="text" type="text" :style="(width ? 'width:'+width+'px;' : '') + (height ? 'height:'+height+'px' : '')" v-model="innerNumber" :placeholder="placeholder" />
            <i class="icon times-circle" :class="{'show':innerNumber}" @click="clickClearPhoneNumber"></i>
        </label>
    </div>
</div>
</template>

<script>
export default {
    name: "PhoneField",
    props: {
        code: {
            type: String,
            default: () => {
                return '';
            }
        },
        number: {
            type: String,
            default: () => {
                return '';
            }
        },
        placeholder: {
            type: String,
            default: () => {
                return '';
            }
        },
        width: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        height: {
            type: Number,
            default: () => {
                return 40;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE_VALUE: 'changeValue'
            },
            innerCode: '',
            innerNumber: '',
            showCountries: false,
            searchCountry: '',
            countries: []
        };
    },
    computed: {
        countryList () {
            if (!this.searchCountry) {
                return this.countries;
            }
            let search = this.searchCountry.toLowerCase();
            return this.countries.filter((item) => {
                return item.commonName.toLowerCase().indexOf(search) >= 0 || item.phoneCode.indexOf(search) >= 0;
            });
        },
        fullNumber () {
            return this.innerCode + this.innerNumber;
        }
    },
    watch: {
        code (code) {
            this.innerCode = code;
        },
        number (number) {
            this.innerNumber = number;
        },
        fullNumber () {
            this.$emit(this.EVENTS.CHANGE_VALUE, this.fullNumber);
        }
    },
    mounted () {
        if (this.code) {
            this.innerCode = this.code;
        }
        if (this.number) {
            this.innerNumber = this.number;
        }
        this.api.countries(this, null, function (rspBody) {
            this.countries = rspBody.countries;
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
    },
    methods: {
        clickPhoneCode () {
            this.showCountries = !this.showCountries;
        },
        clickClearPhoneNumber () {
            this.innerNumber = '';
        },
        clickCountriesMask () {
            this.showCountries = false;
        },
        clickCountryItem (country) {
            this.innerCode = country.phoneCode;
            this.showCountries = false;
        },
    }
}
</script>

<style scoped>
.phone-field {
    border-radius: 3px;
    background-color: var(--background-tertiary);
    display: flex;
}
.phone-field .phone-code {
    width: 78px;
    height: 40px;
}
.phone-field .phone-code .control {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.phone-field .phone-code .control .field {
    flex: 1;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.phone-field .phone-code .control .field-tail {
    width: 22px;
    height: 24px;
    border-right: 1px solid var(--sep-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.phone-field .phone-number {
    flex: 1;
}
.phone-field .phone-number .holder {
    display: block;
}
.phone-field .phone-number .holder .text {
    padding-right: 40px;
}
.phone-field .phone-number .holder .icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    display: none;
}
.phone-field .phone-number .holder .icon.show {
    display: block;
}

.countries {
    position: absolute;
    top: 44px;
    left: 0;
    width: 480px;
    padding: 8px;
    box-shadow: var(--dropdown-menu-shadow) 0 1px 5px 0;
    background-color: var(--background-primary);
    border: 1px solid var(--background-tertiary);
    border-radius: 3px;
    z-index: 1;
    display: none;
}
.countries.show {
    display: block;
}
.countries .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
}
.countries .filter {
    z-index: 1;
}
.countries .filter .holder {
    display: block;
}
.countries .filter .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}
.countries .filter .text {
    height: 32px;
    padding-left: 32px;
}
.countries .list {
    max-height: 240px;
    margin: 8px -8px 0 -8px;
    overflow-y: auto;
    z-index: 1;
}
.countries .list .item {
    height: 44px;
    padding: 0 8px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.countries .list .item:hover {
    background-color: var(--background-hover);
}
</style>