<template>
<div class="container">
    <top-bar></top-bar>
    <div class="trade-head">
        <div class="head-left">
            <instrument-picker class="instrument" :instrument="inst" :panel-height="'600px'" @change="changeInstrument"></instrument-picker>
            <div class="ticker">
                <div class="usdt-price" :class="{'index-up':instTicker.updown>0,'index-down':instTicker.updown<0}" v-text="abs.Util.formatNumber(instTicker.last, inst.tickSize)"></div>
                <div class="usd-price" v-text="'$' + abs.Util.formatNumber(inst.baseIndex)"></div>
            </div>
        </div>
        <div class="head-middle">
            <div class="h24">
                <div class="h24-item">
                    <div class="label" v-text="$t('spot.base.h24change')"></div>
                    <div class="value" :class="{'index-up':instTicker.updown>0,'index-down':instTicker.updown<0}" v-text="(instTicker.updown>0 ? '+' : '') + abs.Util.formatNumber(instTicker.updown, 2) + '%'"></div>
                </div>
                <div class="h24-item">
                    <div class="label" v-text="$t('spot.base.h24low')"></div>
                    <div class="value" v-text="abs.Util.formatNumber(instTicker.low24h, inst.tickSize)"></div>
                </div>
                <div class="h24-item">
                    <div class="label" v-text="$t('spot.base.h24high')"></div>
                    <div class="value" v-text="abs.Util.formatNumber(instTicker.high24h, inst.tickSize)"></div>
                </div>
                <div class="h24-item">
                    <div class="label" v-text="$t('spot.base.h24volume') + '(' + inst.baseCcy + ')'"></div>
                    <div class="value" v-text="abs.Util.formatNumber(instTicker.vol24h, inst.tickSize)"></div>
                </div>
                <div class="h24-item">
                    <div class="label" v-text="$t('spot.base.h24turnover') + '(' + inst.quoteCcy + ')'"></div>
                    <div class="value" v-text="abs.Util.formatNumber(instTicker.volCcy24h, inst.tickSize)"></div>
                </div>
            </div>
        </div>
        <div class="head-right"></div>
    </div>
    <div class="trade-body">
        <div class="col-action">
            <div class="os-block">
                <side-tab @change="changeOrderSide"></side-tab>
            </div>
            <div class="ot-block">
                <div class="ot-tabs">
                    <div class="ot-tab" :class="{'active':orderTypeTab===ORDER_TYPE_TABS.LIMIT}" @click="clickOrderTypeTab(ORDER_TYPE_TABS.LIMIT)"><span class="text" v-text="$t('spot.base.limit')"></span></div>
                    <div class="ot-tab" :class="{'active':orderTypeTab===ORDER_TYPE_TABS.MARKET}" @click="clickOrderTypeTab(ORDER_TYPE_TABS.MARKET)"><span class="text" v-text="$t('spot.base.market')"></span></div>
                </div>
            </div>
            <div class="od-block" :class="{'active':orderTypeTab===ORDER_TYPE_TABS.LIMIT}">
                <spot-limit-order-builder :inst="inst" :side="orderSide" :base-ccy-count="baseCcyCount" :quote-ccy-count="quoteCcyCount"></spot-limit-order-builder>
            </div>
            <div class="od-block" :class="{'active':orderTypeTab===ORDER_TYPE_TABS.MARKET}">
                <spot-market-order-builder :inst="inst" :side="orderSide" :inst-ticker="instTicker" :base-ccy-count="baseCcyCount" :quote-ccy-count="quoteCcyCount"></spot-market-order-builder>
            </div>
        </div>
        <div class="col-observe">
            <div class="obv-top">
                <div class="obv-top-left">
                    <div class="market-tabs">
                        <div class="market-tab" :class="{'active':marketTab===MARKET_TABS.ORDER_BOOKS}" @click="clickMarketTab(MARKET_TABS.ORDER_BOOKS)"><div class="text" v-text="$t('spot.base.order_book')"></div></div>
                        <div class="market-tab" :class="{'active':marketTab===MARKET_TABS.TRADE_HISTORY}" @click="clickMarketTab(MARKET_TABS.TRADE_HISTORY)"><div class="text" v-text="$t('spot.base.last_trades')"></div></div>
                    </div>
                    <div class="market-body">
                        <order-books class="order-books" :class="{'active':marketTab===MARKET_TABS.ORDER_BOOKS}" :inst="inst"></order-books>
                        <trade-history class="trade-history" :class="{'active':marketTab===MARKET_TABS.TRADE_HISTORY}" :inst="inst"></trade-history>
                    </div>
                </div>
                <div class="obv-top-right">
                    <trade-chart :inst="inst"></trade-chart>
                </div>
            </div>
            <div class="obv-bottom">
                <div class="order-tabs">
                    <div class="order-tab" :class="{'active':orderState===ORDER_STATES.PENDING}" v-text="$t('spot.base.open_orders') + (pendingOrderCount ? (' (' + pendingOrderCount + ')') : '')" @click="clickOrderListState(ORDER_STATES.PENDING)"></div>
                    <div class="order-tab" :class="{'active':orderState===ORDER_STATES.HISTORY}" v-text="$t('spot.base.order_history')" @click="clickOrderListState(ORDER_STATES.HISTORY)"></div>
                </div>
                <div class="order-tab-panel" :class="{'active':orderState===ORDER_STATES.PENDING}">
                    <div class="order-types">
                        <a class="button link-button order-type" :class="{'active':pending.orderType===abs.common.EOrderType.LIMIT}" v-text="$t('spot.base.limit') + (pending.limitOrderList.length ? (' (' + pending.limitOrderList.length + ')') : '')" @click="clickOrderListType(abs.common.EOrderType.LIMIT)"></a>
                        <a class="button link-button order-type" :class="{'active':pending.orderType===abs.common.EOrderType.MARKET}" v-text="$t('spot.base.market')" @click="clickOrderListType(abs.common.EOrderType.MARKET)"></a>
                    </div>
                    <div class="list">
                        <div class="head">
                            <div class="row">
                                <div class="col inst" v-text="$t('spot.base.order_symbol')"></div>
                                <div class="col time" v-text="$t('spot.base.order_time')"></div>
                                <div class="col side enum" v-text="$t('spot.base.order_side')"></div>
                                <div class="col price number" v-text="$t('spot.base.order_price')"></div>
                                <div class="col volume number" v-text="$t('spot.base.order_volume')"></div>
                                <div class="col turnover number" v-text="$t('spot.base.order_turnover')"></div>
                                <div class="col status" v-text="$t('spot.base.order_status')"></div>
                                <div class="col action enum"></div>
                            </div>
                        </div>
                        <div class="body">
                            <div class="row" v-for="order in pendingOrderList" :key="order.ordId">
                                <div class="col inst" v-text="order.instId"></div>
                                <div class="col time" v-text="$d(order.cTime, 'long')"></div>
                                <div class="col side enum" v-text="order.side"></div>
                                <div class="col price number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx) : '--') + ' | ' + abs.Util.formatNumber(order.px)"></div>
                                <div class="col volume number" v-text="abs.Util.formatNumber(order.accFillSz) + ' | ' + abs.Util.formatNumber(order.sz)"></div>
                                <div class="col turnover number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx * order.accFillSz, 2) : '0') + ' | ' + abs.Util.formatNumber(order.px * order.sz, 2)"></div>
                                <div class="col status enum" v-text="order.state"></div>
                                <div class="col action enum"><a class="button link-button" v-text="$t('common.cancel')"></a></div>
                            </div>
                            <div class="empty" v-if="!pendingOrderList.length" v-text="$t('spot.base.no_records')"></div>
                        </div>
                    </div>
                </div>
                <div class="order-tab-panel" :class="{'active':orderState===ORDER_STATES.HISTORY}">
                    <div class="order-types">
                        <a class="button link-button order-type" :class="{'active':history.orderType===abs.common.EOrderType.LIMIT}" v-text="$t('spot.base.limit')" @click="clickOrderListType(abs.common.EOrderType.LIMIT)"></a>
                        <a class="button link-button order-type" :class="{'active':history.orderType===abs.common.EOrderType.MARKET}" v-text="$t('spot.base.market')" @click="clickOrderListType(abs.common.EOrderType.MARKET)"></a>
                    </div>
                    <div class="list">
                        <div class="head">
                            <div class="row">
                                <div class="col inst" v-text="$t('spot.base.order_symbol')"></div>
                                <div class="col time" v-text="$t('spot.base.order_time')"></div>
                                <div class="col side enum" v-text="$t('spot.base.order_side')"></div>
                                <div class="col price number" v-text="$t('spot.base.order_price')"></div>
                                <div class="col volume number" v-text="$t('spot.base.order_volume')"></div>
                                <div class="col turnover number" v-text="$t('spot.base.order_turnover')"></div>
                                <div class="col fee number" v-text="$t('spot.base.order_fee')"></div>
                                <div class="col status" v-text="$t('spot.base.order_status')"></div>
                            </div>
                        </div>
                        <div class="body">
                            <div class="row" v-for="order in historyOrderList" :key="order.ordId">
                                <div class="col inst" v-text="order.instId"></div>
                                <div class="col time" v-text="$d(order.cTime, 'long')"></div>
                                <div class="col side enum" v-text="order.side"></div>
                                <div class="col price number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx) : '--') + ' | ' + abs.Util.formatNumber(order.px)"></div>
                                <div class="col volume number" v-text="abs.Util.formatNumber(order.accFillSz) + ' | ' + abs.Util.formatNumber(order.sz)"></div>
                                <div class="col turnover number" v-text="(order.avgPx ? abs.Util.formatNumber(order.avgPx * order.accFillSz, 2) : '0') + ' | ' + abs.Util.formatNumber(order.px * order.sz, 2)"></div>
                                <div class="col fee number" v-text="abs.Util.formatNumber(order.fee)"></div>
                                <div class="col status enum" v-text="order.state"></div>
                            </div>
                            <div class="empty" v-if="!historyOrderList.length" v-text="$t('spot.base.no_records')"></div>
                        </div>
                    </div>
                </div>
                <div class="currency-only"><checkbox-field :label="$t('spot.base.current_only')" :value="currencyOnly" @changeValue="changeCurrencyOnly"></checkbox-field></div>
            </div>
        </div>
    </div>
    <msg-toast></msg-toast>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import SideTab from "@/components/SideTab";
import OrderBooks from "@/components/OrderBooks";
import TradeChart from "@/components/TradeChart";
import InstrumentPicker from "@/components/InstrumentPicker";
import TradeHistory from "@/components/TradeHistory";
import CheckboxField from "@/components/CheckboxField";
import SpotMarketOrderBuilder from "@/components/SpotMarketOrderBuilder";
import SpotLimitOrderBuilder from "@/components/SpotLimitOrderBuilder";
export default {
    name: "SpotBase",
    components: {
        SpotLimitOrderBuilder, SpotMarketOrderBuilder, CheckboxField,
        TradeHistory, InstrumentPicker, SideTab, OrderBooks, TradeChart},
    data () {
        return {
            ORDER_TYPE_TABS: {
                LIMIT: 'limit',
                MARKET: 'market'
            },
            MARKET_TABS: {
                ORDER_BOOKS: 'order-books',
                TRADE_HISTORY: 'trade-history'
            },
            ORDER_STATES: {
                PENDING: 'pending',
                HISTORY: 'history'
            },
            instrumentType: this.abs.common.EInstrumentType.SPOT,
            instId: '',
            inst: {
                instId: '',
                instType: '',
                logo: '',
                baseCcy: '',
                quoteCcy: '',
                baseIndex: 0,
                quoteIndex: 0,
                tickSize: 0,
                tickSizeText: '',
                lotSize: 0,
                lotSizeText: '',
                minSize: 0,
                minSizeText: '',
                last: 0
            },
            instTicker: {
                last: 0,
                updown: 0,
                low24h: 0,
                high24h: 0,
                vol24h: 0,
                volCcy24h: 0
            },
            fee: 0,
            orderSide: this.abs.common.EOrderSide.BUY,
            orderTypeTab: 'limit',
            baseCcyCount: 0,
            quoteCcyCount: 0,
            inLimitPrice: 0,
            inLimitCount: 0,
            inLimitAmount: 0,
            inMarketCount: 0,
            inMarketAmount: 0,
            limitPrice: 0,
            limitCount: 0,
            limitAmount: 0,
            marketCount: 0,
            marketAmount: 0,
            marketCounted: true,
            marketTab: 'order-books',
            orderState: 'pending',
            pending: {
                orderType: this.abs.common.EOrderType.LIMIT,
                limitOrderList: [],
                marketOrderList: [],
                LIMIT: 20
            },
            history: {
                orderType: this.abs.common.EOrderType.LIMIT,
                limitOrderList: [],
                marketOrderList: []
            },
            orders: [],
            currencyOnly: false
        };
    },
    computed: {
        baseCcyLabel () {
            return this.inst.baseCcy ? ('(' + this.inst.baseCcy + ')') : '';
        },
        quoteCcyLabel () {
            return this.inst.quoteCcy ? ('(' + this.inst.quoteCcy + ')') : '';
        },
        pendingLimitOrderList () {
            if (!this.currencyOnly) {
                return this.pending.limitOrderList;
            }
            let orderList = [];
            for (let i = 0; i < this.pending.limitOrderList.length; i++) {
                let order = this.pending.limitOrderList[i];
                if (order.instId === this.inst.instId) {
                    orderList.push(order);
                }
            }
            return orderList;
        },
        pendingMarketOrderList () {
            if (!this.currencyOnly) {
                return this.pending.marketOrderList;
            }
            let orderList = [];
            for (let i = 0; i < this.pending.marketOrderList.length; i++) {
                let order = this.pending.marketOrderList[i];
                if (order.instId === this.inst.instId) {
                    orderList.push(order);
                }
            }
            return orderList;
        },
        historyLimitOrderList () {
            if (!this.currencyOnly) {
                return this.history.limitOrderList;
            }
            let orderList = [];
            for (let i = 0; i < this.history.limitOrderList.length; i++) {
                let order = this.history.limitOrderList[i];
                if (order.instId === this.inst.instId) {
                    orderList.push(order);
                }
            }
            return orderList;
        },
        historyMarketOrderList () {
            if (!this.currencyOnly) {
                return this.history.marketOrderList;
            }
            let orderList = [];
            for (let i = 0; i < this.history.marketOrderList.length; i++) {
                let order = this.history.marketOrderList[i];
                if (order.instId === this.inst.instId) {
                    orderList.push(order);
                }
            }
            return orderList;
        },
        pendingOrderCount () {
            return this.pendingLimitOrderList.length + this.pendingMarketOrderList.length;
        },
        pendingOrderList () {
            if (this.pending.orderType === this.abs.common.EOrderType.LIMIT) {
                return this.pendingLimitOrderList;
            }
            else if (this.pending.orderType === this.abs.common.EOrderType.MARKET) {
                return this.pendingMarketOrderList;
            }
            return [];
        },
        historyOrderList () {
            if (this.history.orderType === this.abs.common.EOrderType.LIMIT) {
                return this.historyLimitOrderList;
            }
            else if (this.history.orderType === this.abs.common.EOrderType.MARKET) {
                return this.historyMarketOrderList;
            }
            return [];
        }
    },
    created () {
        this.instId = this.$route.params.inst || 'BTC-USDT';
    },
    mounted () {
        this.api.instruments(this, {
            instrumentType: this.instrumentType,
            instrumentId: this.instId
        }, function (rspBody) {
            if (rspBody.instruments.length) {
                let inst = rspBody.instruments[0];
                this.changeInstrument(inst);
            }
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
        this.api.tradeFee(this, {
            instrumentType: this.instrumentType
        }, function (rspBody) {
            this.fee = Math.max(Math.abs(Number(rspBody.tradeFee.maker)), Math.abs(Number(rspBody.tradeFee.taker)));
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
        this.spotOrderPendingList(this.abs.common.EOrderType.LIMIT, (orderList) => {
            this.rebuildOrders(this.pending.limitOrderList, orderList);
            this.pending.limitOrderList = orderList;
        });
        this.spotOrderPendingList(this.abs.common.EOrderType.MARKET, (orderList) => {
            this.rebuildOrders(this.pending.marketOrderList, orderList);
            this.pending.marketOrderList = orderList;
        });
        this.spotOrderHistoryList(this.abs.common.EOrderType.LIMIT, (orderList) => {
            this.rebuildOrders(this.history.limitOrderList, orderList);
            this.history.limitOrderList = orderList;
        });
        this.spotOrderHistoryList(this.abs.common.EOrderType.LIMIT, (orderList) => {
            this.rebuildOrders(this.history.limitOrderList, orderList);
            this.history.limitOrderList = orderList;
        });
        let _this = this;
        this.websocket.subscribe({
            channel: 'orders',
            instType: this.abs.common.EInstrumentType.SPOT
        }, 'spot-base-order', function (content) {
            let item = content.data[0];
            let order = {
                ordId: item.ordId,
                instId: item.instId,
                instType: item.instType,
                ordType: item.ordType,
                side: item.side,
                cTime: new Date (Number(item.cTime)),
                px: Number(item.px),
                sz: Number(item.sz),
                avgPx: Number(item.avgPx),
                accFillSz: Number(item.accFillSz),
                fee: Number(item.fee),
                state: item.state
            };
            let oldOrder = _this.orders[order.ordId];
            if (!oldOrder) {
                if (_this.isOrderPending(order)) {
                    if (order.ordType === _this.abs.common.EOrderType.LIMIT) {
                        _this.rebuildOrders(null, [order]);
                        _this.pending.limitOrderList.unshift(order);
                    }
                    else if (order.ordType === _this.abs.common.EOrderType.MARKET) {
                        _this.rebuildOrders(null, [order]);
                        _this.pending.marketOrderList.unshift(order);
                    }
                }
                else {
                    if (order.ordType === _this.abs.common.EOrderType.LIMIT) {
                        _this.rebuildOrders(null, [order]);
                        _this.history.limitOrderList.unshift(order);
                    }
                    else if (order.ordType === _this.abs.common.EOrderType.MARKET) {
                        _this.rebuildOrders(null, [order]);
                        _this.history.marketOrderList.unshift(order);
                    }
                }
            }
            else {
                if ((_this.isOrderPending(oldOrder) && _this.isOrderPending(order)) || (!_this.isOrderPending(oldOrder) && !_this.isOrderPending(order))) {
                    //update
                    oldOrder.avgPx = order.avgPx;
                    oldOrder.accFillSz = order.accFillSz;
                    oldOrder.fee = order.fee;
                    oldOrder.state = order.state;
                }
                else if (_this.isOrderPending(oldOrder) && !_this.isOrderPending(order)) {
                    //pending -> history
                    if (oldOrder.ordType === _this.abs.common.EOrderType.LIMIT) {
                        let index = _this.pending.limitOrderList.indexOf(oldOrder);
                        if (index >= 0) {
                            _this.pending.limitOrderList.splice(index, 1);
                        }
                    }
                    else if (oldOrder.ordType === _this.abs.common.EOrderType.MARKET) {
                        let index = _this.pending.marketOrderList.indexOf(oldOrder);
                        if (index >= 0) {
                            _this.pending.marketOrderList.splice(index, 1);
                        }
                    }
                    if (order.ordType === _this.abs.common.EOrderType.LIMIT) {
                        _this.history.limitOrderList.unshift(order);
                    }
                    else if (order.ordType === _this.abs.common.EOrderType.MARKET) {
                        _this.history.marketOrderList.unshift(order);
                    }
                }
            }
        }, true);
    },
    unmounted () {
        if (this.inst.instId) {
            this.websocket.unsubscribe({
                'channel': 'tickers',
                'instId': this.inst.instId
            }, 'spot-base-instrument');
        }
        this.websocket.unsubscribe({
            channel: 'orders',
            instType: this.abs.common.EInstrumentType.SPOT
        }, 'spot-base-order', true);
    },
    methods: {
        changeInstrument (instrument) {
            if (this.inst.instId) {
                this.inst.baseIndex = 0;
                this.inst.quoteIndex = 0;
                this.websocket.unsubscribe({
                    'channel': 'tickers',
                    'instId': this.inst.instId
                }, 'spot-base-instrument');
            }

            this.inst.instId = instrument.instId;
            this.inst.instType = instrument.instType;
            this.inst.logo = instrument.logo;
            this.inst.baseCcy = instrument.baseCcy;
            this.inst.quoteCcy = instrument.quoteCcy;
            let bits = instrument.tickSz.indexOf('.');
            this.inst.tickSize = bits > 0 ? instrument.tickSz.length - bits - 1 : 0;
            this.inst.tickSizeText = instrument.tickSz;
            bits = instrument.lotSz.indexOf('.');
            this.inst.lotSize = bits > 0 ? instrument.lotSz.length - bits - 1 : 0;
            this.inst.lotSizeText = instrument.lotSz;
            bits = instrument.minSz.indexOf('.');
            this.inst.minSize = bits > 0 ? instrument.minSz.length - bits - 1 : 0;
            this.inst.minSizeText = instrument.minSz;
            this.api.indexTicker(this, {
                instrumentId: this.inst.baseCcy + '-USD'
            }, function (rspBody) {
                this.inst.baseIndex = Number(rspBody.tickers[0].idxPx.replace(/,/g, ''));
            });
            this.api.indexTicker(this, {
                instrumentId: this.inst.quoteCcy + '-USD'
            }, function (rspBody) {
                this.inst.quoteIndex = Number(rspBody.tickers[0].idxPx.replace(/,/g, ''));
            });
            this.accountBalance(this.inst.baseCcy, this.inst.quoteCcy);

            let _this = this;
            this.inst.last = 0;
            this.websocket.subscribe({
                'channel': 'tickers',
                'instId': this.inst.instId
            }, 'spot-base-instrument', function (result) {
                let ticker = result.data[0];
                if (ticker.instId !== _this.inst.instId) {
                    return;
                }
                _this.instTicker.updown = (Number(ticker.last) - Number(ticker.open24h)) / Number(ticker.open24h) * 100;
                _this.instTicker.last = Number(ticker.last);
                _this.instTicker.low24h = Number(ticker.low24h);
                _this.instTicker.high24h = Number(ticker.high24h);
                _this.instTicker.vol24h = Number(ticker.vol24h);
                _this.instTicker.volCcy24h = Number(ticker.volCcy24h);

                if (!_this.inst.last) {
                    _this.inst.last = _this.instTicker.last;
                    _this.limitPrice = _this.inst.last;
                    _this.inLimitPrice = _this.limitPrice;
                }
            });
        },
        changeOrderSide (orderSide) {
            this.orderSide = orderSide;
        },
        clickOrderTypeTab (orderTypeTab) {
            this.orderTypeTab = orderTypeTab;
        },
        clickTransferAvailable () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_TRANSFER);
        },
        clickMarketTab (marketTab) {
            this.marketTab = marketTab;
        },
        clickOrderListState (orderState) {
            this.orderState = orderState;
            this.reloadOrderList();
        },
        clickOrderListType (orderType) {
            if (this.orderState === this.ORDER_STATES.PENDING) {
                this.pending.orderType = orderType;
            }
            else if (this.orderState === this.ORDER_STATES.HISTORY) {
                this.history.orderType = orderType;
            }
            this.reloadOrderList();
        },
        changeCurrencyOnly (currencyOnly) {
            if (this.currencyOnly !== currencyOnly) {
                this.currencyOnly = currencyOnly;
                this.reloadOrderList();
            }
        },
        accountBalance (baseCcy, quoteCcy) {
            this.api.accountBalance(this, null, function (rspBody) {
                for (let i = 0; i < rspBody.balance.details.length; i++) {
                    let detail = rspBody.balance.details[i];
                    if (detail.ccy === baseCcy) {
                        this.baseCcyCount = Number(detail.availBal);
                    }
                    else if (detail.ccy === quoteCcy) {
                        this.quoteCcyCount = this.abs.Util.floor(detail.availBal, 2);
                    }
                }
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        reloadOrderList () {
            if (this.orderState === this.ORDER_STATES.PENDING) {
                if (this.pending.orderType === this.abs.common.EOrderType.LIMIT) {
                    this.spotOrderPendingList(this.abs.common.EOrderType.LIMIT, (orderList) => {
                        this.rebuildOrders(this.pending.limitOrderList, orderList);
                        this.pending.limitOrderList = orderList;
                    });
                }
                else if (this.pending.orderType === this.abs.common.EOrderType.MARKET) {
                    this.spotOrderPendingList(this.abs.common.EOrderType.MARKET, (orderList) => {
                        this.rebuildOrders(this.pending.marketOrderList, orderList);
                        this.pending.marketOrderList = orderList;
                    });
                }
            }
            else if (this.orderState === this.ORDER_STATES.HISTORY) {
                if (this.history.orderType === this.abs.common.EOrderType.LIMIT) {
                    this.spotOrderHistoryList(this.abs.common.EOrderType.LIMIT, (orderList) => {
                        this.rebuildOrders(this.history.limitOrderList, orderList);
                        this.history.limitOrderList = orderList;
                    });
                }
                else if (this.history.orderType === this.abs.common.EOrderType.MARKET) {
                    this.spotOrderHistoryList(this.abs.common.EOrderType.MARKET, (orderList) => {
                        this.rebuildOrders(this.history.marketOrderList, orderList);
                        this.history.marketOrderList = orderList;
                    });
                }
            }
        },
        rebuildOrders (oldOrderList, newOrderList) {
            if (oldOrderList) {
                for (let i = 0; i < oldOrderList.length; i++) {
                    let order = oldOrderList[i];
                    delete this.orders[order.ordId];
                }
            }
            if (newOrderList) {
                for (let i = 0; i < newOrderList.length; i++) {
                    let order = newOrderList[i];
                    this.orders[order.ordId] = order;
                }
            }
        },
        isOrderPending (order) {
            return order.state === this.abs.common.EOrderState.LIVE || order.state === this.abs.common.EOrderState.PARTIALLY;
        },
        spotOrderPendingList (orderType, cb) {
            this.api.spotOrderPendingList(this, {
                instrumentType: this.instrumentType,
                instrumentId: (this.currencyOnly ? this.inst.instId : ''),
                orderType: orderType,
                limit: this.pending.LIMIT
            }, function (rspBody) {
                let orderList = [];
                for (let i = 0; i < rspBody.orders.length; i++) {
                    let item = rspBody.orders[i];
                    orderList.push({
                        ordId: item.ordId,
                        instId: item.instId,
                        instType: item.instType,
                        ordType: item.ordType,
                        side: item.side,
                        cTime: new Date(Number(item.cTime)),
                        px: Number(item.px),
                        sz: Number(item.sz),
                        avgPx: Number(item.avgPx),
                        accFillSz: Number(item.accFillSz),
                        fee: Number(item.fee),
                        state: item.state
                    });
                }
                cb && cb.call(this, orderList);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        spotOrderHistoryList (orderType, cb) {
            this.api.spotOrderHistoryList(this, {
                instrumentType: this.instrumentType,
                instrumentId: (this.currencyOnly ? this.inst.instId : ''),
                orderType: orderType,
                recent: true
            }, function (rspBody) {
                let orderList = [];
                for (let i = 0; i < rspBody.orders.length; i++) {
                    let item = rspBody.orders[i];
                    orderList.push({
                        ordId: item.ordId,
                        instId: item.instId,
                        instType: item.instType,
                        ordType: item.ordType,
                        side: item.side,
                        cTime: new Date (Number(item.cTime)),
                        px: Number(item.px),
                        sz: Number(item.sz),
                        avgPx: Number(item.avgPx),
                        accFillSz: Number(item.accFillSz),
                        fee: Number(item.fee),
                        state: item.state
                    });
                }
                cb && cb.call(this, orderList);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.trade-head {
    height: 60px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
}
.trade-head .head-left {
    width: 300px;
    display: flex;
}
.trade-head .head-left .instrument {
    width: 200px;
}
.trade-head .head-left .ticker {
    width: 100px;
    margin: 10px 0;
    padding: 0 10px;
    border-right: 1px solid var(--sep-color);
}
.trade-head .head-left .ticker .usdt-price {
    line-height: 20px;
    text-align: right;
}
.trade-head .head-left .ticker .usd-price {
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: var(--text-strong);
}

.trade-head .head-middle {
    flex: 1;
}
.trade-head .head-middle .h24 {
    margin: 10px 0;
    padding: 0 10px;
    display: flex;
}
.trade-head .head-middle .h24 .h24-item {
    margin-right: 40px;
}
.trade-head .head-middle .h24 .h24-item .label {
    font-size: 12px;
    line-height: 20px;
    color: var(--text-tertiary);
}
.trade-head .head-middle .h24 .h24-item .value {
    font-size: 12px;
    line-height: 20px;
    color: var(--text-strong);
}

.trade-body {
    height: 900px;
    display: flex;
}
.trade-body .col-action {
    width: 300px;
    border-right: 1px solid var(--sep-color);
    padding-bottom: 100px;
    user-select: none;
}
.trade-body .col-observe {
    flex: 1;
}

.os-block {
    margin-top: 12px;
    padding: 0 12px;
}

.ot-block {
    margin-top: 8px;
    padding: 0 12px;
}
.ot-block .ot-tabs {
    border-bottom: 1px solid var(--sep-color);
    display: flex;
}
.ot-block .ot-tabs .ot-tab {
    margin-right: 16px;
    height: 36px;
    border-bottom: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ot-block .ot-tabs .ot-tab.active {
    border-bottom-color: var(--text-strong);
}
.ot-block .ot-tabs .ot-tab.active .text {
    color: var(--text-strong);
}

.od-block {
    padding: 0 12px;
    display: none;
}
.od-block.active {
    display: block;
}
.od-block .seg {
    margin-top: 12px;
}
.od-block .seg-submit {
    margin-top: 32px;
}
.od-block .seg-submit .button {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: var(--text-strong);
}

.obv-top {
    height: 630px;
    user-select: none;
    display: flex;
}
.obv-top .obv-top-left {
    width: 300px;
    border-right: 1px solid var(--sep-color);
}
.obv-top .obv-top-right {
    flex: 1;
}
.obv-bottom {
    position: absolute;
    top: 630px;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--sep-color);
}

.market-tabs {
    height: 40px;
    padding: 0 16px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.market-tabs .market-tab {
    height: 100%;
    margin-right: 24px;
    padding-top: 1px;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.market-tabs .market-tab.active {
    border-bottom-color: var(--text-strong);
}
.market-tabs .market-tab.active .text {
    color: var(--text-strong);
}
.market-body {
    position: absolute;
    width: 100%;
    top: 40px;
    bottom: 0;
}
.market-body .order-books, .market-body .trade-history {
    width: 100%;
    height: 100%;
    display: none;
}
.market-body .order-books.active, .market-body .trade-history.active {
    display: block;
}

.order-tabs {
    padding: 0 16px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.order-tabs .order-tab {
    margin-right: 32px;
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.order-tabs .order-tab.active {
    color: var(--text-strong);
    border-bottom-color: var(--text-strong);
}
.order-tab-panel {
    display: none;
}
.order-tab-panel.active {
    display: block;
}
.order-tab-panel .order-types {
    padding: 8px 16px;
    display: flex;
    align-items: center;
}
.order-tab-panel .order-types .order-type {
    margin-right: 8px;
    padding: 0 8px;
    height: 28px;
    font-size: 12px;
    color: var(--text-tertiary);
    border: 1px solid var(--text-tertiary);
}
.order-tab-panel .order-types .order-type.active {
    color: var(--text-strong);
    background-color: var(--background-secondary);
}
.order-tab-panel .list {
    padding: 0 16px;
}
.order-tab-panel .list .row {
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.order-tab-panel .list .col {
    width: 10%;
    line-height: 20px;
}
.order-tab-panel .list .col.time {
    width: 15%;
}
.order-tab-panel .list .col.price {
    width: 15%;
}
.order-tab-panel .list .col.volume {
    width: 15%;
}
.order-tab-panel .list .col.turnover {
    width: 15%;
}
.order-tab-panel .list .head .row {
    height: 36px;
}
.order-tab-panel .list .head .col {
    font-size: 12px;
    color: var(--text-tertiary);
}
.order-tab-panel .list .body {
    height: 400px;
    overflow-y: auto;
}
.order-tab-panel .list .body .row {
    height: 48px;
}
.order-tab-panel .list .body .empty {
    height: 280px;
    color: var(--text-tertiary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.currency-only {
    position: absolute;
    top: 0;
    right: 16px;
    height: 40px;
    display: flex;
    align-items: center;
}

.dialog .content {
    padding: 0 24px;
}
.dialog .content .text {
    padding: 8px 0;
}
</style>