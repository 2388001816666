<template>
<div class="slider-bar">
    <div class="slider-content">
        <div class="slider-clickable" @mousedown="press" @mouseup="release" @mousemove="slide" @mouseleave="leave">
            <div class="slider-track">
                <div class="slider-mask" :style="'width:' + rate + '%'"></div>
                <div class="ticks">
                    <div class="tick" :class="{'tick-active':rate>=tick}" v-for="tick in ticks" :key="tick" :style="'left:' + tick + '%'" @mousedown="clickTick($event, tick)"><div class="tick-value" v-if="tick===0 && showZeroStick">0%</div><div class="tick-value" v-if="tick===100 && showHundredStick">100%</div></div>
                </div>
                <div class="slider" :style="'left:' + rate + '%'"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "SliderBar",
    props: {
        value: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        ticks: {
            type: Array,
            default: () => {
                return [0, 25, 50, 75, 100];
            }
        },
        showZeroStick: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        showHundredStick: {
            type: Boolean,
            default: () => {
                return true;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE_VALUE: 'changeValue'
            },
            rate: 0,
            sliding: false
        };
    },
    watch: {
        value (value) {
            this.rate = value;
        }
    },
    methods: {//TODO:当点击0或100较近时直接弹到0或100了
        press (e) {
            this.sliding = true;
            let rect = this.$el.querySelector('.slider-clickable').getBoundingClientRect();
            let offset = e.clientX - rect.x;
            offset = Math.max(0, offset);
            offset = Math.min(offset, rect.width);
            this.rate = Math.round(offset * 100 / rect.width);
            this.$emit(this.EVENTS.CHANGE_VALUE, this.rate);
        },
        release () {
            this.sliding = false;
        },
        slide (e) {
            if (!this.sliding) {
                return;
            }
            let rect = this.$el.querySelector('.slider-clickable').getBoundingClientRect();
            let offset = e.clientX - rect.x;
            offset = Math.max(0, offset);
            offset = Math.min(offset, rect.width);
            this.rate = Math.round(offset * 100 / rect.width);
            this.$emit(this.EVENTS.CHANGE_VALUE, this.rate);
        },
        leave () {
            this.sliding = false;
        },
        clickTick (e, tick) {
            e.stopPropagation();
            this.rate = tick;
            this.$emit(this.EVENTS.CHANGE_VALUE, this.rate);
        }
    }
}
</script>

<style scoped>
.slider-bar {
    height: 36px;
    padding: 0 4px;
}
.slider-bar .slider-content {
    padding: 4px 0;
}
.slider-bar .slider-content .slider-clickable {
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.slider-bar .slider-content .slider-clickable .slider-track {
    width: 100%;
    height: 2px;
    background-color: var(--background-secondary-alt);
}
.slider-bar .slider-content .slider-clickable .slider-track .slider-mask {
    width: 23%;
    height: 2px;
    background-color: var(--text-active);
}
.slider-bar .slider-content .slider-clickable .slider-track .tick {
    position: absolute;
    top: -1px;
    width: 8px;
    height: 8px;
    background-color: var(--background-primary);
    border: 1px solid var(--background-secondary-alt);
    border-radius: 50%;
    transform: translate(-50%,-50%);
}
.slider-bar .slider-content .slider-clickable .slider-track .tick.tick-active {
    border-color: var(--text-active);
}
.slider-bar .slider-content .slider-clickable .slider-track .tick .tick-value {
    position: absolute;
    padding-top: 12px;
    line-height: 16px;
    font-size: 12px;
    color: var(--text-tertiary);
    user-select: none;
}
.slider-bar .slider-content .slider-clickable .slider-track .tick:first-child .tick-value {
    left: 0;
}
.slider-bar .slider-content .slider-clickable .slider-track .tick:last-child .tick-value {
    right: 0;
}
.slider-bar .slider-content .slider-clickable .slider-track .slider {
    position: absolute;
    top: 1px;
    width: 10px;
    height: 10px;
    background-color: var(--text-strong);
    border-radius: 50%;
    transform: translate(-50%,-50%);
}
.slider-bar .slider-content .slider-clickable .slider-track .slider:hover {
    width: 12px;
    height: 12px;
}
</style>