<template>
<div class="bottom-bar">
    <div class="sections">
        <div class="section intro">
            <div class="logo"></div>
            <div class="text" v-text="$t('bottom_bar.risk')"></div>
        </div>
        <div class="section about">
            <div class="title" v-text="$t('bottom_bar.about')"></div>
            <div class="items">
                <div class="item"><a class="button link-button" v-text="$t('bottom_bar.about_company')"></a></div>
            </div>
        </div>
        <div class="section support">
            <div class="title" v-text="$t('bottom_bar.support')"></div>
            <div class="items">
                <div class="item"><a class="button link-button" v-text="$t('bottom_bar.support_agreement')"></a></div>
                <div class="item"><a class="button link-button" v-text="$t('bottom_bar.support_disclaimer')"></a></div>
                <div class="item"><a class="button link-button" v-text="$t('bottom_bar.support_privacy')"></a></div>
                <div class="item"><a class="button link-button" v-text="$t('bottom_bar.support_fee')"></a></div>
            </div>
        </div>
        <div class="section service">
            <div class="title" v-text="$t('bottom_bar.service')"></div>
            <div class="items">
                <div class="item"><a class="button link-button" v-text="$t('bottom_bar.service_bots')"></a></div>
            </div>
        </div>
        <div class="section contact">
            <div class="title" v-text="$t('bottom_bar.contact')"></div>
            <div class="items">
                <div class="item"><a class="button link-button" v-text="$t('bottom_bar.contact_email') + ':support@exopen.cc'"></a></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "BottomBar"
}
</script>

<style scoped>
.sections {
    padding: 80px 100px;
    background-color: var(--background-tertiary);
    display: flex;
}
.sections .section.intro {
    width: 30%;
}
.sections .section.about {
    width: 15%;
}
.sections .section.support {
    width: 15%;
}
.sections .section.service {
    width: 15%;
}
.sections .section.contact {
    width: 25%;
}
.sections .section.intro .logo {
    width: 100px;
    height: 60px;
}
.sections .section.intro .text {
    padding-right: 100px;
    line-height: 24px;
    color: var(--text-tertiary);
}
.sections .section .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: var(--text-strong);
}
.sections .section .items {
    margin-top: 10px;
}
.sections .section .items .item {
    display: flex;
}
.sections .section .items .item .button {
    width: auto;
    color: var(--text-tertiary);
}
</style>