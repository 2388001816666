<template>
<div class="top-bar">
    <div class="logo" @click="clickIndex"></div>
    <div class="main-menu">
        <a class="button link-button menu-item" @click="clickMarket">
            <div class="text" v-text="$t('top_bar.market')"></div>
        </a>
        <a class="button link-button menu-item" v-if="false" @click="clickSpotConvert">
            <div class="text" v-text="$t('top_bar.convert')"></div>
        </a>
        <a class="button link-button menu-item" @click="clickSpotBase">
            <div class="text" v-text="$t('top_bar.spot')"></div>
        </a>
        <a class="button link-button menu-item" v-if="false" @click="clickSpotMargin">
            <div class="text" v-text="$t('top_bar.margin')"></div>
        </a>
        <a class="button link-button menu-item" v-if="false" @click="clickContractSwap">
            <div class="text" v-text="$t('top_bar.swap')"></div>
        </a>
        <a class="button link-button menu-item" v-if="false" @click="clickContractFutures">
            <div class="text" v-text="$t('top_bar.futures')"></div>
        </a>
        <a class="button link-button menu-item" v-if="false" @click="clickContractOption">
            <div class="text" v-text="$t('top_bar.option')"></div>
        </a>
        <a class="button link-button menu-item" @click="clickUserAsset">
            <div class="text" v-text="$t('top_bar.asset')"></div>
        </a>
        <a class="button link-button menu-item" @click="clickUserOrder">
            <div class="text" v-text="$t('top_bar.order')"></div>
        </a>
    </div>
    <div class="sub-menu">
        <template v-if="!uticket">
        <a class="button link-button menu-item" @click="clickLogin">
            <div class="text" v-text="$t('top_bar.login')"></div>
        </a>
        <a class="button primary-button menu-item" @click="clickRegister">
            <div class="text" v-text="$t('top_bar.register')"></div>
        </a>
        </template>
        <template v-if="uticket">
        <a class="button link-button menu-item-alt" @click="clickAccount">
            <i class="icon user"></i><span class="text" v-text="nickname || '-'"></span>
        </a>
        <a class="button link-button menu-item-alt" @click="clickLogout">
            <span class="text" v-text="$t('top_bar.logout')"></span>
        </a>
        </template>
    </div>
</div>
</template>

<script>
export default {
    name: "TopBar",
    data () {
        return {
            uticket: '',
            nickname: ''
        };
    },
    created () {
        this.uticket = this.abs.Store.uticket();
        this.nickname = this.abs.Store.get('nickname');
    },
    mounted () {
        if (this.uticket && !this.nickname) {
            this.api.account(this, null, function (rspBody) {
                this.nickname = rspBody.user.userNickname;
                this.abs.Store.set('nickname', this.nickname);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    },
    methods: {
        clickIndex () {
            this.$router.push(this.abs.routes.HOME_INDEX);
        },
        clickRegister () {
            this.$router.push(this.abs.routes.HOME_REGISTER);
        },
        clickLogin () {
            this.$router.push(this.abs.routes.HOME_LOGIN);
        },
        clickSpotBase () {
            this.$router.push(this.abs.routes.SPOT_BASE);
        },
        clickSpotMargin () {
            this.$router.push(this.abs.routes.SPOT_MARGIN);
        },
        clickSpotConvert () {
            this.$router.push(this.abs.routes.SPOT_CONVERT);
        },
        clickContractSwap () {
            this.$router.push(this.abs.routes.CONTRACT_SWAP);
        },
        clickContractFutures () {
            this.$router.push(this.abs.routes.CONTRACT_FUTURES);
        },
        clickContractOption () {
            this.$router.push(this.abs.routes.CONTRACT_OPTION);
        },
        clickMarket () {
            this.$router.push(this.abs.routes.MARKET_BASE);
        },
        clickUserAsset () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_OVERVIEW);
        },
        clickUserOrder () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_ORDER);
        },
        clickAccount () {
            this.$router.push(this.abs.routes.USER_ACCOUNT + '/' + this.abs.routes.USER_ACCOUNT_MODULE_OVERVIEW);
        },
        clickLogout () {
            this.abs.Store.uticket('');
            this.abs.Store.set('nickname', '');
            this.$router.push(this.abs.routes.HOME_LOGIN);
        }
    }
}
</script>

<style scoped>
.top-bar {
    height: 60px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
}
.logo {
    width: 100px;
    height: 100%;
    background: url(../assets/img/logo.png) center no-repeat;
    background-size: 40px;
    cursor: pointer;
}
.main-menu {
    flex: 1;
}
.sub-menu {
    padding: 30px;
}
.main-menu, .sub-menu {
    display: flex;
    align-items: center;
}
.main-menu .menu-item, .sub-menu .menu-item {
    min-width: 20px;
    margin: 0 10px;
    padding: 0 10px;
}
.sub-menu .menu-item-alt {
    margin-left: 32px;
}
</style>