<template>
<div class="container">
    <top-bar></top-bar>
    <div class="content" :class="{'show':step===STEPS.REGISTER}">
        <div class="section">
            <div class="title" v-text="$t('home.register.title')"></div>
        </div>
        <div class="section">
            <div class="scenes">
                <div class="scene" :class="{'active':scene===abs.account.EAccountScene.PHONE}" @click="clickScene(abs.account.EAccountScene.PHONE)" v-text="$t('home.register.phone')"></div>
                <div class="scene" :class="{'active':scene===abs.account.EAccountScene.EMAIL}" @click="clickScene(abs.account.EAccountScene.EMAIL)" v-text="$t('home.register.email')"></div>
            </div>
        </div>
        <div class="section">
            <div class="account">
                <div class="phone-code" :class="{'show':scene===abs.account.EAccountScene.PHONE}">
                    <div class="control" @click="clickPhoneCode">
                        <div class="field" v-text="phoneCode"></div><div class="field-tail"><i class="icon chevron-down small"></i></div>
                    </div>
                    <div class="countries" :class="{'show':showCountries}">
                        <div class="mask" @click="clickCountriesMask"></div>
                        <div class="filter"><label class="holder"><i class="icon search small"></i><input class="text" type="text" v-model="searchCountry" /></label></div>
                        <div class="list small-scroll">
                            <div class="item" v-for="country in countryList" :key="country.countryId" @click="clickCountryItem(country)"><div class="common-name" v-text="country.logo+country.commonName"></div><div class="code" v-text="'+'+country.phoneCode"></div></div>
                        </div>
                    </div>
                </div>
                <div class="account-text">
                    <label class="holder">
                        <input class="text" type="text" v-model="account" :placeholder="scene===abs.account.EAccountScene.EMAIL ? $t('home.register.email_address') : $t('home.register.phone_number')" />
                        <i class="icon times-circle" :class="{'show':account}" @click="clickClearAccount"></i>
                    </label>
                </div>
            </div>
            <div class="password">
                <form><label class="holder"><input class="text" type="password" autocomplete="off" v-model="password" :placeholder="$t('home.register.password_requirement')" @keypress="keypressPassword" /></label></form>
            </div>
        </div>
        <div class="section">
            <div class="message" v-text="message"></div>
        </div>
        <div class="section">
            <div class="submit"><a class="button primary-button" @click="clickRegister" v-text="$t('home.register.sign_up')"></a></div>
        </div>
        <div class="section">
            <div class="exist"><span v-text="$t('home.register.already_account')"></span><a class="button link-button" @click="clickLogin" v-text="$t('home.register.sign_in')"></a></div>
        </div>
        <div class="section">
            <div class="agreement"><span v-text="$t('home.register.agreement1')"></span><a class="button link-button" style="margin:0 4px" v-text="$t('home.register.agreement2')"></a><span v-text="$t('home.register.agreement3')"></span><a class="button link-button" style="margin:0 4px" v-text="$t('home.register.agreement4')"></a><span v-text="$t('home.register.agreement5')"></span></div>
        </div>
    </div>
    <div class="content" :class="{'show':step===STEPS.VERIFY}">
        <div class="section">
            <div class="title" v-text="$t('home.register.verify_title')"></div>
            <div class="back" @click="clickBack"><i class="icon arrow-left small"></i><div class="text" v-text="$t('home.register.back')"></div></div>
        </div>
        <div class="section">
            <div class="verify-account" v-text="$t('home.register.sent_code') + fullAccount"></div>
        </div>
        <div class="section">
            <div class="codes-block">
                <label class="holder"><input class="text vcodes-0" type="text" v-model="vcodes[0]" @focus="focusVcodes(0)" @keyup="inputVcodes(0, $event)" /></label>
                <label class="holder"><input class="text vcodes-1" type="text" v-model="vcodes[1]" @focus="focusVcodes(1)" @keyup="inputVcodes(1, $event)" /></label>
                <label class="holder"><input class="text vcodes-2" type="text" v-model="vcodes[2]" @focus="focusVcodes(2)" @keyup="inputVcodes(2, $event)" /></label>
                <label class="holder"><input class="text vcodes-3" type="text" v-model="vcodes[3]" @focus="focusVcodes(3)" @keyup="inputVcodes(3, $event)" /></label>
                <label class="holder"><input class="text vcodes-4" type="text" v-model="vcodes[4]" @focus="focusVcodes(4)" @keyup="inputVcodes(4, $event)" /></label>
                <label class="holder"><input class="text vcodes-5" type="text" v-model="vcodes[5]" @focus="focusVcodes(5)" @keyup="inputVcodes(5, $event)" /></label>
            </div>
        </div>
        <div class="section">
            <div class="submit-verify"><a class="button primary-button" @click="clickVerify" v-text="$t('home.register.verify')"></a></div>
        </div>
        <div class="section">
            <div class="resend"><span v-text="$t('home.register.resend_ask')"></span><a class="button link-button" v-text="$t('home.register.resend')"></a></div>
        </div>
    </div>
    <bottom-bar></bottom-bar>
    <msg-toast></msg-toast>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
export default {
    name: "HomeRegister",
    data () {
        return {
            STEPS: {
                REGISTER: '1',
                VERIFY: '2'
            },
            VCODE_LENGTH: 6,
            step: '1',
            scene: 2,
            country: null,
            account: '',
            password: '',
            showCountries: false,
            searchCountry: '',
            countries: [],
            message: '',
            token: '',
            vcodes: ['','','','','','']
        };
    },
    computed: {
        phoneCode () {
            if (!this.country) {
                return '--';
            }
            return '+' + this.country.phoneCode;
        },
        countryList () {
            if (!this.searchCountry) {
                return this.countries;
            }
            let search = this.searchCountry.toLowerCase();
            return this.countries.filter((item) => {
                return item.commonName.toLowerCase().indexOf(search) >= 0 || item.phoneCode.indexOf(search) >= 0;
            });
        },
        fullAccount () {
            if (this.scene === this.abs.account.EAccountScene.PHONE) {
                return (this.country ? this.country.phoneCode : '') + this.account;
            }
            else {
                return this.account;
            }
        },
        vcode () {
            return this.vcodes.join('');
        }
    },
    mounted () {
        this.api.countries(this, null, function (rspBody) {
            this.countries = rspBody.countries;
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
    },
    methods: {
        clickScene (scene) {
            this.scene = scene;
        },
        clickPhoneCode () {
            this.showCountries = !this.showCountries;
            if (this.showCountries) {
                document.querySelector('.countries .filter .text').focus();
            }
        },
        clickClearAccount () {
            this.account = '';
        },
        clickCountriesMask () {
            this.showCountries = false;
        },
        clickCountryItem (country) {
            this.country = country;
            this.showCountries = false;
        },
        keypressPassword (e) {
            if (e.key !== 'Enter') {
                return;
            }
            e.preventDefault();
            this.clickRegister();
        },
        clickRegister () {
            if (this.scene === this.abs.account.EAccountScene.PHONE && !this.country) {
                return;
            }
            if (!this.account) {
                return;
            }
            if (!this.password) {
                return;
            }
            this.api.register(this, {
                accountScene: this.scene,
                account: this.fullAccount,
                password: this.password
            }, function (rspBody) {
                this.token = rspBody.token;

                this.api.vcode(this, {
                    vcodeScene: this.abs.account.EVcodeScene.REGISTER,
                    accountScene: this.scene,
                    account: this.fullAccount
                }, function () {
                    this.step = this.STEPS.VERIFY;
                }, function (code, message) {
                    this.abs.Toast.show(code + ' - ' + message);
                });
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        clickLogin () {
            this.$router.push(this.abs.routes.HOME_LOGIN);
        },
        clickBack () {
            this.step = this.STEPS.REGISTER;
        },
        focusVcodes (index) {
            this.vcodes[index] = '';
        },
        inputVcodes (index, e) {
            if (e.key === 'Backspace') {
                if (index > 0) {
                    document.querySelector('.vcodes-' + (index - 1)).focus();
                }
                return;
            }
            document.querySelector('.vcodes-' + index).blur();
            if (index < this.VCODE_LENGTH - 1) {
                document.querySelector('.vcodes-' + (index + 1)).focus();
            }
            else {
                this.api.verify(this, {
                    vcodeScene: this.abs.account.EVcodeScene.REGISTER,
                    accountScene: this.scene,
                    token: this.token,
                    code: this.vcode
                }, function () {
                    this.$router.replace(this.abs.routes.HOME_LOGIN);
                }, function (code, message) {
                    this.abs.Toast.show(code + ' - ' + message);
                });
            }
        },
        clickVerify () {
            this.api.verify(this, {
                vcodeScene: this.abs.account.EVcodeScene.REGISTER,
                token: this.token,
                code: this.vcode
            }, function () {
                this.$router.replace(this.abs.routes.HOME_LOGIN);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.content {
    min-height: 600px;
    display: none;
}
.content.show {
    display: block;
}
.section {
    width: 480px;
    margin-left: auto;
    margin-right: auto;
}
.title {
    padding: 60px 0 32px 0;
    font-size: 40px;
    font-weight: 700;
    color: var(--text-strong);
    text-align: center;
}
.scenes {
    padding-bottom: 8px;
    display: flex;
}
.scenes .scene {
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    margin-right: 32px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.scenes .scene:hover {
    color: var(--text-strong);
}
.scenes .scene.active {
    color: var(--text-strong);
    border-bottom-color: var(--text-strong);
}
.account {
    border-radius: 3px;
    background-color: var(--background-tertiary);
    display: flex;
}
.account .phone-code {
    width: 78px;
    height: 40px;
    display: none;
}
.account .phone-code.show {
    display: block;
}
.account .phone-code .control {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.account .phone-code .control .field {
    flex: 1;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.account .phone-code .control .field-tail {
    width: 36px;
    height: 24px;
    border-right: 1px solid var(--sep-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.account .account-text {
    flex: 1;
}
.account .account-text .holder {
    display: block;
}
.account .account-text .holder .text {
    padding-right: 40px;
}
.account .account-text .holder .icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    display: none;
}
.account .account-text .holder .icon.show {
    display: block;
}
.password {
    margin-top: 16px;
}

.countries {
    position: absolute;
    top: 44px;
    left: 0;
    width: 480px;
    padding: 8px;
    box-shadow: var(--dropdown-menu-shadow) 0 1px 5px 0;
    background-color: var(--background-primary);
    border: 1px solid var(--background-tertiary);
    border-radius: 3px;
    z-index: 1;
    display: none;
}
.countries.show {
    display: block;
}
.countries .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
}
.countries .filter {
    z-index: 1;
}
.countries .filter .holder {
    display: block;
}
.countries .filter .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}
.countries .filter .text {
    height: 32px;
    padding-left: 32px;
}
.countries .list {
    max-height: 240px;
    margin: 8px -8px 0 -8px;
    overflow-y: auto;
    z-index: 1;
}
.countries .list .item {
    height: 44px;
    padding: 0 8px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.countries .list .item:hover {
    background-color: var(--background-hover);
}

.message {
    margin: 8px 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: var(--text-error);
}
.submit .button {
    height: 48px;
    border-radius: 24px;
}

.exist {
    margin-top: 16px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: center;
}

.agreement {
    margin-top: 60px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.agreement .button {
    font-size: 12px;
}

.verify-account {
    margin-bottom: 48px;
    font-size: 16px;
}
.codes-block {
    display: flex;
    justify-content: space-between;
}
.codes-block .holder {
    display: block;
    width: 48px;
}
.codes-block .holder .text {
    height: 48px;
    text-align: center;
}
.submit-verify {
    margin-top: 48px;
}
.submit-verify .button {
    height: 48px;
    border-radius: 24px;
}
.resend {
    margin-top: 16px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: center;
}

.back {
    position: absolute;
    top: 40px;
    height: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
</style>