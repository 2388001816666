<template>
<div class="container">
    <top-bar></top-bar>
    <msg-toast></msg-toast>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
export default {
    name: "ContractSwap"
}
</script>

<style scoped>
.demo{}
</style>