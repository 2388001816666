export default {
    component: {
        inst_picker: {
            favorites: '自选',
            all: '全部',
            pair: '交易对',
            last: '最新价',
            change: '涨跌幅'
        },
        side_tab: {
            buy: '买入',
            sell: '卖出'
        },
        price_field: {
            market: '以当前市场最优价格下单'
        },
        count_field: {
            total: '按总额'
        },
        amount_field: {
            amount: '按数量'
        },
        available_field: {
            available: '可用'
        },
        order_builder: {
            price: '价格',
            amount: '数量',
            total: '金额',
            tick_size: '最小精度',
            min_size: '最小数量',
            buy: '买入',
            sell: '卖出',
            order_confirmation: '订单确认',
            market_price: '市价',
            type: '类型',
            type_limit: '限价单',
            type_market: '市价单'
        },
        order_books: {
            price: '价格',
            amount: '数量',
            total: '合计'
        },
        trade_history: {
            price: '价格',
            amount: '数量',
            time: '时间'
        },
        trade_chart: {
            min1: '1分钟',
            min5: '5分钟',
            min15: '15分钟',
            min30: '30分钟',
            hour1: '1小时',
            hour2: '2小时',
            hour4: '4小时',
            hour6: '6小时',
            hour12: '12小时',
            day1: '1天',
            week1: '1周',
            month1: '1月',
            view_candle: '基础版',
            view_depth: '深度图',
            open: '开',
            high: '高',
            low: '低',
            close: '收',
            change: '涨跌幅',
            range: '振幅'
        }
    },
    top_bar: {
        market: '行情',
        convert: '闪兑',
        spot: '现货',
        margin: '杠杆',
        swap: '永续',
        futures: '交割',
        option: '期权',
        asset: '资产',
        order: '订单中心',
        register: '注册',
        login: '登录',
        logout: '退出'
    },
    bottom_bar: {
        risk: '数字资产具有较高的风险性，其价格波动受市场影响，与本交易所无关，对数字资产缺乏了解的投资者请谨慎选择。',
        about: '关于我们',
        about_company: '公司简介',
        support: '用户支持',
        support_agreement: '服务条款',
        support_disclaimer: '免责声明',
        support_privacy: '隐私保护',
        support_fee: '费率标准',
        service: '更多服务',
        service_bots: '交易机器人',
        contact: '联系我们',
        contact_email: '客服邮箱'
    },
    common: {
        confirm: '确定',
        cancel: '取消',
        ok: '好的',
        yes: '是',
        no: '否',
        all: '所有'
    },
    home: {
        index: {
            slogan_1: '欢迎来到',
            slogan_2: '让您安心交易数字资产',
            start_1: '立即注册',
            start_2: '去交易',
            rank_popular: '主流币',
            rank_change_up: '涨幅榜',
            rank_change_down: '跌幅榜',
            rank_turnover: '成交榜',
            asset: '名称',
            last: '价格',
            change: '涨跌幅',
            turnover: '24小时成交',
            trade: '去交易',
            more: '查看更多行情',
            feature_title: '安全 稳定 可信',
            feature_desc: '本交易所和 OKX 共享流动性。欧易OKX提供数百种币币和合约交易对，以满足多样化的交易需求。 平台在 200 个国家和地区中有超过 2 千万用户，是全球领先的数字资产服务平台。',
            feature1: '用户国家分布',
            feature2: '用户数',
            feature3: '交易对',
            feature4: '服务支持',
            feature_label1: '顶级的风控体系',
            feature_label2: '极致的交易体验',
            feature_label3: '专业的服务团队',
            feature_value1: '基于区块链行业独创的风险特征体系 多维度全生命周期保障用户资产安全',
            feature_value2: '共享 OKX 世界顶尖的撮合引擎和交易深度',
            feature_value3: '甄选优质区块链项目 7x24h全球客服随时提供服务'
        },
        register: {
            title: '注册',
            phone: '手机号',
            email: '邮箱',
            phone_number: '请输入手机号',
            email_address: '请输入邮箱地址',
            password_requirement: '密码 (至少8个字符)',
            sign_up: '注册',
            sign_in: '登录',
            already_account: '已有账号？ ',
            agreement1: '注册账号即代表您同意了 ',
            agreement2: '服务条款',
            agreement3: ' 和 ',
            agreement4: '隐私政策与声明',
            agreement5: '。',
            verify_title: '验证',
            sent_code: '我们已经发送了验证码给 ',
            verify: '验证',
            resend_ask: '没收到任何验证码？ ',
            resend: '重新发送',
            back: '返回'
        },
        login: {}
    },
    market: {
        favorites: '自选',
        all: '全部',
        pair: '币对',
        price: '最新价格',
        change: '涨跌幅',
        h24low: '24小时最低',
        h24high: '24小时最高',
        h24volume: '24小时成交量',
        h24turnover: '24小时成交额',
        action: '操作',
        trade: '去交易',
        login_add_favorites: '登录后添加自选币对',
    },
    wallet: {
        overview: {
            overview: '资产总览',
            total: '总资产估值',
            my_assets: '资产分布',
            assets: '资产分布',
            accounts: '账户',
            no_assets: '没有资产',
            view_all: '查看全部'
        },
        funding: {
            funding: '资金账户',
            total: '资产估值',
            search: '搜索',
            hide_trivial: '隐藏余额小于1USD的币种',
            recent_transactions: '资产动态',
            crypto: '币种',
            balance: '余额',
            portfolio: '分布',
            no_assets: '暂无资产',
            no_transactions: '暂无记录'
        },
        trading: {
            trading: '交易账户',
            total: '资产估值',
            search: '搜索',
            hide_trivial: '隐藏余额<1USD的币种',
            recent_transactions: '资产动态',
            crypto: '币种',
            equity: '权益',
            portfolio: '分布',
            no_assets: '暂无资产',
            no_transactions: '暂无记录',
            trade: '交易'
        },
        order: {
            order: '订单中心',
            open_orders: '当前委托',
            order_history: '历史委托',
            funding_history: '资金账户账单',
            trading_history: '交易账户账单',
            order_type: '订单类型',
            order_type_limit: '限价单',
            order_type_market: '市价单',
            date: '日期',
            crypto: '币种',
            type: '账单类型',
            type_all: '所有',
            type_deposit: '充值',
            type_withdraw: '提币',
            type_transfer_in: '资金账户转入',
            type_transfer_out: '资金账户转出',
            order_time: '委托时间',
            order_instrument: '交易类型',
            order_symbol: '交易品种',
            order_side: '交易方向',
            order_price: '成交均价 | 委托价',
            order_volume: '已成交 | 委托总量',
            order_turnover: '已成交｜委托价值',
            order_fee: '手续费',
            order_status: '订单状态',
            order_action: '操作',
            amount: '数量',
            balance: '账户余额',
            note: '备注',
            no_data: '暂无记录'
        },
        fee: {
            fee: '手续费',
            spot: '币币交易',
            usdt_pairs: 'USDT/USDⓈ/Crypto 交易区',
            usdc_pairs: 'USDC 交易区',
            maker: '挂单成交手续费',
            taker: '吃单成交手续费'
        },
        deposit: {
            deposit: '充值',
            select_crypto: '选择需要充值的币种',
            select_chain: '选择{currencyText}充值网络',
            select_chain_ensure: '请确保您已选择与提币平台一致的网络进行充币，否则将造成资产损失。',
            next: '下一步',
            edit: '重新编辑',
            deposit_details: '充值详情',
            deposit_to: '将资金充入',
            deposit_address: '充值地址',
            deposit_crypto_ensure: '您只可发送{currencyText}至此地址，若发送其他数字货币，您将会失去这笔资金。',
            deposit_tag_ensure: '请务必同时复制地址及{currencyText}备注/标签，以确保成功充币。',
            minimum_deposit: '最小充值额',
            deposit_arrival: '充值到账',
            withdrawal_unlock: '提币解锁',
            confirmations: '次网络确认',
            deposits: '充值记录',
            crypto_only: '仅{currencyText}',
            time: '时间',
            address: '充值地址',
            crypto: '币种',
            amount: '充值数量',
            progress: '充值进度',
            status: '充值状态',
            no_deposit: '您暂时没有充值记录',
            open_history: '查看历史记录'
        },
        withdraw: {
            withdraw: '提币',
            select_crypto: '选择需要提取的币种',
            next: '下一步',
            edit: '重新编辑',
            withdraw_details: '提币详情',
            select_address: '地址/域名',
            select_chain: '选择{currencyText}提币网络',
            select_chain_ensure: '请确保充币平台支持您所选择的{currencyText}网络，否则将会造成资产损失',
            amount: '数量',
            available: '可用',
            max: '全部',
            h24_limit: '24小时限额',
            fees: '手续费',
            estimated_total: '预期到账',
            withdrawals: '提币记录',
            crypto_only: '仅{currencyText}',
            time: '时间',
            id: '流水号',
            address: '提币地址',
            txid: '交易ID',
            crypto: '币种',
            fee: '网络手续费',
            status: '状态',
            action: '操作',
            no_withdraw: '您暂时没有提币记录',
            open_history: '查看历史记录',
            confirm_title: '安全验证',
            confirm_ensure: '请确保以下资料正确',
            confirm_chain: '网络',
            confirm_address: '地址',
            confirm_amount: '数量',
            confirm_fees: '手续费',
            email_code: '邮箱验证码',
            sms_code: '短信验证码',
            notice_1: '我们在任何情况下都不会要求您向某一账户转账，也不会向您索要验证码。',
            notice_2: '请勿参与代买、洗钱、非法集资等违法行为，谨防网络诈骗。'
        },
        transfer: {
            transfer: '划转',
            crypto: '币种',
            from: '从',
            to: '到',
            amount: '数量',
            available: '可用',
            max: '全部',
            transfer_outs: '从资金账户转出',
            transfer_ins: '向资金账户转入',
            crypto_only: '仅{currencyText}',
            time: '时间',
            status: '状态',
            transfer_successful: '划转成功',
            no_transfer: '您暂时没有划转记录'
        }
    },
    account: {
        overview: {
            overview: '账户总览',
            nickname: '昵称',
            user_id: '用户ID',
            trade_fee_tier: '交易费用等级',
            email: '邮箱',
            phone: '手机号'
        },
        security: {
            security: '安全中心',
            password_title: '登录密码',
            password_desc: '通过设置登录密码，您将可以使用账号和登录密码直接登录',
            change_password: '修改密码',
            phone_title: '手机验证',
            phone_desc: '用于登录、提现、修改密码、安全设置时收取验证短信',
            change_phone: '修改手机号',
            email_title: '邮箱验证',
            email_desc: '您可以绑定一个常用邮箱，用于登录、找回密码、提币时的确认',
            change_email: '修改邮箱',
            login_history: '最近登录历史',
            time: '时间',
            device: '设备',
            ip: 'IP地址'
        },
        change_password: {
            title: '修改登录密码',
            current_password: '原密码',
            current_password_holder: '请输入原密码',
            new_password: '新密码',
            new_password_holder: '请输入新密码',
            new_password_again: '新密码确认',
            new_password_again_holder: '请输入新密码',
            email_code: '邮箱验证码',
            email_code_holder: '请输入邮箱验证码',
            sms_code: '短信验证码',
            sms_code_holder: '请输入短信验证码',
            send_code: '发送',
            notice: '我已知晓，在修改登录密码后如果在新设备登录此账号，将在 24 小时内暂时无法提币。',
            confirm: '确定',
            change_password_successfully: '密码修改成功'
        },
        change_phone: {
            title: '变更手机号',
            new_number: '新手机号',
            new_number_holder: '请输入新手机号',
            new_number_code: '新手机号验证码',
            new_number_code_holder: '请输入新手机号验证码',
            email_code: '邮箱验证码',
            email_code_holder: '请输入邮箱验证码',
            sms_code: '原手机号验证码',
            sms_code_holder: '请输入原手机号验证码',
            send_code: '发送',
            notice: '我已知晓，在修改手机号后如果在新设备登录此账号，将在 24 小时内暂时无法提币。',
            confirm: '确定',
            bind_phone_successfully: '手机号已更新'
        },
        change_email: {
            title: '变更邮箱',
            new_email: '新邮箱',
            new_email_holder: '请输入新邮箱',
            new_email_code: '新邮箱验证码',
            new_email_code_holder: '请输入新邮箱验证码',
            email_code: '原邮箱验证码',
            email_code_holder: '请输入原邮箱验证码',
            sms_code: '短信验证码',
            sms_code_holder: '请输入短信验证码',
            send_code: '发送',
            notice: '我已知晓，在修改邮箱后如果在新设备登录此账号，将在 24 小时内暂时无法提币。',
            confirm: '确定',
            bind_email_successfully: '邮箱已更新'
        },
        preference: {
            preferences: '偏好设置',
            language: '语言',
            chart_colors: '涨跌颜色',
            color_regular: '绿涨红跌',
            color_reverse: '红涨绿跌'
        }
    },
    spot: {
        base: {
            h24change: '24小时涨跌幅',
            h24low: '24h最低价',
            h24high: '24h最高价',
            h24volume: '24h成交量',
            h24turnover: '24h成交额',
            limit: '限价委托',
            market: '市价委托',
            order_book: '订单簿',
            last_trades: '最新成交',
            open_orders: '当前委托',
            order_history: '历史委托',
            order_symbol: '交易品种',
            order_time: '委托时间',
            order_side: '交易方向',
            order_price: '成交均价 | 委托价',
            order_volume: '已成交 | 委托总量',
            order_turnover: '已成交｜委托价值',
            order_fee: '手续费',
            order_status: '订单状态',
            no_records: '没有记录',
            current_only: '只展示当前品种'
        },
        margin: {},
        convert: {}
    },
    contract: {
        swap: {},
        futures: {},
        option: {}
    }
}