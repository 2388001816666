<template>
<div class="trade-fee">
    <div class="type-tabs">
        <div class="type-tab active" v-text="$t('wallet.fee.spot')"></div>
    </div>
    <div class="blocks">
        <div class="block">
            <div class="name" v-text="$t('wallet.fee.usdt_pairs')"></div>
            <div class="fees">
                <div class="fee">
                    <div class="role" v-text="$t('wallet.fee.maker') + ': '"></div>
                    <div class="value" v-text="abs.Util.formatNumber(usdtMaker, 2) + '%'"></div>
                </div>
                <div class="fee">
                    <div class="role" v-text="$t('wallet.fee.taker') + ': '"></div>
                    <div class="value" v-text="abs.Util.formatNumber(usdtTaker, 2) + '%'"></div>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="name" v-text="$t('wallet.fee.usdc_pairs')"></div>
            <div class="fees">
                <div class="fee">
                    <div class="role" v-text="$t('wallet.fee.maker') + ': '"></div>
                    <div class="value" v-text="abs.Util.formatNumber(usdcMaker, 2) + '%'"></div>
                </div>
                <div class="fee">
                    <div class="role" v-text="$t('wallet.fee.taker') + ': '"></div>
                    <div class="value" v-text="abs.Util.formatNumber(usdcTaker, 2) + '%'"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
export default {
    name: "UserAssetFee",
    data () {
        return {
            usdtMaker: 0,
            usdtTaker: 0,
            usdcMaker: 0,
            usdcTaker: 0
        };
    },
    mounted () {
        this.api.tradeFee(this, {
            instrumentType: this.abs.common.EInstrumentType.SPOT
        }, function (rspBody) {
            this.usdtMaker = Math.round(Math.abs(Number(rspBody.tradeFee.maker)) * 10000) / 100;
            this.usdtTaker = Math.round(Math.abs(Number(rspBody.tradeFee.taker)) * 10000) / 100;
            this.usdcMaker = Math.round(Math.abs(Number(rspBody.tradeFee.makerUSDC)) * 10000) / 100;
            this.usdcTaker = Math.round(Math.abs(Number(rspBody.tradeFee.takerUSDC)) * 10000) / 100;
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
    }
}
</script>

<style scoped>
.demo{}
.trade-fee .type-tabs {
    display: flex;
    border-bottom: 1px solid var(--sep-color);
}
.trade-fee .type-tabs .type-tab {
    height: 36px;
    line-height: 36px;
    margin-right: 32px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.trade-fee .type-tabs .type-tab.active {
    color: var(--text-strong);
    border-bottom-color: var(--text-strong);
}
.trade-fee .blocks .block {
    padding-top: 24px;
    padding-right: 48px;
}
.trade-fee .blocks .block .name {
    margin-bottom: 8px;
}
.trade-fee .blocks .block .fees {
    display: flex;
    align-items: center;
}
.trade-fee .blocks .block .fees .fee {
    margin-right: 32px;
    display: flex;
    align-items: center;
}
.trade-fee .blocks .block .fees .fee .role {
    margin-right: 8px;
    color: var(--text-strong);
}
.trade-fee .blocks .block .fees .fee .value {
    margin-right: 8px;
    color: var(--text-strong);
}
.demo{}
</style>