<template>
<div class="radiobox-field">
    <label class="input-label"><input class="radio" type="radio" :name="name" :checked="checked" @change="changeCheck" /><span class="input-mark"></span><span v-text="label"></span></label>
</div>
</template>

<script>
export default {
    name: "RadioboxField",
    props: {
        name: {
            type: String,
            default: () => {
                return '';
            }
        },
        label: {
            type: String,
            default: () => {
                return '';
            }
        },
        value: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE_VALUE: 'changeValue'
            },
            checked: false
        }
    },
    watch: {
        value (value) {
            this.checked = value;
        }
    },
    mounted () {
        this.checked = this.value || false;
    },
    methods: {
        changeCheck (e) {
            this.checked = e.target.checked;
            this.$emit(this.EVENTS.CHANGE_VALUE, this.checked);
        }
    }
}
</script>

<style scoped>
.input-label {
    padding-right:2px;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.input-label .radio {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
}
.input-label .input-mark {
    flex-shrink: 0;
    display: inline-block;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    border: 1px solid var(--text-muted);
    border-radius: 3px;
    background-color: transparent;
}
.input-label:hover .radio ~ .input-mark {
    box-shadow: 0 0 4px var(--text-strong);
}
.input-label .radio:checked ~ .input-mark {
    /*background-color: var(--positive);*/
}
.input-label .input-mark:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 4px;
    height: 8px;
    border: solid var(--text-strong);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
}
.input-label .radio:checked ~ .input-mark:after {
    display: block;
}
</style>