<template>
<div class="dropdown" :class="{'open':open}">
    <div class="mask" @click="clickMask"></div>
    <div class="control item" :style="(width ? 'width:'+width+'px;' : '') + (height ? 'min-height:'+height+'px' : '')" @click="clickControl">
        <span class="left" :class="{'align-left':textAlign===abs.base.EDirection.LEFT,'align-right':textAlign===abs.base.EDirection.RIGHT}"><span class="name" v-text="active ? active.text : ''"></span></span>
        <span class="right"><i class="icon chevron-down"></i></span>
    </div>
    <div class="menu">
        <div class="item" :class="{'selected':active && option.id===active.id}" :style="'justify-content:' + (textAlign===abs.base.EDirection.LEFT ? 'flex-start' : (textAlign===abs.base.EDirection.RIGHT ? 'flex-end' : 'center'))" v-for="(option, index) in options" :key="option.id" @click="clickOption(option, index)">
            <img class="icon" v-if="option.icon" :src="option.icon" alt="" />
            <span class="name" v-text="option.text"></span>
            <span class="attach" v-if="option.attach" v-text="option.attach"></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "DropDown",
    props: {
        value: {
            type: String,
            default: () => {
                return '';
            }
        },
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        textAlign: {
            type: String,
            default: () => {
                return 'center';
            }
        },
        width: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        height: {
            type: Number,
            default: () => {
                return 40;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE: 'change'
            },
            active: null,
            open: false
        };
    },
    watch: {
        value (value) {
            let active = null;
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].id === value) {
                    active = this.options[i];
                    break;
                }
            }
            this.active = active;
        }
    },
    mounted () {
        let active = null;
        for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].id === this.value) {
                active = this.options[i];
                break;
            }
        }
        this.active = active;
    },
    methods: {
        clickControl () {
            this.open = !this.open;
        },
        clickMask () {
            this.open = false;
        },
        clickOption (option, index) {
            this.active = option;
            this.open = false;
            this.$emit(this.EVENTS.CHANGE, this.active.id, this.active, index);
        }
    }
}
</script>

<style scoped>
.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    display: none;
    z-index: 1;
}
.dropdown.open .mask {
    display: block;
}
.dropdown {
    width: 100%;
}
.dropdown .control {
    width: 100%;
    background-color: var(--background-secondary);
    border: 1px solid var(--background-tertiary);
    border-radius: 4px;
}
.dropdown.open .control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.dropdown .item {
    min-height: 40px;
    padding: 0 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropdown .item:hover {
    background-color: var(--dropdown-item-hover);
}
.dropdown .item.selected {
    background: var(--background-tertiary);
}
.dropdown .item .left {
    flex-grow: 1;
    text-align: center;
}
.dropdown .item .left.align-left {
    text-align: left;
}
.dropdown .item .left.align-right {
    text-align: right;
}
.dropdown .item .right {
    margin-left: 4px;
    display: flex;
    align-items: center;
}
.dropdown .item .right .icon {
    width: 12px;
    height: 12px;
    color: var(--text-muted);
    background-color: var(--text-muted);
    display: inline-block;
}
.dropdown .item .name {
    color: var(--text-default);
}
.dropdown.open .menu {
    display: block;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.dropdown .menu {
    position: absolute;
    width: 100%;
    max-height: 360px;
    box-shadow: var(--dropdown-menu-shadow) 0 1px 5px 0;
    background-color: var(--background-primary);
    border: 1px solid var(--background-tertiary);
    border-radius: 4px;
    overflow: hidden auto;
    z-index: 1;
    display: none;
}
.dropdown .menu .item {
    padding-right: 24px;
}
.dropdown .menu .item .icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}
.dropdown .menu .item .name {
    margin-right: 12px;
}
.dropdown .menu .item .attach {
    color: var(--text-secondary);
    font-size: 12px;
}
</style>