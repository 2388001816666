<template>
<div class="action-panel transfer-panel">
    <div class="title" v-text="$t('wallet.transfer.transfer')"></div>
    <div class="step ending" style="padding-left:0">
        <div class="block" style="padding:0">
            <div class="sub-label" v-text="$t('wallet.transfer.crypto')"></div>
            <currency-picker class="field" :text-align="'left'" @changeValue="changeCurrency"></currency-picker>
            <div class="account-exchange">
                <div class="account">
                    <div class="acc-label" v-text="$t('wallet.transfer.from')"></div>
                    <div class="acc-field" v-text="type===TYPES.OUT ? $t('wallet.funding.funding') : $t('wallet.trading.trading')"></div>
                </div>
                <div class="ex"><i class="icon exchange-alt" @click="clickExchangeType"></i></div>
                <div class="account">
                    <div class="acc-label" v-text="$t('wallet.transfer.to')"></div>
                    <div class="acc-field" v-text="type===TYPES.IN ? $t('wallet.funding.funding') : $t('wallet.trading.trading')"></div>
                </div>
            </div>
            <div class="sub-label" v-text="$t('wallet.transfer.amount')"></div>
            <div class="field"><label class="holder"><input class="text" type="text" v-model="amount" /><span class="unit" v-text="currencyText"></span></label></div>
            <div class="amount-available"><span class="text" v-text="$t('wallet.transfer.available') + ': ' + amountAvailable + ' ' + currencyText"></span><a class="button link-button" @click="clickMaxAvailable" v-text="$t('wallet.transfer.max')"></a></div>
            <div class="next"><a class="button primary-button" @click="clickConfirm" v-text="$t('wallet.transfer.transfer')"></a></div>
        </div>
    </div>
    <div class="history">
        <div class="label-tabs">
            <div class="label label-tab" :class="{'active':orderType===TYPES.OUT}" @click="clickHistoryOrderType(TYPES.OUT)" v-text="$t('wallet.transfer.transfer_outs')"></div>
            <div class="label label-tab" :class="{'active':orderType===TYPES.IN}" @click="clickHistoryOrderType(TYPES.IN)" v-text="$t('wallet.transfer.transfer_ins')"></div>
        </div>
        <div class="only" v-if="currency"><checkbox-field :label="$t('wallet.transfer.crypto_only', {currencyText:currencyText})" :value="currencyOnly" @changeValue="changeCurrencyOnly"></checkbox-field></div>
        <div class="list">
            <div class="head">
                <div class="row">
                    <div class="col crypto" v-text="$t('wallet.transfer.crypto')"></div>
                    <div class="col amount" v-text="$t('wallet.transfer.amount')"></div>
                    <div class="col from" v-text="$t('wallet.transfer.from')"></div>
                    <div class="col to" v-text="$t('wallet.transfer.to')"></div>
                    <div class="col time" v-text="$t('wallet.transfer.time')"></div>
                    <div class="col status" v-text="$t('wallet.transfer.status')"></div>
                </div>
            </div>
            <div class="body body-tab" :class="{'active':orderType===TYPES.OUT}">
                <div class="row" v-for="order in outOrderList" :key="order.billId">
                    <div class="col crypto" v-text="order.ccy"></div>
                    <div class="col amount" v-text="abs.Util.formatNumber(order.amount, baseLotSize)"></div>
                    <div class="col from" v-text="$t('wallet.funding.funding')"></div>
                    <div class="col to" v-text="$t('wallet.trading.trading')"></div>
                    <div class="col time" v-text="$d(order.time, 'long')"></div>
                    <div class="col status" v-text="$t('wallet.transfer.transfer_successful')"></div>
                </div>
            </div>
            <div class="body body-tab" :class="{'active':orderType===TYPES.IN}">
                <div class="row" v-for="order in inOrderList" :key="order.billId">
                    <div class="col crypto" v-text="order.ccy"></div>
                    <div class="col amount" v-text="abs.Util.formatNumber(order.amount, baseLotSize)"></div>
                    <div class="col from" v-text="$t('wallet.trading.trading')"></div>
                    <div class="col to" v-text="$t('wallet.funding.funding')"></div>
                    <div class="col time" v-text="$d(order.time, 'long')"></div>
                    <div class="col status" v-text="$t('wallet.transfer.transfer_successful')"></div>
                </div>
            </div>
            <div class="empty" v-if="(orderType===TYPES.OUT && !outOrderList.length) || (orderType===TYPES.IN && !inOrderList.length)" v-text="$t('wallet.transfer.no_transfer')"></div>
        </div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import CurrencyPicker from "@/components/CurrencyPicker";
import CheckboxField from "@/components/CheckboxField";
export default {
    name: "UserAssetTransfer",
    components: {CheckboxField, CurrencyPicker},
    props: {
        baseLotSize: {
            type: Number,
            default: () => {return 8;}
        }
    },
    data () {
        return {
            TYPES: {
                IN: '130',
                OUT: '131'
            },
            currency: null,
            type: '131',
            amount: '',
            fundingAssetValueList: [],
            tradingAssetValueList: [],
            orderType: '131',
            currencyOnly: false,
            outOrderList: [],
            inOrderList: []
        };
    },
    computed: {
        currencyId () {
            return this.currency ? this.currency.id : '';
        },
        currencyText () {
            return this.currency ? this.currency.text : '';
        },
        amountAvailable () {
            if (!this.currency) {
                return 0;
            }
            if (this.type === this.TYPES.OUT) {
                for (let i = 0; i < this.fundingAssetValueList.length; i++) {
                    let asset = this.fundingAssetValueList[i];
                    if (asset.ccy === this.currency.id) {
                        return asset.value;
                    }
                }
            }
            else if (this.type === this.TYPES.IN) {
                for (let i = 0; i < this.tradingAssetValueList.length; i++) {
                    let asset = this.tradingAssetValueList[i];
                    if (asset.ccy === this.currency.id) {
                        return asset.value;
                    }
                }
            }
            return 0;
        }
    },
    mounted () {
        this.assetBalances();
        this.accountBalance();
        this.loadAssetBillList();
    },
    methods: {
        changeCurrency (currency) {
            this.currency = currency;
            this.amount = '';
            this.loadAssetBillList();
        },
        clickMaxAvailable () {
            this.amount = this.amountAvailable;
        },
        changeCurrencyOnly (currencyOnly) {
            this.currencyOnly = currencyOnly;
            this.loadAssetBillList();
        },
        clickExchangeType () {
            this.type = this.type === this.TYPES.OUT ? this.TYPES.IN : this.TYPES.OUT;
            this.amount = '';
        },
        clickConfirm () {
            this.assetTransfer();
        },
        clickHistoryOrderType (orderType) {
            this.orderType = orderType;
        },
        loadAssetBillList () {
            this.assetBillList(this.currencyOnly ? this.currency.id : '', this.TYPES.OUT, null, null, 50, function (billList) {
                this.outOrderList = billList;
            });
            this.assetBillList(this.currencyOnly ? this.currency.id : '', this.TYPES.IN, null, null, 50, function (billList) {
                this.inOrderList = billList;
            });
        },
        assetBalances () {
            this.api.assetBalances(this, null, function (rspBody) {
                let fundingAssetValueList = [];
                for (let i = 0; i < rspBody.balances.length; i++) {
                    let balance = rspBody.balances[i];
                    fundingAssetValueList.push({
                        ccy: balance.ccy,
                        ccyLogo: balance.ccyLogo,
                        value: Number(balance.bal)
                    });
                }
                this.fundingAssetValueList = fundingAssetValueList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        accountBalance () {
            this.api.accountBalance(this, null, function (rspBody) {
                let tradingAssetValueList = [];
                for (let i = 0; i < rspBody.balance.details.length; i++) {
                    let detail = rspBody.balance.details[i];
                    tradingAssetValueList.push({
                        ccy: detail.ccy,
                        ccyLogo: detail.ccyLogo,
                        value: Number(detail.availBal)
                    });
                }
                this.tradingAssetValueList = tradingAssetValueList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetTransfer () {
            this.api.assetTransfer(this, {
                currency: this.currencyId,
                fromWalletType: (this.type === this.TYPES.OUT ? this.abs.wallet.EWalletType.FUNDING : this.abs.wallet.EWalletType.TRADING),
                toWalletType: (this.type === this.TYPES.IN ? this.abs.wallet.EWalletType.FUNDING : this.abs.wallet.EWalletType.TRADING),
                amount: Number(this.amount) || 0
            }, function () {
                this.abs.Toast.show(this.$t('transfer.completed'));
                this.amount = '';
                this.assetBalances();
                this.accountBalance();
                this.loadAssetBillList();
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetBillList (currency, billType, startTime, endTime, limit, cb) {
            this.api.assetBillList(this, {
                currency: currency,
                billType: billType,
                startTime: (startTime ? startTime.getTime() : 0),
                endTime: (endTime ? endTime.getTime() : 0),
                limit: limit
            }, function (rspBody) {
                let billList = [];
                for (let i = 0; i < rspBody.billList.length; i++) {
                    let bill = rspBody.billList[i];
                    billList.push({
                        billId: bill.billId,
                        time: new Date(Number(bill.ts)),
                        type: this.abs.common.ECashBillTypes[bill.type] || '',
                        ccy: bill.ccy,
                        amount: Math.abs(Number(bill.balChg) || 0),
                        status: this.abs.wallet.ETransferStatus['success']
                    });
                }
                cb.call(this, billList);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.action-panel .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 24px;
}
.action-panel .step {
    padding-left: 18px;
    display: none;
}
.action-panel .step.doing, .action-panel .step.done, .action-panel .step.ending {
    display: block;
}
.action-panel .step .num {
    position: absolute;
    left: 6px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-panel .step.doing .num, .action-panel .step.ending .num {
    border: 1px solid var(--text-default);
}
.action-panel .step .num.num-doing {
    color: var(--text-muted);
    border: 1px solid var(--text-muted);
}
.action-panel .step.done .num.num-doing {
    display: none;
}
.action-panel .step.done .num {
    color: var(--background-primary);
    background-color: var(--text-default);
}
.action-panel .step .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    padding: 0 18px;
}
.action-panel .step .label.label-doing {
    color: var(--text-muted);
}
.action-panel .step.done .label.label-doing {
    display: none;
}
.action-panel .step .block {
    width: 460px;
    padding: 24px 18px;
}
.action-panel .step.doing .block {
    border-left: 1px dashed var(--text-tertiary);
}
.action-panel .step.done .block {
    border-left: 1px solid var(--text-default);
}
.action-panel .step.doing .block.block-done {
    display: none;
}
.action-panel .step.done .block.block-doing {
    display: none;
}
.action-panel .step .block .sub-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    margin-top: 36px;
    margin-bottom: 8px;
}
.action-panel .step .block .notice {
    margin: 8px 0;
    padding: 8px 8px 8px 32px;
    border-radius: 4px;
    background-color: var(--background-secondary);
}
.action-panel .step .block .notice .icon {
    position: absolute;
    top: 10px;
    left: 8px;
}
.action-panel .step .block .notice .text {
    color: var(--text-strong);
    line-height: 20px;
}
.action-panel .step .block .next {
    margin-top: 36px;
}
.action-panel .step .block .next .button {
    height: 40px;
    border-radius: 20px;
}
.action-panel .step .block .crypto-chain {
    color: var(--text-strong);
    font-weight: 500;
    line-height: 20px;
}
.action-panel .step .block .back {
    position: absolute;
    top: 24px;
    right: 18px;
}
.action-panel .step .block .back .button {
    height: 20px;
    color: var(--text-active);
}
.action-panel .step .block .address {
    padding: 8px;
    border-radius: 4px;
    background-color: var(--background-secondary-alt);
    display: flex;
    align-items: center;
}
.action-panel .step .block .address .nil {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.action-panel .step .block .address .text {
    flex: 1;
    line-height: 20px;
    background-color: var(--background-secondary-alt);
    word-wrap: break-word;
    overflow: hidden;
}
.action-panel .step .block .address .icon {
    flex-shrink: 0;
    margin-left: 8px;
    cursor: pointer;
}
.action-panel .step .block .field .holder {
    border-radius: 3px;
    background-color: var(--background-tertiary);
    display: flex;
    align-items: center;
}
.action-panel .step .block .field .holder .unit {
    padding: 0 8px;
}
.action-panel .step .block .amount-available {
    padding: 0 8px;
    border-radius: 3px;
    background-color: var(--background-secondary-alt);
    display: flex;
    align-items: center;
}
.action-panel .step .block .amount-available .text {
    font-size: 12px;
    line-height: 30px;
}
.action-panel .step .block .amount-available .button {
    height: 30px;
}
.action-panel .step .block .amount-limit {
    font-size: 12px;
    line-height: 30px;
}
.action-panel .step .block .est {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
}
.action-panel .step .block .est .est-label {
    color: var(--text-secondary);
}
.action-panel .step .block .est .est-value {
    color: var(--text-strong);
}
.action-panel .step .block .account-exchange {
    display: flex;
    align-items: center;
}
.action-panel .step .block .account-exchange .account {
    flex: 1;
}
.action-panel .step .block .account-exchange .account .acc-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    margin-top: 36px;
    margin-bottom: 8px;
}
.action-panel .step .block .account-exchange .account .acc-field {
    height: 40px;
    padding: 0 8px;
    background-color: var(--background-secondary);
    border: 1px solid var(--background-tertiary);
    border-radius: 3px;
    display: flex;
    align-items: center;
}
.action-panel .step .block .account-exchange .ex {
    margin: 64px 8px 0 8px;
    cursor: pointer;
}
.action-panel .step .block .memos {
    margin-top: 64px;
    padding: 16px;
    background-color: var(--background-secondary);
    border-radius: 4px;
}
.action-panel .step .block .memos .memo {
    padding: 8px 0;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}
.action-panel .step .block .memos .memo .memo-desc {
    color: var(--text-secondary);
}
.action-panel .step .block .memos .memo .memo-value {
    color: var(--text-strong);
}

.action-panel .history {
    margin-top: 36px;
}
.action-panel .history .label-tabs {
    display: flex;
    align-items: center;
}
.action-panel .history .label {
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
}
.action-panel .history .label.label-tab {
    margin-right: 40px;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.action-panel .history .label.label-tab.active {
    border-bottom-color: var(--text-default);
}
.action-panel .history .only {
    position: absolute;
    top: 0;
    left: 260px;
    line-height: 20px;
}
.action-panel .history .list {
    margin-top: 16px;
}
.action-panel .history .row {
    height: 52px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.action-panel .history .body.body-tab {
    display: none;
}
.action-panel .history .body.body-tab.active {
    display: block;
}
.action-panel .history .body .row:hover {
    background-color: var(--background-hover);
}
.action-panel .history .head .col {
    color: var(--text-tertiary);
}
.action-panel .history .empty {
    height: 180px;
    color: var(--text-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-panel .history .show-history {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 20px;
}
.action-panel .history .show-history .button {
    min-width: 0;
    height: 20px;
    color: var(--text-active);
}

.deposit-panel .history .col.time {
    width: 20%;
}
.deposit-panel .history .col.address {
    width: 40%;
}
.deposit-panel .history .col.crypto {
    width: 10%;
}
.deposit-panel .history .col.amount {
    width: 10%;
}
.deposit-panel .history .col.progress {
    width: 10%;
}
.deposit-panel .history .col.status {
    width: 10%;
    text-align: right;
}

.transfer-panel .history .col.crypto {
    width: 20%;
}
.transfer-panel .history .col.amount {
    width: 20%;
}
.transfer-panel .history .col.from {
    width: 15%;
}
.transfer-panel .history .col.to {
    width: 15%;
}
.transfer-panel .history .col.time {
    width: 20%;
}
.transfer-panel .history .col.status {
    width: 10%;
    text-align: right;
}
</style>