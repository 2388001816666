import api from '@/assets/js/api';

let _ws = null;
let _uticket = false;
let _channelObservers = [];

export default {
    connect () {
        if (_ws) {
            return;
        }
        _ws = api.websocket();
        _ws.onopen = () => {
            setInterval(() => {
                if (_ws.readyState === WebSocket.OPEN) {
                    _ws.send('ping');
                }
            }, 20000);
        };
        _ws.onmessage = (e) => {
            if (e.data === 'pong') {
                return;
            }
            let content = JSON.parse(e.data);
            if (content.event && content.event === 'login' && content.code === '0') {
                _uticket = true;
                return;
            }
            if (content.event || !content.arg || !content.arg.channel) {
                return;
            }
            let observers = _channelObservers[content.arg.channel];
            if (observers) {
                for (let name in observers) {
                    observers[name](content);
                }
            }
        };
    },
    login (apikey, passphrase, timestamp, sign) {
        let send = function () {
            _ws.send(JSON.stringify({
                'op': 'login',
                'args': [{
                    'apiKey': apikey,
                    'passphrase': passphrase,
                    'timestamp': timestamp,
                    'sign': sign
                }]
            }));
        };
        if (_ws.readyState === WebSocket.OPEN) {
            send();
        }
        else {
            let timer = setInterval(() => {
                if (_ws.readyState === WebSocket.OPEN) {
                    send();
                    clearInterval(timer);
                }
            }, 500);
        }
    },
    observe (channel, name, observer) {
        if (!channel || !name || !observer) {
            return;
        }

        let observers = _channelObservers[channel];
        if (!observers) {
            observers = [];
            _channelObservers[channel] = observers;
        }
        observers[name] = observer;
    },
    subscribe (options, name, observer, uticket) {
        if (!options || !options.channel) {
            return;
        }

        if (name && observer) {
            this.observe(options.channel, name, observer);
        }

        let send = function () {
            _ws.send(JSON.stringify({
                'op': 'subscribe',
                'args': [options]
            }));
        };
        if (_ws.readyState === WebSocket.OPEN && (!uticket || (uticket && _uticket))) {
            send();
        }
        else {
            let timer = setInterval(() => {
                if (_ws.readyState === WebSocket.OPEN && (!uticket || (uticket && _uticket))) {
                    send();
                    clearInterval(timer);
                }
            }, 500);
        }
    },
    unobserve (channel, name) {
        if (!channel || !name) {
            return;
        }

        let observers = _channelObservers[channel];
        if (observers) {
            delete observers[name];
            if (Object.keys(observers).length === 0) {
                delete _channelObservers[channel];
            }
        }
    },
    unsubscribe (options, name, uticket) {
        if (!options || !options.channel) {
            return;
        }

        let send = function () {
            _ws.send(JSON.stringify({
                'op': 'unsubscribe',
                'args': [options]
            }));
        };
        if (_ws.readyState === WebSocket.OPEN && (!uticket || (uticket && _uticket))) {
            send();
        }
        else {
            let timer = setInterval(() => {
                if (_ws.readyState === WebSocket.OPEN && (!uticket || (uticket && _uticket))) {
                    send();
                    clearInterval(timer);
                }
            }, 500);
        }

        if (name) {
            this.unobserve(options.channel, name);
        }
    }
}