export default {
    component: {
        inst_picker: {
            favorites: 'Favorites',
            all: 'All',
            pair: 'Pair',
            last: 'Last price',
            change: 'Change'
        },
        side_tab: {
            buy: 'Buy',
            sell: 'Sell'
        },
        price_field: {
            market: 'Place order at market price'
        },
        count_field: {
            total: 'Total'
        },
        amount_field: {
            amount: 'Amount'
        },
        available_field: {
            available: 'Available'
        },
        order_builder: {
            price: 'Price',
            amount: 'Amount',
            total: 'Total',
            tick_size: 'Min',
            min_size: 'Min',
            buy: 'Buy',
            sell: 'Sell',
            order_confirmation: 'Order confirmation',
            market_price: 'Market price',
            type: 'Type',
            type_limit: 'Limit',
            type_market: 'Market'
        },
        order_books: {
            price: 'Price',
            amount: 'Amount',
            total: 'Total'
        },
        trade_history: {
            price: 'Price',
            amount: 'Amount',
            time: 'Time'
        },
        trade_chart: {
            min1: '1m',
            min5: '5m',
            min15: '15m',
            min30: '30m',
            hour1: '1h',
            hour2: '2h',
            hour4: '4h',
            hour6: '6h',
            hour12: '12h',
            day1: '1d',
            week1: '1w',
            month1: '1M',
            view_original: 'Original',
            view_depth: 'Depth',
            open: 'O',
            high: 'H',
            low: 'L',
            close: 'C',
            change: 'Change',
            range: 'Range'
        }
    },
    top_bar: {
        market: 'Markets',
        convert: 'Convert',
        spot: 'Spot',
        margin: 'Margin',
        swap: 'Perpetual',
        futures: 'Futures',
        option: 'Options',
        asset: 'Assets',
        order: 'Order center',
        register: 'Sign up',
        login: 'Log in',
        logout: 'Log out'
    },
    bottom_bar: {
        risk: 'Trading cryptocurrency involves significant risk. You should ensure that you fully understand the risk involved . We shall not held liable to any gain or loss of your trades.',
        about: 'About Us',
        about_company: 'Our Company',
        support: 'Customer Support',
        support_agreement: 'Terms of Service',
        support_disclaimer: 'Disclaimer',
        support_privacy: 'Privacy Policy',
        support_fee: 'Fee Standard',
        service: 'More Services',
        service_bots: 'Trading Bots',
        contact: 'Contact Us',
        contact_email: 'Email'
    },
    common: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        ok: 'Ok',
        yes: 'Yes',
        no: 'No',
        all: 'All'
        /*action: 'Action',
        search: 'Search',
        all: 'All',
        crypto: 'Crypto',
        pair: 'Pair',
        price: 'Price',
        amount: 'Amount',
        total: 'Total',
        change: 'Change',
        range: 'Range',
        h24low: '24h low',
        h24high: '24h high',
        h24volume: '24h volume',
        h24turnover: '24h turnover',
        tick_size: 'Min',
        min_size: 'Min',
        buy: 'Buy',
        sell: 'Sell',
        order_type: {
            limit: 'Limit',
            market: 'Market'
        },
        bill_type: {
            deposit: 'Deposit',
            withdraw: 'Withdrawal',
            transfer_in: 'Transfer in',
            transfer_out: 'Transfer out'
        },
        volume_check: {
            //amount: 'Amount',
            //total: 'Total'
        },
        order_confirmation: 'Order confirmation'*/
    },
    home: {
        index: {
            slogan_1: 'Welcome to',
            slogan_2: 'The safe place to trade your digital assets',
            start_1: 'Get started',
            start_2: 'Go to trade',
            rank_popular: 'Popular assets',
            rank_change_up: 'Gainers ranking',
            rank_change_down: 'Losers ranking',
            rank_turnover: 'Turnover ranking',
            asset: 'Asset',
            last: 'Last price',
            change: 'Change',
            turnover: '24h vol',
            trade: 'Trade',
            more: 'View more cryptocurrency prices',
            feature_title: 'Safe · Stable · Reliable',
            feature_desc: 'This platform and OKX share liquidity. OKX provides hundreds of crypto and futures trading pairs to meet the diverse needs of traders. We\'re one of the world\'s leading digital asset trading platforms, with millions of users across more than 200 countries and regions.',
            feature1: 'Countries',
            feature2: 'Traders',
            feature3: 'Trading pairs',
            feature4: 'Online support',
            feature_label1: 'Leading risk-control system',
            feature_label2: 'The ultimate trade experience',
            feature_label3: 'Professional service team',
            feature_value1: 'Based on the unique risk characteristic system of the blockchain industry, the multi-dimensional and full life cycle guarantees the safety of user funds',
            feature_value2: 'Share OKX\'s world\'s top matching engine and transaction depth',
            feature_value3: 'Select high-quality blockchain projects and enjoy 24/7 global customer service'
        },
        register: {
            title: 'Sign up',
            phone: 'Phone',
            email: 'Email',
            phone_number: 'Phone number',
            email_address: 'Email address',
            password_requirement: 'Password (at least 8 characters)',
            sign_up: 'Sign up',
            sign_in: 'Sign in',
            already_account: 'Already have an account? ',
            agreement1: 'By creating an account, I agree to ',
            agreement2: 'terms of services',
            agreement3: ' and ',
            agreement4: 'privacy policy',
            agreement5: '.',
            verify_title: 'Verify',
            sent_code: 'We\'ve sent a code to ',
            verify: 'Verify',
            resend_ask: 'Didn\'t receive anything? ',
            resend: 'Resend',
            back: 'Back'
        },
        login: {
            title: 'Sign in',
            phone: 'Phone',
            email: 'Email',
            phone_number: 'Phone number',
            email_address: 'Email address',
            password: 'Password',
            sign_up: 'Sign up',
            sign_in: 'Sign in',
            no_account: 'Don\'t have an account? ',
        }
    },
    market: {
        favorites: 'Favorites',
        all: 'All',
        pair: 'Pair',
        price: 'Price',
        change: 'Change',
        h24low: '24h low',
        h24high: '24h high',
        h24volume: '24h volume',
        h24turnover: '24h turnover',
        action: 'Action',
        trade: 'Trade',
        login_add_favorites: 'Login and add favorites',
    },
    wallet: {
        overview: {
            overview: 'Overview',
            total: 'Est. total value',
            my_assets: 'My assets',
            assets: 'Assets',
            accounts: 'Accounts',
            no_assets: 'No assets',
            view_all: 'Go to all'
        },
        funding: {
            funding: 'Funding',
            total: 'Est. total value',
            search: 'Search',
            hide_trivial: 'Hide assets < 1USD',
            recent_transactions: 'Recent transactions',
            crypto: 'Crypto',
            balance: 'Balance',
            portfolio: 'Portfolio',
            no_assets: 'No assets',
            no_transactions: 'No transactions'
        },
        trading: {
            trading: 'Trading',
            total: 'Est. total value',
            search: 'Search',
            hide_trivial: 'Hide assets < 1USD',
            recent_transactions: 'Recent transactions',
            crypto: 'Crypto',
            equity: 'Equity',
            portfolio: 'Portfolio',
            no_assets: 'No assets',
            no_transactions: 'No transactions',
            trade: 'Trade'
        },
        order: {
            order: 'Order center',
            open_orders: 'Open orders',
            order_history: 'Order history',
            funding_history: 'Funding account history',
            trading_history: 'Trading account history',
            order_type: 'Order type',
            order_type_limit: 'Limit',
            order_type_market: 'Market',
            date: 'Date',
            crypto: 'Crypto',
            type: 'Type',
            type_all: 'All',
            type_deposit: 'Deposit',
            type_withdraw: 'Withdraw',
            type_transfer_in: 'Transfer in',
            type_transfer_out: 'Transfer out',
            order_time: 'Order time',
            order_instrument: 'Instrument',
            order_symbol: 'Symbol',
            order_side: 'Side',
            order_price: 'Fill | Order price',
            order_volume: 'Filled | Total',
            order_turnover: 'Filled | Order value',
            order_fee: 'Fee',
            order_status: 'Status',
            order_action: 'Action',
            amount: 'Amount',
            balance: 'Balance',
            fee: 'Fee',
            note: 'Note',
            no_data: 'No data found'
        },
        fee: {
            fee: 'Fees',
            spot: 'Spot',
            usdt_pairs: 'USDT/USDⓈ/Crypto pairs',
            usdc_pairs: 'USDC pairs',
            maker: 'Maker',
            taker: 'Taker'
        },
        deposit: {
            deposit: 'Deposit',
            select_crypto: 'Select crypto to deposit',
            select_chain: 'Select {currencyText} deposit network',
            select_chain_ensure: 'Ensure you have also selected the same network as the platform you\'re withdrawing from, otherwise you\'ll lose your assets.',
            next: 'Next',
            edit: 'Edit',
            deposit_details: 'Deposit details',
            deposit_to: 'Deposit to',
            deposit_address: 'address',
            deposit_crypto_ensure: 'Only send {currencyText} to this address, you will lose your assets if you send any other crypto.',
            deposit_tag_ensure: 'It is required to copy both the {currencyText} address and tag/memo to successfully complete this deposit.',
            minimum_deposit: 'Minimum deposit',
            deposit_arrival: 'Deposit arrival',
            withdrawal_unlock: 'Withdrawal unlock',
            confirmations: 'confirmations',
            deposits: 'Deposits',
            crypto_only: '{currencyText} only',
            time: 'Time',
            address: 'Deposit address',
            crypto: 'Crypto',
            amount: 'Amount',
            progress: 'Progress',
            status: 'Status',
            no_deposit: 'You haven\'t deposited anything yet',
            open_history: 'Open history'
        },
        withdraw: {
            withdraw: 'Withdraw',
            select_crypto: 'Select crypto to withdraw',
            next: 'Next',
            edit: 'Edit',
            withdraw_details: 'Withdrawal details',
            select_address: 'address/domain',
            select_chain: 'Select the {currencyText} withdrawal network',
            select_chain_ensure: 'Ensure the {currencyText} network you have selected is supported by the depositing platform, otherwise you will lose your assets.',
            amount: 'Amount',
            available: 'Available',
            max: 'Max',
            h24_limit: '24h limit',
            fees: 'Fees',
            estimated_total: 'Estimated total',
            withdrawals: 'Withdrawals',
            crypto_only: '{currencyText} only',
            time: 'Time',
            id: 'ID',
            address: 'Address',
            txid: 'TXID',
            crypto: 'Crypto',
            fee: 'Fee',
            status: 'Status',
            action: 'Action',
            no_withdraw: 'You haven\'t made any withdrawals yet',
            open_history: 'Open history',
            confirm_title: 'Withdrawal verification',
            confirm_ensure: 'Ensure all the information below is correct',
            confirm_chain: 'Network',
            confirm_address: 'Address',
            confirm_amount: 'Amount',
            confirm_fees: 'Fees',
            email_code: 'Email code',
            sms_code: 'SMS code',
            send_code: 'Send code',
            notice_1: 'We\'ll never ask you to transfer funds to another account',
            notice_2: 'Beware of fraud and refrain from participating in any illegal activities such as proxy purchases, money laundering, and illegal fundraising'
        },
        transfer: {
            transfer: 'Transfer',
            crypto: 'Crypto',
            from: 'From',
            to: 'To',
            amount: 'Amount',
            available: 'Available',
            max: 'Max',
            transfer_outs: 'Transfer outs',
            transfer_ins: 'Transfer ins',
            crypto_only: '{currencyText} only',
            time: 'Time',
            status: 'Status',
            transfer_successful: 'Transfer successful',
            no_transfer: 'You haven\'t made any transfers yet'
        }
        /*assets: 'Assets',
        overview: 'Overview',
        funding: 'Funding',
        trading: 'Trading',
        order_center: 'Order center',
        fees: 'Fees',
        estimated_total_value: 'Estimated total value',
        my_assets: 'My assets',
        accounts: 'Accounts',
        recent_transactions: 'Recent transactions',
        equity: 'Equity',
        portfolio: '% of portfolio',
        hide_mini_assets: 'Hide assets < 1 USD',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        transfer: 'Transfer',
        convert: 'Convert',
        trade: 'trade',
        no_records_found: 'No records found',
        funding_account_history: 'Funding account history',
        usdt_pairs: 'USDT/USDⓈ/Crypto pairs',
        usdc_pairs: 'USDC pairs',
        maker_fee: 'Maker fee',
        taker_fee: 'Taker fee'*/
    },
    account: {
        overview: {
            overview: 'Overview',
            nickname: 'Nickname',
            user_id: 'User ID',
            trade_fee_tier: 'Trade fee tier',
            email: 'Email',
            phone: 'Phone'
        },
        security: {
            security: 'Security center',
            password_title: 'Login password',
            password_desc: 'Use this password for account login',
            change_password: 'Change password',
            phone_title: 'Mobile verification',
            phone_desc: 'Get SMS or voice codes for login, withdrawals, password changes and settings confirmations',
            change_phone: 'Change mobile number',
            email_title: 'Email verification',
            email_desc: 'Use this email for login, password updates and withdrawal notifications',
            change_email: 'Change email',
            login_history: 'Login history',
            time: 'Time',
            device: 'Device',
            ip: 'IP address'
        },
        change_password: {
            title: 'Change login password',
            current_password: 'Current password',
            current_password_holder: 'Enter your current password',
            new_password: 'New password',
            new_password_holder: 'Enter your new password',
            new_password_again: 'Confirm new password',
            new_password_again_holder: 'Enter your new password again',
            email_code: 'Email code',
            email_code_holder: 'Enter email code',
            sms_code: 'SMS code',
            sms_code_holder: 'Enter SMS code',
            send_code: 'Send code',
            notice: 'The withdrawal function will be suspended for 24 hours if you log in with new devices after you have changed the login password',
            confirm: 'Confirm',
            change_password_successfully: 'Change password successfully'
        },
        change_phone: {
            title: 'Change phone number',
            new_number: 'New number',
            new_number_holder: 'Enter code sent to new number',
            new_number_code: 'SMS code for new number',
            new_number_code_holder: '',
            email_code: 'Code for email',
            email_code_holder: 'Enter code sent to email',
            sms_code: 'SMS code for original number',
            sms_code_holder: 'Enter SMS code sent to original number',
            send_code: 'Send code',
            notice: 'For your security, you can\'t withdraw funds within 24 hours after changing your number',
            confirm: 'Confirm',
            bind_phone_successfully: 'Phone number updated'
        },
        change_email: {
            title: 'Change email',
            new_email: 'New email',
            new_email_holder: 'Enter new email',
            new_email_code: 'New email code',
            new_email_code_holder: 'Enter code sent to new email',
            email_code: 'Code for original email',
            email_code_holder: 'Enter code sent to original email',
            sms_code: 'SMS code',
            sms_code_holder: 'Enter SMS code',
            send_code: 'Send code',
            notice: 'For your security, you can\'t withdraw funds within 24 hours after changing your email',
            confirm: 'Confirm',
            bind_email_successfully: 'Email updated'
        },
        preference: {
            preferences: 'Preferences',
            language: 'Language',
            chart_colors: 'Chart colors',
            color_regular: 'Green up↑ Red down↓',
            color_reverse: 'Red up↑ Green down↓'
        }
    },
    spot: {
        base: {
            h24change: '24h change',
            h24low: '24h low',
            h24high: '24h high',
            h24volume: '24h volume',
            h24turnover: '24h turnover',
            limit: 'Limit',
            market: 'Market',
            order_book: 'Order book',
            last_trades: 'Last trades',
            open_orders: 'Open orders',
            order_history: 'Order history',
            order_symbol: 'Symbol',
            order_time: 'Order time',
            order_side: 'Side',
            order_price: 'Fill | Order price',
            order_volume: 'Filled | Total',
            order_turnover: 'Filled | Order value',
            order_fee: 'Fee',
            order_status: 'Status',
            no_records: 'No records',
            current_only: 'Current symbol'
        },
        margin: {},
        convert: {}
    },
    contract: {
        swap: {},
        futures: {},
        option: {}
    }
}