<template>
<div class="trade-history">
    <div class="panel">
        <div class="head">
            <div class="row">
                <div class="col col-price" v-text="$t('component.trade_history.price') + (inst.quoteCcy ? ' (' + inst.quoteCcy + ')' : '')"></div>
                <div class="col col-count" v-text="$t('component.trade_history.amount') + (inst.quoteCcy ? ' (' + inst.baseCcy + ')' : '')"></div>
                <div class="col col-time" v-text="$t('component.trade_history.time')"></div>
            </div>
        </div>
        <div class="body">
            <div class="trade-list hidden-scroll">
                <div class="row" v-for="trade in trades" :key="trade.tradeId">
                    <div class="col col-price" :class="{'index-up':trade.side===abs.common.EOrderSide.BUY,'index-down':trade.side===abs.common.EOrderSide.SELL}" v-text="abs.Util.formatNumber(trade.px, inst.tickSize)"></div>
                    <div class="col col-count" v-text="abs.Util.formatNumber(trade.sz, inst.lotSize)"></div>
                    <div class="col col-time" v-text="$d(new Date(trade.ts), 'long_time')"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "TradeHistory",
    props: {
        inst: {
            type: Object,
            default: () => {
                return {
                    instId: '',
                    baseCcy: '',
                    quoteCcy: '',
                    tickSize: 0,
                    lotSize: 0
                };
            }
        }
    },
    data () {
        return {
            TRADE_SIZE: 100,
            trades: []
        };
    },
    watch: {
        //*/
        'inst.instId' (newInstId, oldInstId) {
            if (oldInstId) {
                this.websocket.unsubscribe({
                    'channel': 'trades',
                    'instId': oldInstId
                }, 'trade-history');
            }

            this.api.trades(this, {
                instrumentId: newInstId,
                limit: this.TRADE_HISTORY
            }, function (rspBody) {
                rspBody.trades.forEach((trade) => {
                    trade.ts = Number(trade.ts);
                });
                this.trades = rspBody.trades;

                let _this = this;
                this.websocket.subscribe({
                    'channel': 'trades',
                    'instId': newInstId
                }, 'trade-history', function (result) {
                    result.data.forEach((trade) => {
                        trade.ts = Number(trade.ts);
                    });
                    _this.trades = result.data.concat(_this.trades);
                    _this.trades.slice(0, _this.TRADE_SIZE);
                });
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
        //*/
    }
}
</script>

<style scoped>
.panel {
    position: absolute;
    width: 100%;
    top: 8px;
    bottom: 0;
    padding: 0 8px;
}
.panel .head {
    padding: 0 8px;
}
.panel .body {
    position: absolute;
    left: 8px;
    right: 8px;
    top: 28px;
    bottom: 16px;
    padding: 0 8px;
    overflow: hidden;
}
.panel .row {
    overflow-x: hidden;
    display: flex;
}
.panel .col {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
}
.panel .col.col-price {
    width: 30%;
}
.panel .col.col-count {
    width: 35%;
    text-align: right;
}
.panel .col.col-time {
    width: 35%;
    text-align: right;
}
.panel .body .row {
    cursor: pointer;
    margin-bottom: 2px;
}
.panel .body .row:hover {
    background-color: var(--background-hover);
}
.panel .body .trade-list {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    right: 8px;
    overflow-y: auto;
}
</style>