<template>
<div class="date-picker">
    <div class="mask" :class="{'show':showCalendar}" @click="clickMask"></div>
    <div class="control" @click="clickControl">
        <label><input class="text" :style="'height:'+height+'px'" type="text" v-model="activeText" readonly /></label><i class="icon calendar-alt" :style="'top:'+((height-16)/2)+'px;right:'+((height-16)/2)+'px;'"></i>
    </div>
    <div class="calendar" :class="{'show':showCalendar}">
        <div class="month-bar">
            <div class="prev-month clickable">
                <div class="core" @click="clickPrevMonth"><i class="icon chevron-left"></i></div>
            </div>
            <div class="curr-month clickable">
                <div class="core" @click="clickCurrMonth"><div class="text" v-text="$d(current || active || NOW, 'year_month')"></div><i class="icon chevron-down"></i></div>
            </div>
            <div class="next-month clickable">
                <div class="core" @click="clickNextMonth"><i class="icon chevron-right"></i></div>
            </div>
        </div>
        <div class="week-bar">
            <div class="week" v-for="week in WEEKS" :key="week.getDay()" v-text="$d(week, 'short_weekday')"></div>
        </div>
        <div class="date-bar">
            <div class="date prev" :class="{'today':date.year===NOW.getFullYear() && date.month===NOW.getMonth() && date.date===NOW.getDate(), 'active':active && active.getFullYear()===date.year && active.getMonth()===date.month && active.getDate()===date.date}" v-for="date in prevMonthDates" :key="date.date" v-text="date.date" @click="clickDate(date)"></div>
            <div class="date" :class="{'today':date.year===NOW.getFullYear() && date.month===NOW.getMonth() && date.date===NOW.getDate(), 'active':active && active.getFullYear()===date.year && active.getMonth()===date.month && active.getDate()===date.date}" v-for="date in currMonthDates" :key="date.date" v-text="date.date" @click="clickDate(date)"></div>
            <div class="date next" :class="{'today':date.year===NOW.getFullYear() && date.month===NOW.getMonth() && date.date===NOW.getDate(), 'active':active && active.getFullYear()===date.year && active.getMonth()===date.month && active.getDate()===date.date}" v-for="date in nextMonthDates" :key="date.date" v-text="date.date" @click="clickDate(date)"></div>
        </div>
        <div class="options" :class="{'show':showOption}">
            <div class="list">
                <div class="year-list small-scroll">
                    <div class="year" :class="{'active':year===optionYear}" v-for="year in YEARS" :key="year" v-text="year" @click="clickYear(year)"></div>
                </div>
                <div class="month-list small-scroll">
                    <div class="month" :class="{'active':month.getMonth()===optionMonth}" v-for="month in MONTHS" :key="month.getMonth()" v-text="$d(month, 'short_month')" @click="clickMonth(month.getMonth())"></div>
                </div>
            </div>
            <div class="buttons">
                <div class="cancel"><a class="button link-button" @click="clickOptionCancel" v-text="$t('common.cancel')"></a></div>
                <div class="confirm"><a class="button primary-button" @click="clickOptionConfirm" v-text="$t('common.confirm')"></a></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "DatePicker",
    props: {
        value: {
            type: Date,
            default: () => {
                return null;
            }
        },
        height: {
            type: Number,
            default: () => {
                return 40;
            }
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE: 'change'
            },
            NOW: new Date(),
            MIN_YEAR: 2001,
            MAX_YEAR: 2050,
            YEARS: [],
            MONTHS: [],
            WEEKS: [],
            MAX_DATE_COUNT: 42,
            current: null,
            active: null,
            activeText: '',
            optionYear: -1,
            optionMonth: -1,
            showCalendar: false,
            showOption: false
        };
    },
    computed: {
        prevMonthDates () {
            if (!this.current) {
                return [];
            }
            let curr = new Date(this.current.getTime());
            curr.setDate(1);
            let day = curr.getDay();
            curr.setMonth(curr.getMonth(), 0);
            let date = curr.getDate();

            let dates = [];
            for (let i = 0; i < day; i++) {
                dates.unshift({
                    year: curr.getFullYear(),
                    month: curr.getMonth(),
                    date: date - i
                });
            }
            return dates;
        },
        currMonthDates () {
            if (!this.current) {
                return [];
            }
            let curr = new Date(this.current.getTime());
            curr.setMonth(curr.getMonth() + 1, 0);
            let date = curr.getDate();

            let dates = [];
            for (let i = 0; i < date; i++) {
                dates.push({
                    year: curr.getFullYear(),
                    month: curr.getMonth(),
                    date: i + 1
                });
            }
            return dates;
        },
        nextMonthDates () {
            if (!this.current) {
                return [];
            }
            let curr = new Date(this.current.getTime());
            curr.setMonth(curr.getMonth() + 1, 0);
            let length = curr.getDate() + this.prevMonthDates.length;
            curr.setMonth(curr.getMonth() + 1, 1);

            let dates = [];
            for (let i = 0; i < (this.MAX_DATE_COUNT - length); i++) {
                dates.push({
                    year: curr.getFullYear(),
                    month: curr.getMonth(),
                    date: i + 1
                });
            }
            return dates;
        }
    },
    watch: {
        value (active) {
            this.active = active;
        },
        active () {
            this.activeText = this.active ? this.$d(this.active, 'short') : '';
        }
    },
    mounted () {
        for (let year = this.MIN_YEAR; year <= this.MAX_YEAR; year++) {
            this.YEARS.push(year);
        }
        for (let m = 0; m < 12; m++) {
            let month = new Date();
            month.setMonth(m, 1);
            this.MONTHS.push(month);
        }
        for (let w = 0; w < 7; w++) {
            let week = new Date();
            week.setMonth(week.getMonth(), 1);
            let day = week.getDay();
            week.setMonth(week.getMonth(), week.getDate() + (w - day));
            this.WEEKS.push(week);
        }
        if (!this.current) {
            this.current = this.active || this.NOW;
        }
        this.optionYear = this.current.getFullYear();
        this.optionMonth = this.current.getMonth();
    },
    methods: {
        clickControl () {
            this.showCalendar = !this.showCalendar;
            this.showOption = false;
            this.optionYear = this.current.getFullYear();
            this.optionMonth = this.current.getMonth();
        },
        clickMask () {
            this.showCalendar = false;
            this.showOption = false;
            this.optionYear = this.current.getFullYear();
            this.optionMonth = this.current.getMonth();
        },
        clickCurrMonth () {
            this.showOption = !this.showOption;
            this.optionYear = this.current.getFullYear();
            this.optionMonth = this.current.getMonth();
        },
        clickPrevMonth () {
            let current = new Date(this.current.getTime());
            current.setMonth(this.current.getMonth() - 1, 1);
            this.current = current;
        },
        clickNextMonth () {
            let current = new Date(this.current.getTime());
            current.setMonth(this.current.getMonth() + 1, 1);
            this.current = current;
        },
        clickDate (date) {
            let active = new Date();
            active.setFullYear(date.year, date.month, date.date);
            active.setHours(0, 0, 0, 0);
            this.active = active;
            this.showCalendar = false;
            this.showOption = false;
            this.optionYear = this.current.getFullYear();
            this.optionMonth = this.current.getMonth();
            this.$emit(this.EVENTS.CHANGE, this.active);
        },
        clickYear (year) {
            this.optionYear = year;
        },
        clickMonth (month) {
            this.optionMonth = month;
        },
        clickOptionConfirm () {
            let current = new Date();
            current.setFullYear(this.optionYear, this.optionMonth);
            this.current = current;
            this.showOption = false;
        },
        clickOptionCancel () {
            this.showOption = false;
            this.optionYear = this.current.getFullYear();
            this.optionMonth = this.current.getMonth();
        }
    }
}
</script>

<style scoped>
.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    display: none;
    z-index: 1;
}
.mask.show {
    display: block;
}
.date-picker .control .text {
    padding-right: 32px;
    cursor: pointer;
}
.date-picker .control .icon {
    position: absolute;
    top: 12px;
    right: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.date-picker .calendar {
    position: absolute;
    width: 286px;
    margin-top: 4px;
    padding: 16px;
    border: 1px solid var(--background-tertiary);
    border-radius: 3px;
    background-color: var(--background-secondary-alt);
    display: none;
    z-index: 1;
}
.date-picker .calendar.show {
    display: block;
}
.date-picker .calendar .month-bar {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date-picker .calendar .month-bar .clickable {
    padding: 0 4px;
    height: 32px;
    cursor: pointer;
}
.date-picker .calendar .month-bar .clickable .core {
    height: 100%;
    display: flex;
    align-items: center;
}
.date-picker .calendar .month-bar .curr-month .core .icon {
    margin-left: 4px;
}
.date-picker .calendar .week-bar {
    height: 36px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date-picker .calendar .week-bar .week {
    width: 32px;
    text-align: center;
}
.date-picker .calendar .date-bar {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.date-picker .calendar .date-bar .date {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}
.date-picker .calendar .date-bar .date.prev, .date-picker .calendar .date-bar .date.next {
    color: var(--text-muted);
}
.date-picker .calendar .date-bar .date:hover {
    color: var(--text-strong);
    background-color: var(--background-hover);
}
.date-picker .calendar .date-bar .date.today {
    color: var(--text-active);
    border: 1px solid var(--text-active);
}
.date-picker .calendar .date-bar .date.active {
    color: var(--text-strong);
    background-color: var(--background-hover-alt);
    border: none;
}
.date-picker .calendar .options {
    position: absolute;
    top: 56px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    background-color: var(--background-secondary-alt);
    display: none;
    z-index: 2;
}
.date-picker .calendar .options.show {
    display: block;
}
.date-picker .calendar .options .list {
    position: absolute;
    top: 0;
    bottom: 40px;
    width: 100%;
    padding-bottom: 8px;
    display: flex;
}
.date-picker .calendar .options .list .year-list, .date-picker .calendar .options .list .month-list {
    flex: 1;
    overflow-y: auto;
}
.date-picker .calendar .options .list .year-list .year, .date-picker .calendar .options .list .month-list .month {
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
}
.date-picker .calendar .options .list .year-list .year:hover, .date-picker .calendar .options .list .month-list .month:hover {
    color: var(--text-strong);
    background-color: var(--background-hover);
}
.date-picker .calendar .options .list .year-list .year.active, .date-picker .calendar .options .list .month-list .month.active {
    color: var(--text-strong);
    background-color: var(--background-hover-alt);
}
.date-picker .calendar .options .buttons {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    padding: 0 8px;
    border-top: 1px solid var(--sep-color);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

i.icon {
    color: var(--text-secondary);
    background-color: var(--text-secondary);
    display: inline-block;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 12px;
    height: 12px;
}
i.icon.chevron-left {
    -webkit-mask-image: url(../assets/icons/chevron-left.svg);
    mask-image: url(../assets/icons/chevron-left.svg);
}
i.icon.chevron-right {
    -webkit-mask-image: url(../assets/icons/chevron-right.svg);
    mask-image: url(../assets/icons/chevron-right.svg);
}
</style>