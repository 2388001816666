<template>
<div class="container">
    <top-bar></top-bar>
    <div class="account-tabs">
        <div class="tab-heads">
            <div class="tab-head" :class="{'active':module===MODULES.OVERVIEW}" @click="clickTab(MODULES.OVERVIEW)" v-text="$t('account.overview.overview')"></div>
            <div class="tab-head" :class="{'active':module===MODULES.SECURITY || module===MODULES.PASSWORD || module===MODULES.PHONE || module===MODULES.EMAIL}" @click="clickTab(MODULES.SECURITY)" v-text="$t('account.security.security')"></div>
            <div class="tab-head" :class="{'active':module===MODULES.PREFERENCE}" @click="clickTab(MODULES.PREFERENCE)" v-text="$t('account.preference.preferences')"></div>
        </div>
        <div class="tab-bodys">
            <div class="tab-body" :class="{'active':module===MODULES.OVERVIEW}">
                <user-account-overview :base-lot-size="BASE_LOT_SIZE" :base-unit="BASE_UNIT" :base-unit-tickers="BASE_UNIT_TICKERS" :btc-unit-ticker="BTC_UNIT_TICKER" :usd-unit-ticker="USD_UNIT_TICKER" :unit="unit" :units="UNITS"></user-account-overview>
            </div>
            <div class="tab-body" :class="{'active':module===MODULES.SECURITY || module===MODULES.PASSWORD || module===MODULES.PHONE || module===MODULES.EMAIL}">
                <user-account-security v-if="module===MODULES.SECURITY"></user-account-security>
                <user-account-change-password v-if="module===MODULES.PASSWORD"></user-account-change-password>
                <user-account-change-phone v-if="module===MODULES.PHONE"></user-account-change-phone>
                <user-account-change-email v-if="module===MODULES.EMAIL"></user-account-change-email>
            </div>
            <div class="tab-body" :class="{'active':module===MODULES.PREFERENCE}">
                <user-account-preference></user-account-preference>
            </div>
        </div>
    </div>
    <bottom-bar></bottom-bar>
    <msg-toast></msg-toast>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import UserAccountOverview from "@/UserAccountOverview";
import UserAccountSecurity from "@/UserAccountSecurity";
import UserAccountPreference from "@/UserAccountPreference";
import UserAccountChangePassword from "@/UserAccountChangePassword";
import UserAccountChangePhone from "@/UserAccountChangePhone";
import UserAccountChangeEmail from "@/UserAccountChangeEmail";
export default {
    name: "UserAccount",
    components: {
        UserAccountChangeEmail,
        UserAccountChangePhone,
        UserAccountChangePassword, UserAccountOverview, UserAccountPreference, UserAccountSecurity},
    data () {
        return {
            MODULES: {
                OVERVIEW: 'overview',
                SECURITY: 'security',
                PREFERENCE: 'preference',
                PASSWORD: 'password',
                PHONE: 'phone',
                EMAIL: 'email'
            },
            BASE_LOT_SIZE: 8,
            UNITS: [{
                id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2
            }, {
                id: 'USD', text: 'USD', symbol: '$', lotSize: 2
            }, {
                id: 'BTC', text: 'BTC', symbol: '฿', lotSize: 6
            }],
            BASE_UNIT: {
                id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2
            },
            BASE_UNIT_TICKERS: [],
            BTC_UNIT_TICKER: 0,
            USD_UNIT_TICKER: 0,
            module: '',
            unit: {
                id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2
            }
        };
    },
    created () {
        this.module = this.$route.params.module || this.MODULES.OVERVIEW;
        this.$watch(() => this.$route.params, (params) => {
            this.module = params.module || this.MODULES.OVERVIEW;
        });
    },
    mounted () {
        this.indexTicker();
    },
    methods: {
        clickTab (module) {
            if (this.module === module) {
                return;
            }
            this.module = module;
            this.$router.push(this.abs.routes.USER_ACCOUNT + '/' + this.module);
        },
        indexTicker () {
            this.api.indexTicker(this, {
                quoteCcy: this.BASE_UNIT.id
            }, function (rspBody) {
                let baseUnitTickers = [];
                for (let i = 0; i < rspBody.tickers.length; i++) {
                    let ticker = rspBody.tickers[i];
                    baseUnitTickers[ticker.instId] = ticker;
                }
                this.BASE_UNIT_TICKERS = baseUnitTickers;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });

            this.api.indexTicker(this, {
                instrumentId: 'BTC-USDT'
            }, function (rspBody) {
                this.BTC_UNIT_TICKER = Number(rspBody.tickers[0].idxPx);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });

            this.api.indexTicker(this, {
                instrumentId: 'USDT-USD'
            }, function (rspBody) {
                this.USD_UNIT_TICKER = Number(rspBody.tickers[0].idxPx);
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.demo {}
.tab-heads {
    padding: 0 100px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
}
.tab-heads .tab-head {
    margin-right: 30px;
    height: 48px;
    border-bottom: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tab-heads .tab-head.active {
    color: var(--text-strong);
    border-bottom: 2px solid var(--text-strong);
}
.tab-heads .tab-head:hover {
    color: var(--text-strong);
}
.tab-bodys .tab-body {
    display: none;
    min-height: 1000px;
    padding: 24px 100px 100px;
}
.tab-bodys .tab-body.active {
    display: block;
}
.demo {}
</style>