<template>
<router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted () {
      let app = document.querySelector('#app');
      if (this.abs.Store.get('updown') === this.abs.common.EIndexMode.REVERSE) {
          app.className += ' theme-reverse';
      }
      else {
          app.className += ' theme-regular';
      }
      if (!this.abs.Store.client()) {
          this.api.client(this, null, function (rspBody) {
              this.abs.Store.client(rspBody.client);
          }, function (code, message) {
              this.abs.MsgBox.alert(message);
          });
      }
      this.websocket.connect();
      if (this.abs.Store.uticket()) {
          this.api.refresh(this, null, function (rspBody) {
              let uticket = rspBody.uticket;
              let apikey = rspBody.apikey;
              let passphrase = rspBody.passphrase;
              let timestamp = rspBody.timestamp;
              let sign = rspBody.sign;
              this.abs.Store.uticket(uticket);
              this.websocket.login(apikey, passphrase, timestamp, sign);
          }, function (code, message) {
              this.abs.MsgBox.alert(message);
          });
      }
  }
}
</script>

<style>
</style>
