<template>
<div class="container">
    <top-bar></top-bar>
    <trade-chart></trade-chart>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import TradeChart from "@/components/TradeChart";
export default {
    name: "SpotMargin",
    components: { TradeChart }
}
</script>

<style scoped>
.demo{}
</style>