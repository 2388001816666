<template>
<div class="wallet">
    <div class="side-left">
        <div class="border-box border-box-panel balance">
            <div class="label" v-text="$t('wallet.funding.total')"></div>
            <div class="amount">
                <div class="value">
                    <div class="text" v-text="innerUnit.symbol + abs.Util.formatNumber(totalFundingAmount, innerUnit.lotSize)"></div>
                    <drop-down class="unit" :width="80" :height="36" :value="innerUnit.id" :options="units" @change="changeUnit"></drop-down>
                </div>
                <div class="actions">
                    <a class="action" @click="clickDeposit" v-text="$t('wallet.deposit.deposit')"></a>
                    <a class="action" @click="clickWithdraw" v-text="$t('wallet.withdraw.withdraw')"></a>
                    <a class="action" @click="clickTransfer" v-text="$t('wallet.transfer.transfer')"></a>
                </div>
            </div>
            <div class="filter-bar">
                <div class="filter">
                    <i class="icon search"></i><label><input class="text" type="text" v-model="currencySearch" /></label>
                </div>
                <div class="trivial">
                    <checkbox-field :label="$t('wallet.funding.hide_trivial')" :value="trivial" @changeValue="changeTrivial"></checkbox-field>
                </div>
            </div>
            <div class="detail">
                <div class="head">
                    <div class="row">
                        <div class="col currency">
                            <div class="text" v-text="$t('wallet.funding.crypto')"></div>
                        </div>
                        <div class="col count">
                            <div class="text number" v-text="$t('wallet.funding.balance')"></div>
                        </div>
                        <div class="col rate">
                            <div class="text number" v-text="$t('wallet.funding.portfolio')"></div>
                        </div>
                    </div>
                </div>
                <div class="body">
                    <div class="row" v-for="asset in filterFundingAssetAmountList" :key="asset.ccy">
                        <div class="col currency">
                            <div class="icon" :style="'background-image:url(' + asset.ccyLogo + ')'"></div>
                            <div class="text" v-text="asset.ccy"></div>
                        </div>
                        <div class="col count">
                            <div class="text number" v-text="innerUnit.symbol + abs.Util.formatNumber(asset.amount, baseLotSize)"></div>
                        </div>
                        <div class="col rate">
                            <div class="text number" v-text="abs.Util.formatNumber(asset.rate, 2) + '%'"></div>
                        </div>
                        <div class="col opers">
                            <a class="oper" @click="clickDeposit" v-text="$t('wallet.deposit.deposit')"></a>
                            <a class="oper" @click="clickWithdraw" v-text="$t('wallet.withdraw.withdraw')"></a>
                            <a class="oper" @click="clickTransfer" v-text="$t('wallet.transfer.transfer')"></a>
                        </div>
                    </div>
                    <div class="empty" v-if="!filterFundingAssetAmountList.length" v-text="$t('wallet.funding.no_records')"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="side-right">
        <div class="border-box flow">
            <div class="border-box-title" v-text="$t('wallet.funding.recent_transactions')"></div>
            <div class="panel">
                <div class="row" v-for="bill in fundingBillList" :key="bill.billId">
                    <div class="col left">
                        <i class="icon trade"></i>
                        <div class="info">
                            <div class="type" v-text="bill.type"></div>
                            <div class="time" v-text="$d(bill.time, 'long')"></div>
                        </div>
                    </div>
                    <div class="col right">
                        <div class="text number" v-text="abs.Util.formatNumber(bill.amount) + ' ' + bill.ccy"></div>
                    </div>
                </div>
                <div class="empty" v-if="!fundingBillList.length" v-text="$t('wallet.funding.no_transactions')"></div>
            </div>
        </div>
    </div>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
import DropDown from "@/components/DropDown";
import CheckboxField from "@/components/CheckboxField";
export default {
    name: "UserAssetFunding",
    components: {CheckboxField, DropDown},
    emits: ['changeunit'],
    props: {
        baseLotSize: {
            type: Number,
            default: () => {return 8;}
        },
        baseUnit: {
            type: Object,
            default: () => {return {id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2};}
        },
        baseUnitTickers: {
            type: Array,
            default: () => {return [];}
        },
        btcUnitTicker: {
            type: Number,
            default: () => {return 0;}
        },
        usdUnitTicker: {
            type: Number,
            default: () => {return 0;}
        },
        unit: {
            type: Object,
            default: () => {return {id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2};}
        },
        units: {
            type: Array,
            default: () => {return [];}
        }
    },
    data () {
        return {
            EVENTS: {
                CHANGE_UNIT: 'changeunit'
            },
            innerUnit: {id: 'USDT', text: 'USDT', symbol: '₮', lotSize: 2},
            totalFundingValue: 0,
            fundingAssetValueList: [],
            currencySearch: '',
            trivial: false,
            fundingBillList: []
        };
    },
    computed: {
        totalFundingAmount () {
            if (this.innerUnit.id === 'BTC') {
                return this.btcUnitTicker ? (this.totalFundingValue / this.btcUnitTicker) : 0;
            }
            else if (this.innerUnit.id === 'USD') {
                return this.usdUnitTicker ? (this.totalFundingValue * this.usdUnitTicker) : 0;
            }
            else {
                return this.totalFundingValue;
            }
        },
        filterFundingAssetAmountList () {
            let list = [];
            let currencySearch = this.currencySearch.toLowerCase();
            for (let i = 0; i < this.fundingAssetValueList.length; i++) {
                let asset = this.fundingAssetValueList[i];
                if (currencySearch && asset.ccy.toLowerCase().indexOf(currencySearch) < 0) {
                    continue;
                }
                if (this.trivial && asset.value < 1) {
                    continue;
                }
                let amount = asset.value;
                if (this.innerUnit.id === 'BTC') {
                    amount = amount / this.btcUnitTicker;
                }
                else if (this.innerUnit.id === 'USD') {
                    amount = amount * this.usdUnitTicker;
                }
                let rate = asset.value / this.totalFundingValue * 100;
                list.push({
                    ccy: asset.ccy,
                    ccyLogo: asset.ccyLogo,
                    amount: amount,
                    rate: rate
                });
            }
            return list;
        }
    },
    watch: {
        unit (unit) {
            if (this.innerUnit !== unit) {
                this.innerUnit = unit;
            }
        }
    },
    mounted () {
        this.assetBalances();
        this.assetBillList(null, null, null, null, 50);
    },
    methods: {
        changeUnit (id, innerUnit) {
            this.innerUnit = innerUnit;
            this.$emit(this.EVENTS.CHANGE_UNIT, this.innerUnit);
        },
        clickDeposit () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_DEPOSIT);
        },
        clickWithdraw () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_WITHDRAW);
        },
        clickTransfer () {
            this.$router.push(this.abs.routes.USER_ASSET + '/' + this.abs.routes.USER_ASSET_MODULE_TRANSFER);
        },
        changeTrivial (trivial) {
            this.trivial = trivial;
        },
        assetBalances () {
            this.api.assetBalances(this, null, function (rspBody) {
                let totalFundingValue = 0;
                let fundingAssetValueList = [];
                for (let i = 0; i < rspBody.balances.length; i++) {
                    let balance = rspBody.balances[i];
                    let value = 0;
                    if (balance.ccy === this.baseUnit.id) {
                        value = Number(balance.bal);
                    }
                    else {
                        let baseUnitTicker = this.baseUnitTickers[balance.ccy + '-' + this.baseUnit.id];
                        value = baseUnitTicker ? Number(balance.bal) * Number(baseUnitTicker.idxPx) : 0;
                    }
                    totalFundingValue += value;
                    fundingAssetValueList.push({
                        ccy: balance.ccy,
                        ccyLogo: balance.ccyLogo,
                        value: value
                    });
                }
                this.totalFundingValue = totalFundingValue;
                this.fundingAssetValueList = fundingAssetValueList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        },
        assetBillList (currency, billType, startTime, endTime, limit) {
            this.api.assetBillList(this, {
                currency: currency,
                billType: billType,
                startTime: (startTime ? startTime.getTime() : 0),
                endTime: (endTime ? endTime.getTime() : 0),
                limit: limit
            }, function (rspBody) {
                let fundingBillList = [];
                for (let i = 0; i < rspBody.billList.length; i++) {
                    let bill = rspBody.billList[i];
                    fundingBillList.push({
                        billId: bill.billId,
                        time: new Date(Number(bill.ts)),
                        type: this.abs.common.ECashBillTypes[bill.type] || '',
                        ccy: bill.ccy,
                        amount: Math.abs(Number(bill.balChg) || 0),
                        status: this.abs.wallet.ETransferStatus['success']
                    });
                }
                this.fundingBillList = fundingBillList;
            }, function (code, message) {
                this.abs.Toast.show(code + ' - ' + message);
            });
        }
    }
}
</script>

<style scoped>
.side-left {
    flex: 1;
    margin-right: 24px;
}
.side-right {
    width: 30%;
}

.border-box {
    border: 1px solid var(--sep-color);
    border-radius: 2px;
}
.border-box-title {
    height: 64px;
    padding: 0 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--sep-color);
}
.border-box-panel {
    padding: 24px 24px 36px 24px;
}

.row .col .text.number {
    text-align: right;
}

.balance .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 8px;
}
.balance .amount {
    display: flex;
    justify-content: space-between;
}
.balance .amount .value {
    display: flex;
    align-items: center;
}
.balance .amount .value .text {
    font-size: 30px;
    margin-right: 16px;
}
.balance .amount .actions {
    display: flex;
    align-items: center;
}
.balance .amount .actions .action {
    width: 76px;
    height: 32px;
    line-height: 30px;
    margin-right: 16px;
    text-align: center;
    border: 1px solid var(--sep-color);
    border-radius: 16px;
    cursor: pointer;
}
.balance .amount .actions .action:hover {
    color: var(--text-strong-ant);
    background-color: var(--text-strong);
    border-color: var(--text-strong);
}

.opers {
    text-align: right;
}
.opers .oper {
    display: inline-block;
    width: 76px;
    height: 28px;
    line-height: 26px;
    margin-right: 8px;
    text-align: center;
    border: 1px solid var(--sep-color);
    border-radius: 14px;
    cursor: pointer;
}
.opers .oper:hover {
    color: var(--text-strong-ant);
    background-color: var(--text-strong);
    border-color: var(--text-strong);
}

.wallet {
    display: flex;
    align-items: flex-start;
}
.wallet .balance .filter-bar {
    padding: 48px 0 8px 0;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    justify-content: space-between;
}
.wallet .balance .filter-bar .filter .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 12px;
    height: 12px;
    z-index: 1;
}
.wallet .balance .filter-bar .filter .text {
    height: 32px;
    padding-left: 32px;
}
.wallet .balance .filter-bar .trivial {
    display: flex;
    align-items: center;
}
.wallet .balance .detail .row {
    padding: 0 12px;
    display: flex;
    align-items: center;
}
.wallet .balance .detail .col.currency {
    width: 25%;
    display: flex;
    align-items: center;
}
.wallet .balance .detail .col.currency .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}
.wallet .balance .detail .col.count {
    width: 20%;
}
.wallet .balance .detail .col.rate {
    width: 20%;
}
.wallet .balance .detail .col.opers {
    width: 35%;
}
.wallet .balance .detail .head {
    border-bottom: 1px solid var(--sep-color);
}
.wallet .balance .detail .head .row {
    height: 36px;
}
.wallet .balance .detail .body .row {
    height: 64px;
    border-bottom: 1px solid var(--sep-color);
}
.wallet .balance .detail .body .row:hover {
    background-color: var(--background-hover);
}

.wallet .flow .panel {
    padding: 0 24px 36px 24px;
}
.wallet .flow .row {
    height: 64px;
    padding: 0 8px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.wallet .flow .row:hover {
    background-color: var(--background-hover);
}
.wallet .flow .row .left {
    width: 50%;
    display: flex;
    align-items: center;
}
.wallet .flow .row .left .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: var(--background-inactive);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
}
.wallet .flow .row .left .icon.trade {
    background-image: url(./assets/img/wallet-trade.png);
}
.wallet .flow .row .left .info .type {
    line-height: 20px;
}
.wallet .flow .row .left .info .time {
    line-height: 16px;
    font-size: 12px;
    color: var(--text-secondary);
}
.wallet .flow .row .right {
    width: 50%;
}

.empty {
    min-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>