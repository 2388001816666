<template>
<div class="container">
    <top-bar></top-bar>
    <div class="banner">
        <div class="slogan-1" v-text="$t('home.index.slogan_1') + ' EXOPEN'"></div>
        <div class="slogan-2" v-text="$t('home.index.slogan_2')"></div>
        <div class="slogan-3">
            <button class="button primary-button large" v-if="!uticket" @click="clickRegister" v-text="$t('home.index.start_1')"></button>
            <button class="button primary-button large" v-if="uticket" @click="clickTrading" v-text="$t('home.index.start_2')"></button>
        </div>
    </div>
    <div class="market">
        <div class="tabs">
            <div class="tab" :class="{'active':rank===RANKS.POPULAR}" @click="clickPopular" v-text="$t('home.index.rank_popular')"></div>
            <div class="tab" :class="{'active':rank===RANKS.CHANGE_UP}" @click="clickChangeUp" v-text="$t('home.index.rank_change_up')"></div>
            <div class="tab" :class="{'active':rank===RANKS.CHANGE_DOWN}" @click="clickChangeDown" v-text="$t('home.index.rank_change_down')"></div>
            <div class="tab" :class="{'active':rank===RANKS.VOLUME}" @click="clickVolume" v-text="$t('home.index.rank_turnover')"></div>
        </div>
        <div class="tab-panels">
            <div class="tab-panel" :class="{'active':rank===RANKS.POPULAR}">
                <div class="table-head">
                    <div class="row">
                        <div class="col name" v-text="$t('home.index.asset')"></div>
                        <div class="col price" v-text="$t('home.index.last')"></div>
                        <div class="col change" v-text="$t('home.index.change')"></div>
                        <div class="col volume" v-text="$t('home.index.turnover')"></div>
                        <div class="col trade"></div>
                    </div>
                </div>
                <div class="table-body">
                    <div class="row" v-for="item in popularRank" :key="item.code">
                        <div class="col name">
                            <img class="img" :src="item.logo" alt="" /><div class="text" v-text="item.code"></div>
                        </div>
                        <div class="col price">
                            <div class="text" v-text="'$'+abs.Util.formatNumber(item.last)"></div>
                        </div>
                        <div class="col change">
                            <div class="text" :class="{'index-up':item.change>=0,'index-down':item.change<0}" v-text="(item.change>=0 ? '+' : '')+abs.Util.formatNumber(item.change, 2)+'%'"></div>
                        </div>
                        <div class="col volume">
                            <div class="text" v-text="abs.Util.formatNumber(item.volume, 0)"></div>
                        </div>
                        <div class="col trade">
                            <a class="button link-button" @click="clickTrade(item.code)" v-text="$t('home.index.trade')"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-panel" :class="{'active':rank===RANKS.CHANGE_UP}">
                <div class="table-head">
                    <div class="row">
                        <div class="col name" v-text="$t('home.index.asset')"></div>
                        <div class="col price" v-text="$t('home.index.last')"></div>
                        <div class="col change" v-text="$t('home.index.change')"></div>
                        <div class="col volume" v-text="$t('home.index.turnover')"></div>
                        <div class="col trade"></div>
                    </div>
                </div>
                <div class="table-body">
                    <div class="row" v-for="item in changeUpRank" :key="item.code">
                        <div class="col name">
                            <img class="img" :src="item.logo" alt="" /><div class="text" v-text="item.code"></div>
                        </div>
                        <div class="col price">
                            <div class="text" v-text="'$'+abs.Util.formatNumber(item.last)"></div>
                        </div>
                        <div class="col change">
                            <div class="text" :class="{'index-up':item.change>=0,'index-down':item.change<0}" v-text="(item.change>=0 ? '+' : '')+abs.Util.formatNumber(item.change, 2)+'%'"></div>
                        </div>
                        <div class="col volume">
                            <div class="text" v-text="abs.Util.formatNumber(item.volume, 0)"></div>
                        </div>
                        <div class="col trade">
                            <a class="button link-button" @click="clickTrade(item.code)" v-text="$t('home.index.trade')"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-panel" :class="{'active':rank===RANKS.CHANGE_DOWN}">
                <div class="table-head">
                    <div class="row">
                        <div class="col name" v-text="$t('home.index.asset')"></div>
                        <div class="col price" v-text="$t('home.index.last')"></div>
                        <div class="col change" v-text="$t('home.index.change')"></div>
                        <div class="col volume" v-text="$t('home.index.turnover')"></div>
                        <div class="col trade"></div>
                    </div>
                </div>
                <div class="table-body">
                    <div class="row" v-for="item in changeDownRank" :key="item.code">
                        <div class="col name">
                            <img class="img" :src="item.logo" alt="" /><div class="text" v-text="item.code"></div>
                        </div>
                        <div class="col price">
                            <div class="text" v-text="'$'+abs.Util.formatNumber(item.last)"></div>
                        </div>
                        <div class="col change">
                            <div class="text" :class="{'index-up':item.change>=0,'index-down':item.change<0}" v-text="(item.change>=0 ? '+' : '')+abs.Util.formatNumber(item.change, 2)+'%'"></div>
                        </div>
                        <div class="col volume">
                            <div class="text" v-text="abs.Util.formatNumber(item.volume, 0)"></div>
                        </div>
                        <div class="col trade">
                            <a class="button link-button" @click="clickTrade(item.code)" v-text="$t('home.index.trade')"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-panel" :class="{'active':rank===RANKS.VOLUME}">
                <div class="table-head">
                    <div class="row">
                        <div class="col name" v-text="$t('home.index.asset')"></div>
                        <div class="col price" v-text="$t('home.index.last')"></div>
                        <div class="col change" v-text="$t('home.index.change')"></div>
                        <div class="col volume" v-text="$t('home.index.turnover')"></div>
                        <div class="col trade"></div>
                    </div>
                </div>
                <div class="table-body">
                    <div class="row" v-for="item in volumeRank" :key="item.code">
                        <div class="col name">
                            <img class="img" :src="item.logo" alt="" /><div class="text" v-text="item.code"></div>
                        </div>
                        <div class="col price">
                            <div class="text" v-text="'$'+abs.Util.formatNumber(item.last)"></div>
                        </div>
                        <div class="col change">
                            <div class="text" :class="{'index-up':item.change>=0,'index-down':item.change<0}" v-text="(item.change>=0 ? '+' : '')+abs.Util.formatNumber(item.change, 2)+'%'"></div>
                        </div>
                        <div class="col volume">
                            <div class="text" v-text="abs.Util.formatNumber(item.volume, 0)"></div>
                        </div>
                        <div class="col trade">
                            <a class="button link-button" @click="clickTrade(item.code)" v-text="$t('home.index.trade')"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="more"><a class="button link-button" v-text="$t('home.index.more')" @click="clickMoreMarket"></a></div>
    </div>
    <div class="guide">
        <div class="guide-item">
            <div class="title" v-text="$t('home.index.feature_title')"></div>
            <div class="describe" v-text="$t('home.index.feature_desc')"></div>
        </div>
        <div class="guide-item">
            <div class="keys">
                <div class="key">
                    <div class="value">200+</div>
                    <div class="label" v-text="$t('home.index.feature1')"></div>
                </div>
                <div class="key">
                    <div class="value">20M+</div>
                    <div class="label" v-text="$t('home.index.feature2')"></div>
                </div>
                <div class="key">
                    <div class="value">400+</div>
                    <div class="label" v-text="$t('home.index.feature3')"></div>
                </div>
                <div class="key">
                    <div class="value">7x24小时</div>
                    <div class="label" v-text="$t('home.index.feature4')"></div>
                </div>
            </div>
        </div>
        <div class="guide-item">
            <div class="intros">
                <div class="intro">
                    <img class="img" src="./assets/img/intro-1.png" alt="" />
                    <div class="title" v-text="$t('home.index.feature_label1')"></div>
                    <div class="describe" v-text="$t('home.index.feature_value1')"></div>
                </div>
                <div class="intro">
                    <img class="img" src="./assets/img/intro-2.png" alt="" />
                    <div class="title" v-text="$t('home.index.feature_label2')"></div>
                    <div class="describe" v-text="$t('home.index.feature_value2')"></div>
                </div>
                <div class="intro">
                    <img class="img" src="./assets/img/intro-3.png" alt="" />
                    <div class="title" v-text="$t('home.index.feature_label3')"></div>
                    <div class="describe" v-text="$t('home.index.feature_value3')"></div>
                </div>
            </div>
        </div>
    </div>
    <bottom-bar></bottom-bar>
    <msg-toast></msg-toast>
</div>
<div class="locale" v-text="$i18n.locale"></div>
</template>

<script>
export default {
    name: "HomeIndex",
    data () {
        return {
            RANKS: {
                POPULAR: 'popular',
                CHANGE_UP: 'change_up',
                CHANGE_DOWN: 'change_down',
                VOLUME: 'volume'
            },
            uticket: this.abs.Store.uticket(),
            instrumentType: this.abs.common.EInstrumentType.SPOT,
            rank: 'popular',
            ranks: {}
        };
    },
    computed: {
        popularRank () {
            return this.ranks['popular'] || [];
        },
        changeUpRank () {
            return this.ranks['change_up'] || [];
        },
        changeDownRank () {
            return this.ranks['change_down'] || [];
        },
        volumeRank () {
            return this.ranks['volume'] || [];
        }
    },
    mounted () {
        this.api.ranks(this, {
            instrumentType: this.instrumentType
        }, function (rspBody) {
            this.ranks = rspBody.ranks;
        }, function (code, message) {
            this.abs.Toast.show(code + ' - ' + message);
        });
    },
    methods: {
        clickRegister () {
            this.$router.push(this.abs.routes.HOME_REGISTER);
        },
        clickTrading () {
            this.$router.push(this.abs.routes.SPOT_BASE);
        },
        clickPopular () {
            this.rank = this.RANKS.POPULAR;
        },
        clickChangeUp () {
            this.rank = this.RANKS.CHANGE_UP;
        },
        clickChangeDown () {
            this.rank = this.RANKS.CHANGE_DOWN;
        },
        clickVolume () {
            this.rank = this.RANKS.VOLUME;
        },
        clickTrade (currency) {
            this.$router.push(this.abs.routes.SPOT_BASE + '/' + currency + '-USDT');
        },
        clickMoreMarket () {
            this.$router.push(this.abs.routes.MARKET_BASE);
        }
    }
}
</script>

<style scoped>
.banner {
    width: 100%;
    height: 400px;
    padding: 0 100px;
    background-color: var(--background-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.banner .slogan-1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    color: var(--text-strong);
    -webkit-font-smoothing: antialiased;
}
.banner .slogan-2 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: var(--text-default);
    -webkit-font-smoothing: antialiased;
}
.banner .slogan-3 {
    margin-top: 30px;
}
.banner .slogan-3 .button {
    width: 160px;
}

.market {
    padding: 0 100px;
}
.market .tabs {
    padding-top: 40px;
    display: flex;
    justify-content: center;
}
.market .tabs .tab {
    height: 40px;
    line-height: 40px;
    margin: 0 10px;
    padding: 0 10px;
    font-size: 16px;
    color: var(--text-secondary);
    cursor: pointer;
}
.market .tabs .tab.active {
    color: var(--text-strong);
}
.market .tab-panel {
    display: none;
}
.market .tab-panel.active {
    display: block;
}
.market .tab-panel .row {
    display: flex;
}
.market .tab-panel .col {
    display: flex;
    align-items: center;
}
.market .tab-panel .col.name {
    width: 20%;
    padding-left: 10px;
}
.market .tab-panel .col.name .img {
    width: 24px;
    margin-right: 10px;
}
.market .tab-panel .col.price {
    width: 20%;
    justify-content: right;
}
.market .tab-panel .col.change {
    width: 20%;
    justify-content: right;
}
.market .tab-panel .col.volume {
    width: 20%;
    justify-content: right;
}
.market .tab-panel .col.trade {
    width: 20%;
    padding-right: 10px;
    justify-content: right;
}
.market .tab-panel .table-head .col {
    height: 50px;
    color: var(--text-secondary);
    border-bottom: 1px solid var(--sep-color);
}
.market .tab-panel .table-body .row:hover {
    background-color: var(--background-hover);
}
.market .tab-panel .table-body .col {
    height: 60px;
    color: var(--text-strong);
    border-bottom: 1px solid var(--sep-color);
}
.market .more {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.market .more .button {
    color: var(--text-active);
}

.guide {
    padding: 0 100px 100px 80px;
}
.guide .guide-item {
    margin-top: 80px;
}
.guide .guide-item .title {
    font-size: 38px;
    font-weight: 600;
    line-height: 46px;
    text-align: center;
    color: var(--text-strong);
    -webkit-font-smoothing: antialiased;
}
.guide .guide-item .describe {
    margin-top: 20px;
    text-align: center;
    color: var(--text-default);
}
.guide .guide-item > .describe {
    width: 1000px;
    margin: 20px auto 0 auto;
}
.guide .guide-item .keys {
    display: flex;
    justify-content: center;
}
.guide .guide-item .keys .key {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.guide .guide-item .keys .key .value {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: var(--text-active);
    -webkit-font-smoothing: antialiased;
}
.guide .guide-item .keys .key .label {
    margin-top: 10px;
    color: var(--text-strong);
}
.guide .guide-item .intros {
    display: flex;
    justify-content: center;
}
.guide .guide-item .intros .intro {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.guide .guide-item .intros .intro .img {
    width: 100px;
    height: 100px;
}
.guide .guide-item .intros .intro .title {
    margin-top: 20px;
    padding: 0 20px;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
}
.guide .guide-item .intros .intro .describe {
    margin-top: 20px;
    padding: 0 20px;
}
.demo{}
</style>